import { toDateTimeString } from '@/utils/date';

function transformItem2Dto(item) {
  const dto = {
    ...item,
    employeeID: item.id,
    employmentStartDate: toDateTimeString(item.employmentStartDate),
    employmentEndDate: toDateTimeString(item.employmentEndDate)
  };
  delete dto.id;
  return dto;
}

function transformDto2Item(dto) {
  const item = {
    ...dto,
    id: dto.employeeID
  };
  delete item.employeeID;
  return item;
}

export const apiOverrides = { transformItem2Dto, transformDto2Item };

export const storeExtensions = {
  actions: {},
  getters: {},
  mutation: {},
  state: {}
};
