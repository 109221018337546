<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import AutoUpdateMixin from '@/mixins/auto-update';
export default {
  name: 'App',
  mixins: [AutoUpdateMixin],
  computed: {
    authorizedUserID() {
      return this.$store.state.authentication.userId;
    },
    preferredLanguage() {
      return this.$store.state.authentication.preferredLanguage;
    },
    currentPermissions() {
      return this.$store.getters.permissions;
    }
  },
  watch: {
    authorizedUserID(newUserID) {
      this.onAuthorizedUserIDChanged(newUserID);
    },
    preferredLanguage(lang) {
      if (lang) {
        this.$i18n.locale = lang;
        this.$store.dispatch('setLanguage', lang);
      }
    },
    currentPermissions(newPermissions, oldPermissions) {
      // Generate routes for side bar
      this.$store.dispatch('permission/generateRoutes', newPermissions);
    }
  },
  created() {
    this.$i18n.locale = this.$store.getters.language;
    this.$store.dispatch('language/getItems');
    this.onAuthorizedUserIDChanged(this.authorizedUserID);
  },
  methods: {
    onAuthorizedUserIDChanged(newUserID) {
      if (newUserID) {
        this.$store.dispatch('option/fetchAllOptions');
        this.$store.dispatch('configuration/getItems', {
          options: { localStorageKey: 'configuration' }
        });
        this.$store.dispatch('getUserInfo', {}).then(() => {});
      }
    }
  }
};
</script>
