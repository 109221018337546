import { PermissionLevel } from '@/utils/permission';

/**
 * Pages for requestForm
 */
export const requestFormRoutes = [
  {
    component: () => import('@/pages/requestform/index.vue'),
    meta: {
      permissions: [{ MyRequestForm: PermissionLevel.Write }],
      title: 'menu_requestForm'
    },
    name: 'RequestForm',
    path: 'requestForm'
  }
];
