// Keys ougt to be sorted alphabetically, case sensitive.
// To autosort: repeat CTRL+S until all keys are sorted
//  OR
// repeat command until sorted: npm run lint-fix
/* eslint sort-keys-fix/sort-keys-fix: error */
export default {
  accesslog: {
    accessedAt: 'Datum Zeit',
    accessedBy: 'Geöffnet von',
    accessedResource: 'Verwendete Quelle',
    ip: 'IP',
    parameters: 'Parameter'
  },
  autoUpdate: {
    alertConfirm: 'Reload page',
    alertMessage: 'There is a new update available for this application. Please reload the page to update.',
    alertTitle: 'Update'
  },
  common: {
    active: 'Aktiv',
    add: 'Hinzufügen',
    addRow: 'Zeile hinzufügen',
    address: 'Adresse',
    addresses: 'Adressen',
    ageRestriction: 'Mindestalter',
    areaName: 'Region',
    billingAddress: 'Rechnungsadresse',
    block_notification_not_saved_warning:
      'Benachrichtigung Einstellung nicht gespeichert. Bitte versuchen Sie es später noch einmal',
    browserNotSupported: 'Ihr Browser unterstützt diese Funktion nicht',
    cancel: 'Abbrechen',
    category: 'Kategorie',
    chamberOfCommerceNumber: 'Nummer Handelskammer',
    city: 'Ort',
    clearFilters: 'Alle Filter löschen',
    clearSelection: 'Abwählen',
    close: 'Schließen',
    close_unsaved_warning:
      'Ihre Änderungen sind noch nicht gespeichert worden. Sind Sie sicher, dass Sie diesen Bildschirm schließen möchten?',
    close_warning: 'Sind Sie sicher, dass Sie diesen Bildschirm schließen möchten?',
    code: 'Code',
    company: 'Unternehmen',
    companyAddress: 'Besuchsadresse',
    companyName: 'Unternehmen',
    confirm: 'Bestätigen',
    content: 'Inhalt',
    copy: 'Kopieren',
    copyToClipboard: 'In die Zwischenablage kopieren',
    copyToClipboardFail: 'In die Zwischenablage kopieren fehlgeschlagen',
    copyToClipboardSuccess: 'Erfolgreich in die Zwischenablage kopiert',
    country: 'Land',
    couponCode: 'Rabattcode',
    createdTime: 'Eingabezeit',
    customer: 'Kunde',
    data_created: 'Die Daten wurden gespeichert',
    data_save_error: 'Die Daten können nicht gespeichert werden',
    data_updated: 'Die Änderungen wurden gespeichert',
    date: 'Datum',
    dateOfBirth: 'Geburtsdatum',
    decimals: 'Dezimale',
    delete: 'Löschen',
    deleteSelectedItems: 'Ausgewählte Elemente löschen',
    delete_cancelled: 'Löschen abgebrochen',
    delete_completed: 'Die Daten wurden gelöscht',
    delete_record_summary_warning: 'Sind Sie sicher, dass Sie <b>{summary}</b> löschen möchten?',
    delete_record_warning: 'Sind Sie sicher, dass Sie dies löschen wollen?',
    deliveryMethod: 'Versandart',
    deliveryPerson: 'Kurier',
    department: 'Abteilung',
    deposit: 'Pfand',
    description: 'Beschreibung',
    displayName: 'Anzeige auf Seite',
    dontSave: 'Nicht speichern',
    dontSend: 'Nicht verschicken',
    dont_show_again_warning: 'Nicht mehr anzeigen',
    draft: 'Konzept',
    ean: 'EAN',
    edit: 'Bearbeiten',
    email: 'E-Mail-Adresse',
    employee: 'Arbeitnehmer',
    employees: 'Arbeitnehmer',
    emptyString: ' ',
    error: 'Fehler',
    export: 'Export',
    file: 'Datei',
    firstName: 'Vorname',
    fixedPhone: 'Festnetz-Telefon',
    fromDate: '',
    gender: 'Geschlecht',
    general: 'Allgemein',
    houseNumber: 'Hausnummer',
    ibanNumber: 'IBAN',
    id: 'ID',
    image: 'Bild',
    impersonateUser: 'Melden Sie sich als dieser Benutzer an',
    inactive: 'Inaktiv',
    initials: 'Initiale',
    isMainAddressYN: 'Hauptadresse',
    jobFunction: 'Position',
    key: 'Schlüssel',
    lastName: 'Nachname',
    latitude: 'Breitengrad',
    leave_mail_warning: 'Sie verlassen diese Seite. Möchten Sie diese E-Mail zuerst versenden?',
    leave_unsaved_warning: 'Sie verlassen diese Seite. Möchten Sie die Änderungen speichern??',
    legacyOrderID: 'Bestellungs-ID',
    loading: 'Laden ...',
    localProducts: 'Lokale Produkte',
    longitude: 'Längengrad',
    loyaltyPoints: 'VIP-Punkte',
    mailingAddress: 'Postadresse',
    menuCategory: 'Menü-Kategorie',
    menuComposition: 'Zusammensetzung des Menüs',
    mobilePhone: 'Mobiltelefon',
    modified: 'Geändert',
    mollieKey: 'Mollie key',
    name: 'Name',
    no: 'Nein',
    notApplicable: 'Nicht zutreffend',
    nutritionValues: 'Nährwerte',
    ok: 'OK',
    onlineYN: 'Aktiv',
    partnership: 'Partnerschaft',
    password: 'passwort',
    phone: 'Telefon',
    phoneIce: 'Telefon (I.C.E.)',
    position: 'Position auf Website',
    positionInCashDesk: 'Position auf Kasse',
    positionOnTicket: 'Position auf Ticket',
    postalCode: 'Postleitzahl',
    prefix: 'Präfix',
    price: 'Preis',
    priceCategory: 'Preiskategorie',
    printers: 'Drucker',
    product: 'Produkt',
    productCategory: 'Produktkategorie',
    products: 'Products',
    publish: 'Publizieren',
    quantity: 'Anzahl',
    question: 'Frage',
    readMore: 'Weiterlesen ...',
    reference: 'Referenz',
    remark: 'Anmerkung',
    remarks: 'Anmerkungen',
    restaurant: 'Restaurant',
    restaurantName: 'Restaurant Name',
    restaurants: 'Restaurants',
    salutation: 'Grußwort',
    save: 'Speichern',
    saveClose: 'Speichern & Schließen',
    saveNext: 'Speichern & weiter',
    search: 'Suchen',
    send: 'Versenden',
    shortcut: 'Website Verknüpfung',
    skip: 'Überspringen',
    status: 'Status',
    stay: 'Auf Seite bleiben',
    streetName: 'Straße',
    streetNumber: 'Hausnummer',
    success: 'Erfolg',
    switchOff: 'Ausschalten',
    switchOn: 'Anschalten',
    tabGeneral: 'Allgemein',
    takeOutAndDeliveryConcept: 'Bestellen und Liefern',
    takeawayID: 'Bestell-ID',
    taskstatus: 'Status Aufgabe',
    ticketNumber: 'Ticketnummer',
    timeCreated: 'Zeiteintrag',
    timeDelivered: 'Tatsächliche Lieferzeit',
    timeExpectedDelivery: 'Gewünschte Lieferzeit',
    timeHoursMinutes: 'hh:mm',
    timeInstore: 'Startzeit der Küche',
    timeLeftStore: 'Endzeit der Küche',
    timeWaited: 'Wartezeit',
    title: 'Titel',
    toGo: 'To Go Formel',
    total: 'Total',
    translations: 'Übersetzungen',
    type: 'Typ',
    unit: 'Einheit',
    useShiftBaseYN: 'Shiftbase Benutzer',
    username: 'Benutzername',
    value: 'Wert',
    vatNumber: 'MwSt.-Nummer',
    vatRateOption: 'MwSt-Tarif',
    vehicle: 'Fahrzeug',
    vehicleType: 'Fahrzeug Typ',
    vehicleTypes: 'Fahrzeug Typen',
    vehicles: 'Fahrzeuge',
    warning: 'Warnung',
    yes: 'Ja'
  },
  computerAccess: {
    accessYN: 'Zustimmung',
    code: 'Code',
    createdAt: 'Datum/Zeit Anfrage Zugang',
    ip: 'IP-Adresse',
    locale: 'Lokal',
    userAgent: 'Browser'
  },
  configuration: {
    code: 'Code',
    fromDate: 'Gültig ab',
    value: 'Wert'
  },
  coupon: {
    conditions: 'Bedingungen',
    couponType: 'Aktionstyp',
    createdByRestaurantName: 'Erstellt von',
    customer: 'Spezifisch für Kunde',
    discountAmount: 'Rabattbetrag',
    discountPercentage: 'Rabattprozentsatz',
    endDateTime: 'Gültig bis einschließlich',
    externalProductID: 'Externe Produkt-ID',
    minimumSpending: 'Minimalbetrag',
    oncePerOrder: 'Einmal pro Bestellung',
    onlyUseOnce: 'Einmalig zu benutzen',
    restaurants: 'Restaurants',
    startDateTime: 'Gültig ab'
  },
  customer: {
    account: 'Hat Account',
    blackList: 'Schwarze Liste',
    excludeFromDirectMailYN: 'Senden Sie keine direkte E-Mail.',
    hasAccount: 'VIP',
    newsletter: 'Newsletter'
  },
  dashboard: {
    revenueChart: 'Umsatzentwicklung',
    sparewareNews: 'Formel Neuigkeiten',
    sparewareNewsSubtitle: 'Die neuesten Updates der Formel'
  },
  dayOfWeek: {
    friday: 'Freitag',
    monday: 'Montag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
    thursday: 'Donnerstag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch'
  },
  deliveryAppUser: {
    jwtToken: 'Bearer Token',
    tokenIssuedAt: 'Ausgegeben',
    tokenValidUntil: 'Gültig bis'
  },
  deliveryMethod: {
    delivery: 'Lieferung',
    dining: 'Dinieren',
    takeOut: 'Abholen'
  },
  deliveryReport: {
    average: 'Durchschnitt',
    customerWaitingTime: 'Wartezeit Kunde (min)',
    deliveryDude: 'Kurier',
    deliveryDuration: 'Lieferzeit (Min)',
    groupByDate: 'Nach Datum sortieren',
    groupByDeliverer: 'Nach Kurier sortieren',
    max: 'Max',
    min: 'Min',
    numberOfTickets: 'Anzahl Tickets',
    period: 'Zeitraum',
    timeInKitchen: 'Zeit in Küche (min)'
  },
  editor: {
    entityFetchError: 'Dieser Artikel existiert nicht (mehr) oder Sie haben keinen Zugriff auf die Daten dieser Seite.'
  },
  employee: {
    change: 'Wechselgeld',
    cost: 'Kosten',
    employment_date_error: 'Das Datum der Inbetriebnahme darf nicht vor dem Datum der Außerbetriebnahme liegen.',
    employment_end_date: 'Datum außer Dienst',
    employment_start_date: 'Datum Einstellung',
    firebaseUID: 'Firebase UID',
    hourly_wage: 'Stundenlohn',
    is_delivery_dude: 'Ist Kurier',
    is_restaurant_manager: 'Ist Filialmanager',
    residence: 'Wohnort',
    worklogs: 'Stunden'
  },
  employeeWorklog: {
    amount: 'Betrag',
    amountToBeSettled: 'Abrechnen',
    breakDuration: 'Pause',
    changePaymentMethodError: 'De beSprachewijze kon niet worden aangepast',
    changePaymentMethodSuccess: 'Die Zahlungsmethode wurde geändert',
    createReport: 'Nachricht herunterladen',
    days: 'Tage',
    employee: 'Arbeitnehmer',
    employeeName: 'Name Arbeitnehmer',
    endsAt: 'Endzeit',
    fillInCompleteForm: 'Wählen Sie den Arbeitnehmer aus und geben Sie das Datum und die geleisteten Stunden ein',
    fromDate: 'Von',
    hours: 'Stunden',
    isExcluded: 'Nicht zugewiesen',
    isIncluded: '{name}',
    minutes: 'Minuten',
    numberOfTickets: 'Anzahl Tickets',
    orderNotFound: "Bestellung mit Ticketnummer '{ticketNumber}' nicht gefunden in der Liste",
    paymentMethod: 'Bezahlweise',
    remarks: 'Notiz',
    save: 'Speichern',
    scanTicket: 'Tickets Scannen',
    selectEmployee: 'Alle Arbeitnehmers',
    startedAt: 'Anfangszeit',
    ticketNumber: 'Ticketnummer',
    totalAmount: 'Gesamt',
    totalCosts: 'Kosten',
    totalElectronicAmount: 'Elektronisch',
    totalGiftcardAmount: 'Geschenkgutschein',
    totalHours: 'Stunden (h:mm)',
    totalInvoiceAmount: 'Auf Rechnung/Sonstige',
    totalTicketAmount: 'Gesamt Bons',
    totalWage: 'Lohn',
    untilDate: 'Bis einschließlich',
    workedAt: 'Datum',
    worklogsCreated: 'Die Stunden sind gespeichert'
  },
  error: {
    general_request: 'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
    oneOrMoreInputs: 'Daten können nicht gespeichert werden, weil das Formular Fehler enthält.',
    request_401: 'Sie sind nicht befugt',
    request_403: 'Sie haben keine Erlaubnis für diese Aktion',
    save: 'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.'
  },
  errorLog: {
    description:
      'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction',
    tips: 'Please click the bug icon in the upper right corner'
  },
  errorPages: {
    backToHome: 'Zurück zur Startseite',
    headline401: 'Sie können diese Seite nicht aufrufen',
    headline403: 'Sie können diese Seite nicht aufrufen',
    headline404: 'Seite nicht gefunden',
    message401: 'Wenn Sie damit nicht einverstanden sind, wenden Sie sich bitte an Ihren Administrator..',
    message403: 'Wenn Sie damit nicht einverstanden sind, wenden Sie sich bitte an Ihren Administrator..',
    message404: 'Prüfen Sie, ob die eingegebene URL korrekt ist.',
    return: 'Zurück',
    title401: 'Ups',
    title403: 'Zugang verweigert',
    title404: 'Sorry'
  },
  eventlog: {
    exception: 'Ausnahme',
    level: 'Level',
    logger: 'Logger',
    message: 'Nachricht',
    occurredAt: 'Datum Zeit',
    thread: 'Thread'
  },
  forgotPassword: {
    description:
      'Sie können Ihr Passwort zurücksetzen. Füllen Sie das nachstehende Formular aus, um die Anweisungen per E-Mail zu erhalten.',
    done: 'Es wurde eine E-Mail an {email} gesendet. Bitte befolgen Sie die darin enthaltenen Anweisungen.',
    submit: 'E-Mail verschicken',
    title: 'Passwort vergessen'
  },
  home: {
    welcomeVisitor: 'Willkommen Besucher'
  },
  homepageBlock: {
    blockType: 'Typ Block',
    contentPlaceholder: 'Geben Sie hier den HTML-Code mit dem Inhalt der Website ein',
    country: 'Land',
    ribbonText: 'Multifunktionsleiste Text',
    ribbonTextPlaceholder: 'Dieser Text erscheint auf der Multifunktionsleiste',
    ribbonType: 'Multifunktionsleiste Typ',
    url: 'URL',
    website: 'Website'
  },
  language: {
    code: 'ISO 639-1 Code'
  },
  localProduct: {
    localProductCategory: 'Kategorie',
    online: 'Online'
  },
  login: {
    code: 'Benutzername',
    forbidden: 'Benutzer/IP Kmobination ist blockiert.',
    impersonationSuccess: 'Sie sind jetzt als anderer Benutzer angemeldet',
    logIn: 'Einloggen',
    password: 'Passwort',
    title: 'Einloggen',
    unauthorised: 'Diese Login-Daten sind nicht korrekt.'
  },
  loyaltyPoints: {
    points: 'Punkten',
    type: 'Aktion'
  },
  mail: {
    bcc: 'Bcc',
    body: 'Nachricht',
    cc: 'Cc',
    error: 'Es ist ein Problem aufgetreten. Ihre E-Mail wurde nicht gesendet.',
    from: 'Von',
    subject: 'Betreff',
    success: 'Ihre E-Mail wurde erfolgreich gesendet',
    to: 'An'
  },
  menu: {
    availableForThuisbezorgd: 'Thuisbezorgd',
    cowsMilkProtein: 'Kuhmilcheiweiß',
    digestibleCarbohydrates: 'Prozentsatz der verdaulichen Kohlenhydrate',
    displayName: 'Name auf der Website',
    extraProductLine: 'Zusätzliche Produkt auf der Bon',
    extraProductLineTooltip:
      'Diese Zeile wird immer auf den Kassenbon gedruckt. Zum Beispiel: „Mit Krabbencrackern und Zwiebeln“',
    fat: 'Fettprozentsatz',
    gluten: 'Gluten',
    imageTooltip: 'Ein Menü wird auf der Website nur angezeigt, wenn ein Bild hochgeladen wurde',
    kiloCalorie: 'Kilokalorien',
    kiloJoule: 'Kilojoule',
    loyaltyPointsTooltip:
      'Die Anzahl der Punkte, die dieses Menü im VIP-Shop kostet. Lassen Sie 0, wenn dieses Menü NICHT im VIP-Shop sichtbar sein soll',
    menuBlockOptions: 'Optionen',
    menuBlockType: 'Typ',
    menuBlockTypes: {
      moreMainProduct: 'Zusätzliche Menge des Hauptprodukts',
      sauce: 'Sauce',
      sideDish: 'Beilage',
      taste: 'Geschmack',
      topping: 'Topping'
    },
    nameOnTicket: 'Name auf der Bon',
    nameTooltip: 'Den sprachspezifischen Namen können Sie unter Übersetzungen eingeben (Name auf der Website)',
    nutritionValuesUnit: 'Nährwert pro',
    onlineTooltip: 'Ein Menü wird nur dann auf der Website angezeigt, wenn es aktiv ist',
    popupInfo: 'Pop-up Text',
    positionInMenu: 'Reihenfolge',
    pricesTooltip:
      'Eine Menü wird nur dann auf der Website angezeigt, wenn hier der Preis für die Kategorie des gewählten Restaurants eingetragen ist',
    restaurantsTooltip:
      'Eine Menü wird nur dann auf der Website angezeigt, wenn das gewählte Restaurant hier angekreuzt ist',
    showPricesYN: 'Preise zeigen',
    subtitle: 'Unterschrift',
    takeAwayID: 'Thuisbezorgd ID'
  },
  menuBlockOption: {
    default: 'Standard',
    displayName: 'Name auf der Website',
    displayNameDe: 'Deutsch',
    displayNameNl: 'Niederländisch',
    menuCategoryName: 'Name der Menükategorie',
    numberOfProduct: '#',
    price: 'Zuschlag für produkte',
    priceAlmere: 'Almere',
    priceDuitsland: 'Deutschland',
    priceGroningen: 'Groningen',
    priceHoog: 'Hoch',
    priceLaag: 'Gering',
    priceMiddel: 'Mittel',
    product: 'Produkt',
    productCategoryName: 'Name der Produktkategorie',
    sortOrder: 'Reihenfolge'
  },
  messageTemplate: {
    body: 'Nachricht',
    messageType: 'Typ Nachricht',
    subject: 'Betreff'
  },
  monitoredProcess: {
    createdAt: 'Erstellungsdatum',
    expectedIntervalInSeconds: 'Intervall (s)',
    lastError: 'Fehler',
    lastRunAt: 'Letzter Lauf',
    nextRunAt: 'Nächster Lauf'
  },
  navbar: {
    dashboard: 'Dashboard',
    language: 'Sprache',
    logOut: 'Ausloggen',
    reportBug: 'Störung melden',
    screenfull: 'Screenfull',
    size: 'Gesamtgröße',
    theme: 'Thema'
  },
  newPassword: {
    description: 'Sie können Ihr Passwort jetzt ändern.',
    done: 'Ihr Passwort wurde geändert.',
    invalidToken: 'Dieser Link ist abgelaufen.',
    submit: 'Passwort ändern',
    title: 'Neues Passwort'
  },
  orderFromRobex: {
    loginToRobexWebShop: 'Klicken Sie auf diesen Link, um den Robex-Webshop in einer neuen Registerkarte zu öffnen',
    ordering: 'Bestellen',
    requestAccessToRobexWebShop:
      'Sie haben noch keinen Zugang zum Robex-Webshop. Bitte wenden Sie sich an die Hauptverwaltung, um den Zugang zu beantragen.'
  },
  page: {
    inFooterMenu: 'In Fußzeile',
    keywords: 'Schlüsselworte',
    shortDescription: 'Kurze Beschreibung',
    template: 'Template'
  },
  permission: {
    AccessLog: 'Access Log',
    AppSetting: 'Appsettings',
    ComputerAccess: 'Zugelassene Computer',
    Configuration: 'Einstellungen',
    Country: 'Länder',
    CouponCode: 'Rabattcodes',
    Customer: 'Kunden',
    CustomerAddress: 'Kundenadressen',
    DeliveryApp: 'Liefer-App',
    DeliveryAppUser: 'Benutzer der Liefer-App',
    DeliveryReport: 'Lieferbericht',
    DiscountCondition: 'Rabattbedingung',
    DiscountRule: 'Rabattlinie',
    Domain: 'Domains',
    EmailMarketing: 'Email Marketing',
    Employee: 'Arbeitnehmer',
    EmployeeWorklog: 'Personalstunden',
    EventLog: 'Event Log',
    ExternalApiUser: 'Externe API Nutzer',
    HomePageBlock: 'Homepage Blöcke',
    ImpersonateUser: 'Als andere Benutzer anmelden',
    KassaUser: 'Checkout-logins',
    Language: 'Sprachen',
    LegacySpareware: 'Alte Spareware',
    LocalProduct: 'Lokale Produkte',
    LocalProductCategory: 'Lokale Produktkategorien',
    LogUpdateDeliveryTime: 'Lieferzeiten protokollieren',
    LoyaltyPointsTransaction: 'Vip-Punkt-Transaktionen',
    MediaLibrary: 'Medienbibliothek öffnen',
    Menu: 'Speisekarten',
    MenuCategory: 'Speisekartenkategorien',
    MessageTemplate: 'Nachrichtenvorlagen',
    MonitoredProcess: 'Überwachte Prozesse',
    MyComputerAccess: 'Filialmanager - Zugelassene Computer',
    MyCouponCodes: 'Filialmanager - Rabattcodes',
    MyDeliveryReport: 'Filialmanager - Lieferbericht',
    MyEmployees: 'Filialmanager - Arbeitnehmer',
    MyLegacySpareware: 'Filialmanager - Alte Spareware',
    MyLocalProduct: 'Filialmanager - Lokale Produkte',
    MyLocalProductCategory: 'Filialmanager - Lokale Produkten Kategorien',
    MyLocalProducts: 'Filialmanager - Lokale Produkte',
    MyOrderHistory: 'Filialmanager - Bestellverlauf',
    MyRevenueReport: 'Filialmanager - Umsatzbericht',
    MyReviewsBureauDeWit: 'Filialmanager - Bureau de Wit',
    MyStatistics: 'Filialmanager - Statistiken',
    MyVehicles: 'Filialmanager - Fahrzeuge',
    OpeningHours: 'Filialmanager - Öffnungszeiten',
    Option: 'Optionslisten',
    OrderFromRobex: 'Bestellen bei Robex',
    OrderFromRobexWooCommerce: 'Bestellen bei Robex (neu)',
    OrderHistory: 'Bestellverlauf',
    OrderServiceBus: 'Order Service Bus',
    Page: 'Seiten',
    Partnership: 'Partnerships',
    Permission: 'Erlaubnisse',
    PinDevice: 'Pin-Geräten',
    PostcodeRestaurant: 'Postleitzahlen',
    PriceCategory: 'Preiskategorien',
    Printer: 'Drucker',
    Product: 'Produkte',
    ProductCategory: 'Produktkategorien',
    ReportEmployeeWorklog: 'Personalstunden Bericht',
    RequestForm: 'Anfrageformular',
    Restaurant: 'Restaurants',
    RestaurantUser: 'Filialmanager',
    RevenueChart: 'Umsatzgrafik',
    RevenueReport: 'Umsatzbericht',
    RevenueReportRealtime: 'Actueles Umsatzbericht',
    ReviewsBureauDeWit: 'Bureau de Wit',
    Role: 'Rollen',
    ScratchAndWinAction: 'Scratch and win actions',
    SpareWareNews: 'Spareware News',
    StandardUser: 'Standard-Nutzer',
    Statistics: 'Statistiken',
    StatisticsBureauDeWit: 'Statistiken Bureau de Wit',
    StatisticsCustomerSatisfaction: 'Statistiken Gastfreundschaft',
    StatisticsMediaDashboard: 'Statistiken media dashboard',
    StatisticsPostcodeAnalytics: 'Statistiken Postleitzahlanalyse',
    StatisticsRevenueComparison: 'Statistiken Umsatzvergleich',
    StatisticsRevenueDevelopment: 'Statistiken Umsatzentwicklung',
    StatisticsScorecard: 'Statistiken Scorecard',
    TimeRegistrationTerminal: 'Ein- und Ausstempeln',
    Translation: 'Übersetzungen',
    User: 'Benutzer',
    Vehicle: 'Fahrzeuge',
    VehicleType: 'Fahrzeugtypen',
    execute: 'Ausführen',
    none: 'Keine',
    pageIsReadonly: 'Sie sind nicht berechtigt, die Daten auf dieser Seite zu ändern.',
    read: 'Lesen',
    roles: 'Ihre Rollen',
    selectPlaceholder: 'Wählen Sie die Zugriffsstufe',
    switchRoles: 'Role wechseln',
    tabPermissions: 'Erlaubnisse',
    tabTaskTypes: 'Aufgabentypen',
    write: 'Schreiben'
  },
  pickrec: {
    chooseEntryFor: '"{caption}" auswählen',
    itemNotFound: "'{caption}' nicht gefunden",
    suggestionsMayBeMore: 'Es kann mehr Suchergebnisse geben',
    suggestionsRestCount: 'Es gibt {count} andere Suchergebnisse'
  },
  pinDevice: {
    deviceType: 'Gerätetyp',
    ecrID: 'ECR ID',
    status: 'Transaktionsstatus',
    transactionID: 'Transaktion ID',
    wakeupIpAddress: 'Wake up IP-address',
    wakeupIpPort: 'Wake up IP-port'
  },
  printer: {
    expectStatus: 'Sendet Status',
    printProvider: 'Provider',
    printTestPage: 'Testseite drucken',
    statusOption: 'Status',
    statusReceivedAt: 'Status erhalten um',
    statusText: 'Erhaltener Status',
    testPagePrinted: 'Die Testseite wurde an den Drucker gesendet',
    typeOption: 'Typ'
  },
  product: {
    closingTime: 'Verkauf bis',
    cowsMilkProteinYN: 'Kuhmilcheiweiß',
    deposit: 'Pfand',
    description: 'Beschreibung auf der Seite',
    digestibleCarbohydrates: 'Verdauliche Kohlenhydrate',
    fat: 'Fett',
    glutenYN: 'Gluten',
    image: 'Abbildung',
    kiloCalorie: 'Kilokalorie',
    kiloJoule: 'Kilojoule',
    largeImage: 'Großes Bild 359x170 Pixel',
    menuBlockType: 'Als Menüoption verfügbar',
    nameOnTicket: 'Name auf der Quittung',
    nutritionValuesUnit: 'Einheit',
    onlyTasteYN: 'Nur Geschmack',
    prices: 'Preise',
    restaurants: 'Erhältlich',
    sauceYN: 'Sauce',
    showInNewBlockInCashDeskYN: 'Neuen Block im Checkout anzeigen',
    showLargeInSiteTooltip:
      'Wählen Sie diese Option, wenn Sie das Produkt in der Größe gleich wie ein Menu anzeigen möchten. (Wie zum Beispiel eine Flaguette)',
    showLargeInSiteYN: 'GGroß anzeigen',
    smallImage: 'Kleines Bild 97x97 Pixel',
    subtitle: 'Subtext auf der Website',
    supChargeYN: 'Ist SUP Abgabe',
    tabNutritionalValues: 'Nährwerte',
    tabRestaurants: 'Restaurants',
    tasteYN: 'Geschmack',
    toppingYN: 'Topping',
    vipOnlyYN: 'VIP only'
  },
  report: {
    fromDate: 'Von',
    untilDate: 'Bis einschließlich'
  },
  request: {
    networkError: 'Das Netzwerk ist derzeit nicht zugänglich.'
  },
  requestForm: {
    formTitle: 'Anfrageformular',
    openRequestForm: 'Open het verzoekformulier in een new tab'
  },
  restaurant: {
    addressCheckCompletedYN: 'NAW Check komplett',
    cashPaymentYN: 'Zahlung in bar',
    cashdeskDirectPrint: 'Kasse direkt drucken',
    cashdeskDomain: 'Checkout-Bereich',
    closed: 'Geschlossen',
    closingTime: 'Zeit geschlossen',
    defaultDeliveryMethod: 'Standardlieferung',
    deliveryCharge: 'Lieferkosten',
    deliveryCostsLimit: 'Grenze für die Liefergebühr',
    deliveryTime: 'Lieferzeit (m)',
    detailPageBlock1: 'Website-Infoblock 1',
    detailPageBlock2: 'Website-Infoblock 2',
    freeDeliveryThreshold: 'Kostenlose Lieferung von',
    giftcardYN: 'Geschenkkarte',
    idealYN: 'iDeal Bezahlung',
    insocialCustomerID: 'Insocial Kundennummer',
    isTSGCustomerYN: 'TSG Kunde',
    minimumOrderValue: 'Minimaler Bestellwert',
    mobilePin: 'Verfügt über einen mobilen Zahlungsautomaten.',
    nameOnTicket: 'Handelsname auf der Quittung',
    newPos: 'Neue POS',
    numberOfTickets: 'Anzahl Quittungen',
    open: 'Öffnet',
    openingHours: 'Öffnungszeiten',
    openingHoursValidationError: 'Öffnungszeiten prüfen',
    openingSoonYN: 'Öffnet bald',
    openingTime: 'Öffnungszeit',
    openingTimeForDelivery: 'Öffnungszeiten für Anlieferung',
    openingTimeForPickup: 'Öffnungszeiten',
    openingTimesStatement: 'Statement Öffnungszeiten',
    partnershipShiftbase: 'Diese Partnerschaft unterstützt ShiftBase nicht',
    pinDevices: 'Pin devices',
    postcodeOpeningHoursValidationError: 'Postleitzahlen und Öffnungszeiten prüfen',
    postcodeUniquenessViolation: '{alreadyUsedBy}',
    postcodes: 'Postleitzahlen',
    queuePrinterClientCode: 'Client-Code des Druckers',
    redirectOrderUrl: 'Weiterleitung nach Bestellung',
    redirectSearchUrl: 'Umleitung nach Filialwechsel',
    robexCustomerNumber: 'Robex Kundennummer',
    showDayRevenueYN: 'Tagesumsatz anzeigen',
    showDeliveryScreen: 'Lieferschirm anzeigen',
    tabCashDesk: 'Kasse',
    tabContactPersons: 'Kontaktpersonen',
    takeOutDiscountPercentage: 'Prozentualer Rabatt zum Mitnehmen',
    takeOutPossible: 'Abholen möglich',
    testRestaurantYN: 'Restaurant zum Testen',
    ticketFooter: 'Quittung Fußzeile',
    ticketHeader: 'Quittung Kopfzeile',
    useNewPosYN: 'Neue POS verwenden',
    website: 'Filiale Detailseite'
  },
  revenueReport: {
    amount: 'Total',
    amountVATHigh: 'MwSt. hoch',
    amountVATLow: 'MwSt. niedrig',
    bringYourOwnCount: 'Anzahl Eigenes mitbringen',
    customerAverageRevenue: 'Durchschnittlicher Betrag pro Kunde',
    daysCount: 'Anzahl von Tagen',
    employeeHours: 'Gearbeitete Stunden',
    employeeLaborCosts: 'Lohnkosten netto',
    employeeLaborCostsPercentage: 'Bruttolohnkosten in Prozent vom Umsatz',
    employeeNumberOfDays: 'Anzahl von Tagen',
    employeeOtherCosts: 'Kosten',
    header: 'Restaurant {restaurant}: Umsatz vom {fromDate} bis {untilDate}',
    lastUpdated: 'Aktualisiert auf',
    ordersAppCount: 'Anzahl der Belege Apps',
    ordersByPhoneCount: 'Anzahl der Belege telefonisch',
    ordersOthersCount: 'Anzahl der Belege Sonstige',
    ordersPayterCount: 'Anzahl der Belege Payter',
    ordersRestaurantCount: 'Anzahl der Belege Geschäft',
    ordersSneletenCount: 'Anzahl der Belege Sneleten',
    ordersThuisbezorgdCount: 'Anzahl der Belege Thuisbezorgd',
    ordersWebsiteCount: 'Anzahl der Belege Website',
    position: 'Positie',
    revenue: 'Umsatz',
    revenueAppsTotal: 'Umsatz Apps',
    revenueDiningCash: 'Umsatz dinieren bar',
    revenueDiningPIN: 'Umsatz dinieren PIN',
    revenueGiftCard: 'Umsatz Geschenkgutschein',
    revenueOnCredit: 'Umsatz auf Rechnung',
    revenueOthers: 'Umsatz Sonstige',
    revenuePayter: 'Umsatz Payter',
    revenuePhoneCash: 'Umsatz telefonischer Kontakt',
    revenuePhoneElectronic: 'Umsatz telefonisch elektronisch',
    revenuePhonePIN: 'Umsatz telefonisch PIN',
    revenueSneletenCash: 'Umsatz Sneleten bar',
    revenueSneletenElectronic: 'Umsatz Sneleten elektronisch',
    revenueSneletenPIN: 'Umsatz Sneleten PIN',
    revenueTakeAwayCash: 'Umsatz abholen bar (im Restaurant bestellt)',
    revenueTakeAwayPIN: 'Umsatz abholen PIN (im Restaurant bestellt)',
    revenueThuisbezorgdCash: 'Umsatz Thuisbezorgd bar',
    revenueThuisbezorgdElectronic: 'Umsatz Thuisbezorgd elektronisch',
    revenueThuisbezorgdPIN: 'Umsatz Thuisbezorgd PIN',
    revenueWebsiteCash: 'Umsatz Website bar',
    revenueWebsiteElectronic: 'Umsatz Website elektronisch',
    revenueWebsitePIN: 'Umsatz Website PIN',
    totalCredited: 'Gutgeschrieben',
    totalCustomersCount: 'Anzahl der Kunden',
    totalDeliveryCosts: 'Totale Lieferkosten',
    totalDeposit: 'Totales Pfand',
    totalOrdersCount: 'Anzal der Belege Total',
    totalPayed: 'Geprüfte Quittungen',
    totalRevenueCash: 'Total bar',
    totalRevenueElectronic: 'Total elektronisch',
    totalRevenueExcludingVAT: 'Umsatz ohne MwSt.',
    totalRevenueGiftCard: 'Total Geschenkgutschein',
    totalRevenueIncludingVAT: 'Umsatz mit MwSt.',
    totalRevenueNotSettled: 'Nicht geprüfte Quittungen',
    totalRevenueOnCredit: 'Total auf Rechnung',
    totalRevenuePIN: 'Total PIN',
    totalRevenueVATHigh: 'Umsatz MwSt. hoch',
    totalRevenueVATLow: 'Umsatz MwSt. niedrig',
    totalSupCharges: 'Total Einwegsplastikabgabe',
    totalUniqueCustomersCount: 'Anzahl der einzigartigen Kunden',
    vat: 'MwSt.'
  },
  reviewsBureauDeWit: {
    header: 'Bureau de Wit',
    loginToReviewsBureauDeWit: 'Klicken Sie auf diesen Link, um die Bewertungen in einer neuen Registerkarte zu öffnen'
  },
  route: {
    Table: 'Table',
    avatarUpload: 'Avatar Hochladen',
    clipboardDemo: 'Zwischenablage',
    complexTable: 'Complex Tabelle',
    componentIndex: 'Vorstellung',
    componentMixin: 'Mixin',
    components: 'Komponenten',
    countTo: 'CountTo',
    createContactPerson: 'Kontactperson erstellen',
    createCountry: 'Land erstellen',
    createCouponCode: 'Rabattcode erstellen',
    createCustomer: 'Kunde erstellen',
    createCustomerAddress: 'Adresse erstellen',
    createDeliveryAppUser: 'Benutzer der Liefer-App erstellen',
    createEmployee: 'Arbeitnehmer erstellen',
    createEmployeeWorklog: 'Stunden erstellen',
    createExternalApiUser: 'Externe API Benutzer erstellen',
    createHomePageBlock: 'Homepage Blöcke erstellen',
    createKassaUser: 'Checkout-login erstellen',
    createLanguage: 'Sprache erstellen',
    createLocalProduct: 'Lokales Produkt erstellen',
    createLocalProductCategory: 'Lokale Produktkategorie erstellen',
    createLocalProducts: 'Lokales Produkt erstellen',
    createLoyaltyPointsTransaction: 'Vip Punkte Transaktion erstellen',
    createMenu: 'Speisekarte erstellen',
    createMenuBlock: 'Menüpunkte erstellen',
    createMenuCategory: 'Speisekarten-Kategorie erstellen',
    createMessageTemplate: 'Nachrichtenvorlage erstellen',
    createMonitoredProcess: 'Überwachter Prozess erstellen',
    createMyCouponCode: 'Rabattcode erstellen',
    createPage: 'Seite erstellen',
    createPartnership: 'Partnerschaft erstellen',
    createPinDevice: 'Pin device erstellen',
    createPriceCategory: 'Preiskategorie erstellen',
    createPrinter: 'Drucker erstellen',
    createProduct: 'Produkt erstellen',
    createProductCategory: 'Produktkategorie erstellen',
    createRestaurant: 'Restaurant erstellen',
    createRestaurantUser: 'Filialmanager erstellen',
    createRole: 'Rolle erstellen',
    createScratchAndWinAction: 'Eine Rubbel- und Gewinnaktion erstellen',
    createScratchAndWinBatch: 'Eine Rubbel- und Gewinn Charge erstellen',
    createUser: 'Benutzer erstellen',
    createVehicle: 'Fahrzeug erstellen',
    createVehicleType: 'Fahrzeugtyp erstellen',
    customTreeTable: 'Benutzerdefiniertes Baumdiagramm',
    dashboard: 'Dashboard',
    directivePermission: 'Directive Erlaubnis',
    documentation: 'Dokumentation',
    dragDialog: 'Drag Dialog',
    dragTable: 'Drag Tabelle',
    dropzone: 'Dropzone',
    dynamicTable: 'Dynamische Tabelle',
    editComputerAccess: 'Autorisierten Computer bearbeiten',
    editConfiguration: 'Anpassen der Einstellung',
    editContactPerson: 'Kontaktperson Bearbeiten',
    editCountry: 'Land bearbeiten',
    editCouponCode: 'Rabattcode bearbeiten',
    editCustomer: 'Kunde bearbeiten',
    editCustomerAddress: 'Adresse bearbeiten',
    editDeliveryAppUser: 'Benutzer der Liefer-App bearbeiten',
    editEmployee: 'Arbeitnehmer bearbeiten',
    editEmployeeWorklog: 'Stunden bearbeiten',
    editExternalApiUser: 'Externe API Benutzer bearbeiten',
    editHomePageBlock: 'Homepage Blöcke bearbeiten',
    editKassaUser: 'Checkout-login bearbeiten',
    editLanguage: 'Sprache bearbeiten',
    editLocalProduct: 'Lokales Produkt bearbeiten',
    editLocalProductCategory: 'Lokale Produktkategorie bearbeiten',
    editLoyaltyPointsTransaction: 'Vip Punkte Transaktion bearbeiten',
    editMenu: 'Speisekarte bearbeiten',
    editMenuBlock: 'Menüpunkte bearbeiten',
    editMenuCategory: 'Speisekartenkategorie bearbeiten',
    editMessageTemplate: 'Nachrichtentemplate bearbeiten',
    editMonitoredProcess: 'Überwachter Prozess bearbeiten',
    editMyComputerAccess: 'Autorisierten Computer bearbeiten',
    editMyCouponCode: 'Rabattcode bearbeiten',
    editMyLocalProduct: 'Lokales Produkt bearbeiten',
    editPage: 'Seite bearbeiten',
    editPartnership: 'Partnerschaft bearbeiten',
    editPinDevice: 'Pin device bearbeiten',
    editPostcodeRestaurant: 'Region bearbeiten',
    editPriceCategory: 'Preiskategorie bearbeiten',
    editPrinter: 'Drucker bearbeiten',
    editProduct: 'Produkt bearbeiten',
    editProductCategory: 'Produktkategorie bearbeiten',
    editRestaurant: 'Restaurant bearbeiten',
    editRestaurantUser: 'Filialmanager bearbeiten',
    editRole: 'Rolle bearbeiten',
    editScratchAndWinAction: 'Eine Rubbel- und Gewinnaktion bearbeiten',
    editScratchAndWinBatch: 'Eine Rubbel- und Gewinn Charge bearbeiten',
    editStandardUser: 'Benutzer bearbeiten',
    editTranslation: 'Übersetzung bearbeiten',
    editVehicle: 'Fahrzeug bearbeiten',
    editVehicleType: 'Fahrzeugtyp bearbeiten',
    errorLog: 'Fehler Log',
    errorPages: 'Fehler Seiten',
    example: 'Beispiel',
    excel: 'Excel',
    exportExcel: 'Excel Exportieren',
    exportPDF: 'PDF Exportieren',
    exportZip: 'Zip Exportieren',
    externalLink: 'Externer Link',
    form: 'Form',
    guide: 'Anleitung',
    home: 'Home',
    i18n: 'I18n',
    icons: 'Icons',
    inlineEditTable: 'Inline Edit',
    introduction: 'Einführung',
    jsonEditor: 'JSON Editor',
    markdown: 'Markdown',
    menu_admin: 'Verwalten',
    menu_admin_accesslog: 'Zugangs Log',
    menu_admin_application: 'Applikation',
    menu_admin_appsettings: 'Appsettings',
    menu_admin_company_records: 'Angaben zum Unternehmen',
    menu_admin_configuration: 'Einstellungen',
    menu_admin_countries: 'Länder',
    menu_admin_deliveryapp_users: 'Nutzer der Liefer-App',
    menu_admin_eventlog: 'Event Log',
    menu_admin_external_api_users: 'Externe API Logins',
    menu_admin_languages: 'Sprachen',
    menu_admin_messagetemplates: 'Nachrichtenvorlagen',
    menu_admin_options: 'Auswahllisten',
    menu_admin_restaurant_users: 'Filialmanager',
    menu_admin_roles: 'Rollen',
    menu_admin_security: 'Rollen und Benutzer',
    menu_admin_translations: 'Übersetzungen',
    menu_admin_users: 'Benutzer',
    menu_admin_vehicle_types: 'Fahrzeugtypen',
    menu_computer_accesses: 'Zugelassene Computer',
    menu_coupon_codes: 'Rabattcodes',
    menu_customers: 'Kunden',
    menu_delivery_report: 'Lieferung',
    menu_dishes: 'Gerichte',
    menu_employees: 'Arbeitnehmer',
    menu_employeeworklog: 'Stunden abrechnen',
    menu_employeeworklog_report: 'Personalstunden',
    menu_home_page_blocks: 'Homepage Blöcke',
    menu_kassa_users: 'Checkout-logins',
    menu_legacy_spareware: 'Alte Spareware',
    menu_local_product_categories: 'Lokale Produkt kategorien',
    menu_local_products: 'Lokale Produkte',
    menu_loyalty_points_transactions: 'Vip Punkte Transaktionen',
    menu_main_customers: 'Kundenverwaltung',
    menu_medialibrary: 'Medienbibliothek',
    menu_menu: 'Speisekarten',
    menu_menu_categories: 'Speisekartenkategorien',
    menu_monitored_processes: 'Überwachte Prozesse',
    menu_my_restaurant: 'Mein restaurant',
    menu_opening_hours: 'Öffnungszeiten',
    menu_pages: 'Seiten',
    menu_partnerships: 'Partnerschaften',
    menu_pin_devices: 'Pin Devices',
    menu_postcode_restaurants: 'Regionen',
    menu_price_categories: 'Preiskategorien',
    menu_product_categories: 'Produktkategorien',
    menu_products: 'Produkte',
    menu_reports: 'Berichte',
    menu_requestForm: 'Anfrageformular',
    menu_restaurants: 'Restaurants',
    menu_revenue_comparison: 'Umsatzvergleich',
    menu_revenue_development: 'Umsatzentwicklung',
    menu_revenue_report: 'Kasse Umsatz',
    menu_revenue_report_realtime: 'Aktuele Umsatzvergleich',
    menu_reviews_bureau_de_wit: 'Bureau de Wit',
    menu_scorecard: 'Scorecard',
    menu_scratch_and_win_actions: 'Kratzen und gewinnen',
    menu_statistics: 'Statistiken',
    menu_statistics_bureau_de_wit: 'Bureau de Wit',
    menu_statistics_customer_satisfaction: 'Gastfreundschaft',
    menu_statistics_media_dashboard: 'Media dashboard',
    menu_statistics_postcode_analytics: 'Postleitzahlenanalyse',
    menu_vehicles: 'Fahrzeuge',
    menu_website: 'Website',
    news: 'Neuigkeiten',
    orderFromRobex: 'Bestellen bei Robex',
    orderHistory: 'Bestellverlauf',
    page401: '401',
    page403: '403',
    page404: '404',
    pagePermission: 'Page Permission',
    permission: 'Erlaubnis',
    selectExcel: 'Ausgewählte Exportieren',
    splitPane: 'SplitPane',
    sticky: 'Sticky',
    tab: 'Tab',
    theme: 'Thema',
    tinymce: 'Tinymce',
    treeTable: 'Baumdiagramm',
    uploadExcel: 'Excel Hochladen',
    userSettings: 'Einstellungen Benutzer',
    zip: 'Zip'
  },
  scratchAndWin: {
    batchState: 'Status der Charge',
    conditionsUrl: 'URL der Aktionsbedingungen',
    confirmAlreadyExported:
      'Diese Datei wurde bereits heruntergeladen. Möchten Sie die Gewinncodes wirklich erneut herunterladen?',
    confirmDownloadCsv:
      'Geben Sie diese Datei nur einmal an der Druckerei, um doppelte Gewinncodes zu vermeiden. Sind Sie sicher, dass Sie die Gewinncodes jetzt herunterladen möchten?',
    couponCodePrefix: 'Präfix für den Aktionscode',
    couponType: 'Aktionstyp',
    couponValidDays: 'Gültigkeit des gewonnenen Rabattcode (Tage)',
    discountPercentage: 'Rabattprozentsatz',
    downloadCsv: 'Gewinncodes herunterladen',
    endDate: 'Gültig bis einschließlich',
    numberOfCards: 'Anzahl Karten',
    numberOfCardsGenerated: 'Anzahl der generierten Karten',
    product: 'Produkt',
    scratchAndWinPriceType: 'Chargentyp',
    sendPriceNotificationTo: 'Benachrichtigung bei Gewinn an',
    startDate: 'Gültig ab',
    tabBatches: 'Chargen',
    winText: 'Text {Price} = gewonnener Preis, {CouponCode} = Aktionscode), {ValidUntil} = Gültig bis',
    winTextGrandPrice: 'Text {FirstName} {LastName} {EmailAddress}'
  },
  size: {
    medium: 'Normal',
    mini: 'Kleinst',
    small: 'Klein'
  },
  stringInsert: {
    btnText: 'Einfügen...',
    closeOnInsert: 'Dialog nach dem Einfügen schließen',
    textIntro: 'Klicken Sie auf einen Text, um ihn einzufügen.',
    textIntroUnavailable: 'Es gibt keine Texte zum Einfügen.',
    textTitle: 'Text einfügen',
    toastInsert: 'Eingefügt: {name}'
  },
  table: {
    actions: 'Aktionen',
    searchDatePlaceholder: 'Eintippen oder auswählen',
    searchPlaceholder: 'Tippen um zu suchen',
    searchSelectPlaceholder: 'Auswählen um zu suchen'
  },
  tagsView: {
    close: 'Schließen',
    closeAll: 'Alle schließen',
    closeOthers: 'Andere schließen',
    refresh: 'Aktualisieren'
  },
  textArea: {
    placeholder: 'Ihr Text'
  },
  translation: {
    code: 'Übersetzungscode'
  },
  user: {
    avatar: 'Profilbild',
    avatarInfo: 'URL zum Profilbild',
    kassaEditAllowedYN: 'Dieser Benutzer darf gutschreiben',
    kassaLoginCode: 'Login-Code für die Kasse',
    newPassword: 'Neues Passwort',
    newPasswordInfo: 'Leer lassen, wenn Sie Ihr Passwort nicht ändern möchten',
    oldPassword: 'Aktuelles Passwort',
    oldPasswordInfo: 'Bitte geben Sie Ihr aktuelles Passwort zur Überprüfung ein',
    oldPasswordInvalid: 'Sie haben nicht das richtige aktuelle Passwort eingegeben',
    passwordChanged: 'Ihr Passwort wurde geändert',
    repeatPassword: 'Passwort wiederholen',
    repeatPasswordInfo: 'Wiederholen Sie Ihr Passwort zur Kontrolle',
    robexWebshopURL: 'Robex Autologin Link',
    roles: 'Rollen'
  },
  validation: {
    complexity: 'Komplexität auswählen',
    ean: 'Dieser Barcode ist falsch',
    email: 'Dies ist eine ungültige E-Mail Adresse',
    error: {
      alphanumeric: 'Mindestens 1 Symbol ist erforderlich',
      digit: 'Mindestens 1 Zahlsymbol ist erforderlich',
      isFromDatabase: 'Das Kennwort kann nicht validiert werden, da der Server nicht erreichbar ist.',
      itemNotFound: 'Die Datei wurde bereits gelöscht.',
      length: 'Mindestens {count} Zeichen sind erforderlich',
      lowercase: 'Mindestens 1 Kleinbuchstabe ist erforderlich',
      passwordComplexity: 'Das Passwort entspricht nicht der erforderlichen Komplexität',
      uppercase: 'Mindestens 1 Großbuchstabe ist erforderlich'
    },
    exceedMax: 'Dieser Wert ist zu groß',
    formValidationFailed:
      'Dieses Formular enthält Fehler. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut',
    notApplicable: 'Dieser Wert ist nicht zutreffend',
    numeric: 'Nur Zahlen erlaubt',
    oldPasswordRequired: 'Altes Passwort eingeben',
    postcode: 'Ungültige Postleitzahl',
    repeatPasswordMismatch: 'Zwei Kennwörter stimmen nicht überein',
    required: 'Dieses Feld ist erforderlich',
    time: 'Ungültige Zeit',
    unique: 'Dieser Wert ist nicht eindeutig',
    url: 'Dies ist eine ungültige URL'
  },
  vehicle: {
    code: 'Code/Nummernschild'
  },
  vehicleType: {
    travelMode: 'Reisemethode'
  },
  wizard: {
    next: 'nächste',
    prev: 'Zurück',
    skip: 'Überspringen'
  }
};
