import { deepSortObjectByKey, tryGetValue, objectsAreEqualExact } from '@/utils';
import Vue from 'vue';
import Debug from '@/utils/debug';
export default {
  namespaced: true,
  state: {
    sequenceNumber: 0,
    forms: {},
    initials: {},
    flattenedForm: {},
    valuesQueue: [],
    initQueue: [],
    actionSaveYN: false
  },
  actions: {
    register({ commit, state, dispatch }, { form }) {
      commit('INCREASE_SEQUENCE_NUMBER');
      const editorNumber = state.sequenceNumber;
      dispatch('updateForm', { form, editorNumber });
      return editorNumber;
    },
    unregister({ commit }, editorNumber) {
      commit('DELETE_FORM', editorNumber);
      commit('UPDATE_FLATTENED_FORM');
    },
    updateForm({ commit }, { form, editorNumber }) {
      commit('UPDATE_FORM', { form, editorNumber });
      commit('UPDATE_FLATTENED_FORM');
    },
    updateInitial({ commit }, { initial, editorNumber }) {
      commit('UPDATE_INITIAL', { initial, editorNumber });
    },
    clearForms({ commit }) {
      return commit('CLEAR_FORMS');
    },
    performSave({ commit }) {
      commit('SET_FLAG_ACTION_SAVE', true);
    }
  },
  getters: {
    form: state => editorNumber => state.forms[editorNumber],
    flattenedForm: state => state.flattenedForm,
    initial: state => editorNumber => state.initials[editorNumber],
    value: state => field => {
      const value = state.flattenedForm[field];
      return value;
    },
    initialValue: state => (field, editorNumber) => {
      const value = tryGetValue(state.initials, editorNumber, field);
      return value;
    },
    valuesQueueItem: state => editorNumber => {
      const queueItem = state.valuesQueue.find(x => x.editorNumber === editorNumber);
      return queueItem && queueItem.values;
    },
    initQueueItem: state => editorNumber => {
      const queueItem = state.initQueue.find(x => x.editorNumber === editorNumber);
      return queueItem && queueItem.values;
    },
    actionSaveYN: state => state.actionSaveYN,
    hasValuesChanged: state => ({ editorNumber, fieldNames, cleaners }) => {
      const comparedForm = {};
      const comparedInits = {};

      fieldNames.forEach(key => {
        const clean = tryGetValue(cleaners, key) || (value => value);
        comparedForm[key] = clean(tryGetValue(state.forms, editorNumber, key));
        comparedInits[key] = clean(tryGetValue(state.initials, editorNumber, key));
      });

      const sortedComparedForm = deepSortObjectByKey(comparedForm);
      const sortedComparedInits = deepSortObjectByKey(comparedInits);

      // Debug code
      Debug.logNotEqual(sortedComparedInits, sortedComparedForm);
      // End debug code
      return !objectsAreEqualExact(sortedComparedForm, sortedComparedInits);
    }
  },
  mutations: {
    INCREASE_SEQUENCE_NUMBER: state => {
      state.sequenceNumber++;
    },
    DELETE_FORM: (state, editorNumber) => {
      delete state.forms[editorNumber];
    },
    UPDATE_FLATTENED_FORM: state => {
      state.flattenedForm = Object.keys(state.forms).reduce(
        (flattened, key) => ({ ...flattened, ...state.forms[key] }),
        {}
      );
    },
    UPDATE_FORM: (state, { editorNumber, form }) => {
      Vue.set(state.forms, editorNumber, form);
    },
    UPDATE_INITIAL: (state, { editorNumber, initial }) => {
      state.initials[editorNumber] = initial;
    },
    CLEAR_FORMS: state => {
      state.forms = {};
      state.initials = {};
      state.flattenedForm = {};
      state.sequenceNumber = 0;
      state.valuesQueue = [];
      state.initQueue = [];
      state.actionSaveYN = false;
    },
    PUSH_VALUES_QUEUE: (state, { editorNumber, values }) => {
      state.valuesQueue.push({ editorNumber, values });
    },
    PUSH_INIT_QUEUE: (state, { editorNumber, values }) => {
      state.initQueue.push({ editorNumber, values });
    },
    CLEAR_VALUES_QUEUE_ITEM: (state, editorNumber) => {
      const index = state.valuesQueue.findIndex(x => x.editorNumber === editorNumber);
      if (index > -1) {
        state.valuesQueue.splice(index, 1);
      }
    },
    CLEAR_INIT_QUEUE_ITEM: (state, editorNumber) => {
      const index = state.initQueue.findIndex(x => x.editorNumber === editorNumber);
      if (index > -1) {
        state.initQueue.splice(index, 1);
      }
    },
    SET_FLAG_ACTION_SAVE: (state, flag) => (state.actionSaveYN = flag),
    CLEAR_FLAG_ACTION_SAVE: state => (state.actionSaveYN = false)
  }
};
