import { PermissionLevel } from '@/utils/permission';
import { getConfigurationFromLocalStorage } from '@/utils';

/**
 * Pages for reports
 */
export const reportRoutes = [
  {
    component: () => import('@/pages/deliveryreport/report.vue'),
    meta: {
      permissions: [{ DeliveryReport: PermissionLevel.Read }],
      title: 'menu_delivery_report'
    },
    name: 'DeliveryReportMyRestaurant',
    path: 'deliveryreport'
  },
  {
    component: () => import('@/pages/revenuereport/report.vue'),
    meta: {
      permissions: [{ RevenueReport: PermissionLevel.Read }],
      title: 'menu_revenue_report'
    },
    name: 'RevenueReportMyRestaurant',
    path: 'revenuereport'
  },
  {
    component: () => import('@/pages/revenuereport/realtime.vue'),
    meta: {
      permissions: [{ RevenueReportRealtime: PermissionLevel.Read }],
      title: 'menu_revenue_report_realtime'
    },
    name: 'RealtimeRevenueReportMyRestaurant',
    path: 'RevenueReportRealtime'
  },
  {
    component: () => import('@/pages/legacyspareware/index.vue'),
    meta: {
      permissions: [{ LegacySpareware: PermissionLevel.Read }],
      title: 'menu_legacy_spareware'
    },
    name: 'LegacySpareware',
    path: 'LegacySpareware',
    beforeEnter() {
      let sparewareUrl = localStorage.cashDeskUrl;
      if (sparewareUrl) {
        sparewareUrl += '/spareware';
      } else {
        sparewareUrl = getConfigurationFromLocalStorage('spareWareLegacy.URL');
      }
      window.open(sparewareUrl, '_blank');
    }
  },
  {
    component: () => import('@/pages/employeeworklog/report.vue'),
    meta: {
      permissions: [{ ReportEmployeeWorklog: PermissionLevel.Read }],
      title: 'menu_employeeworklog_report'
    },
    name: 'EmployeeWorklogReport',
    path: 'employeeWorklogReport'
  },
  {
    component: () => import('@/pages/reviewsBureauDeWit/index.vue'),
    meta: {
      permissions: [{ ReviewsBureauDeWit: PermissionLevel.Read }],
      title: 'menu_reviews_bureau_de_wit'
    },
    name: 'MyReviewsBureauDeWit',
    path: 'ReviewsBureauDeWit',
    beforeEnter() {
      const url = getConfigurationFromLocalStorage('reviewsBureauDeWit.URL');
      window.open(url, '_blank');
    }
  },
  {
    component: () => import('@/pages/masterdata/orderhistory/index.vue'),
    meta: {
      permissions: [{ MyOrderHistory: PermissionLevel.Read }],
      title: 'orderHistory'
    },
    name: 'OrderHistory',
    path: 'orderHistory'
  },
  {
    component: () => import('@/pages/masterdata/restaurants/myrestaurant/index.vue'),
    meta: {
      permissions: [{ OpeningHours: PermissionLevel.Read }],
      title: 'menu_opening_hours'
    },
    name: 'OpeningHours',
    path: 'openinghours'
  }
];
