import { PermissionLevel } from '@/utils/permission';

/**
 * Pages for restaurant configuration
 * Price categories, restaurants, postcodes
 */
export const restaurantRoutes = [
  {
    component: () => import('@/pages/masterdata/couponcodes/allrestaurants/index.vue'),
    meta: {
      permissions: [{ CouponCode: PermissionLevel.Read }],
      title: 'menu_coupon_codes'
    },
    name: 'CouponCodes',
    path: 'couponcodes'
  },
  {
    component: () => import('@/pages/masterdata/couponcodes/allrestaurants/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ CouponCode: PermissionLevel.Write }],
      title: 'createCouponCode'
    },
    name: 'CreateCouponCode',
    path: 'couponcodes/create'
  },
  {
    component: () => import('@/pages/masterdata/couponcodes/allrestaurants/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ CouponCode: PermissionLevel.Read }],
      title: 'editCouponCode'
    },
    name: 'EditCouponCode',
    path: 'couponcodes/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/customers/headoffice/index.vue'),
    meta: {
      permissions: [{ Customer: PermissionLevel.Read }],
      title: 'menu_customers'
    },
    name: 'Customers',
    path: 'customers'
  },
  {
    component: () => import('@/pages/masterdata/customers/headoffice/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ Customer: PermissionLevel.Write }],
      title: 'editCustomer'
    },
    name: 'EditCustomer',
    path: 'customer/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/pricecategories/index.vue'),
    meta: {
      permissions: [{ PriceCategory: PermissionLevel.Read }],
      title: 'menu_price_categories'
    },
    name: 'PriceCategories',
    path: 'price-categories'
  },
  {
    component: () => import('@/pages/masterdata/pricecategories/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ PriceCategory: PermissionLevel.Write }],
      title: 'createPriceCategory'
    },
    name: 'CreatePriceCategory',
    path: 'price-categories/create'
  },
  {
    component: () => import('@/pages/masterdata/pricecategories/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ PriceCategory: PermissionLevel.Read }],
      title: 'editPriceCategory'
    },
    name: 'EditPriceCategory',
    path: 'price-categories/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/partnerships/index.vue'),
    meta: {
      permissions: [{ Partnership: PermissionLevel.Read }],
      title: 'menu_partnerships'
    },
    name: 'Partnerships',
    path: 'partnerships'
  },
  {
    component: () => import('@/pages/masterdata/partnerships/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ Partnership: PermissionLevel.Write }],
      title: 'createPartnership'
    },
    name: 'CreatePartnership',
    path: 'partnerships/create'
  },
  {
    component: () => import('@/pages/masterdata/partnerships/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ Partnership: PermissionLevel.Write }],
      title: 'editPartnership'
    },
    name: 'EditPartnership',
    path: 'partnerships/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/restaurants/index.vue'),
    meta: {
      permissions: [{ Restaurant: PermissionLevel.Read }],
      title: 'menu_restaurants'
    },
    name: 'Restaurants',
    path: 'restaurants'
  },
  {
    component: () => import('@/pages/masterdata/restaurants/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ Restaurant: PermissionLevel.Write }],
      title: 'createRestaurant'
    },
    name: 'CreateRestaurant',
    path: 'restaurants/create'
  },
  {
    component: () => import('@/pages/masterdata/restaurants/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ Restaurant: PermissionLevel.Read }],
      title: 'editRestaurant'
    },
    name: 'EditRestaurant',
    path: 'restaurants/edit/:id(\\d+)'
  }
];
