import { parseSelector } from '@/utils';

const baseActions = {
  clearItems({ commit }) {
    commit('CLEAR_ITEMS');
  },
  deleteItem({ commit, dispatch, state }, item) {
    return dispatch('delete', item).then(() => {
      commit('DELETE_ITEM', item);
      commit('DELETE_PAGINATED_ITEM', item);
      commit('SET_TOTAL_ITEM_COUNT', state.totalItemsCount - 1);
    });
  },
  getItem({ commit, dispatch }, params) {
    commit('SET_LOADING', true);
    const dontSave = params.options && params.options.dontSave;
    const identifier = {};
    let fetchAppend = '';
    fetchAppend = parseSelector(params.selector, identifier);

    return dispatch('fetchOne' + fetchAppend, { ...identifier, params })
      .then(response => {
        const dto = response.data;
        return dispatch('transformDto', dto).then(item => {
          commit('UPSERT_LOOSE_ITEM', item);
          if (!dontSave) {
            commit('UPSERT_ITEM', item);
          }
          commit('SET_LOADING', false);
          return item;
        });
      })
      .catch(err => {
        commit('SET_LOADING', false);
        return Promise.reject(err);
      });
  },
  getItems(store, params) {
    const { commit, getters, rootGetters, dispatch } = store;
    const pageVersion = rootGetters['pageVersion'];
    commit('SET_LOADING', true);
    return dispatch('fetchList', params)
      .then(response => {
        const dontSave = params && params.options && params.options.dontSave;

        if (!dontSave) {
          if (getters['getVersion'] < pageVersion) {
            // Force clear if page has been changed since last load
            dispatch('clearItems');
          }
          commit('SET_TOTAL_ITEM_COUNT', response.data.total);
          commit('SET_VERSION', pageVersion);
        }
        const localStorageKey = params && params.options && params.options.localStorageKey;
        commit('SET_LOADING', false);
        const items = [];
        for (const dto of response.data.items) {
          dispatch('transformDto', dto).then(item => {
            commit('UPSERT_LOOSE_ITEM', item);
            if (!dontSave) {
              commit('UPSERT_ITEM', item);
            }
            items.push(item);
            if (localStorageKey) {
              localStorage.setItem(localStorageKey, JSON.stringify(items));
            }
          });
        }
        return { items, total: response.data.total };
      })
      .catch(err => {
        console.log(err);
        commit('SET_LOADING', false);
      });
  },
  getPaginatedItems({ commit, dispatch }, params) {
    commit('SET_LOADING', true);
    return dispatch('fetchList', params)
      .then(response => {
        const dontSave = params && params.options && params.options.dontSave;

        const items = [];
        for (const dto of response.data.items) {
          dispatch('transformDto', dto).then(item => {
            commit('UPSERT_LOOSE_ITEM', item);
            if (!dontSave) {
              commit('UPSERT_PAGINATED_ITEM', item);
            }
            items.push(item);
          });
        }

        if (!dontSave) {
          commit('SET_TOTAL_ITEM_COUNT', response.data.total);
        }
        commit('SET_LOADING', false);
        return { items, total: response.data.total };
      })
      .catch(() => {
        commit('SET_LOADING', false);
      });
  },
  getSimpleItem({ state, dispatch }, params) {
    const identifier = {};
    parseSelector(params.selector, identifier);
    if (identifier.id !== undefined) {
      if (state.backendEntityIDField !== 'id') {
        identifier[state.backendEntityIDField] = identifier.id;
        delete identifier.id;
      }
    }
    params.query = Object.assign({}, params.query, identifier);
    return dispatch('getItems', params).then(({ items, total }) => {
      if (total !== 1 || items.length !== 1) {
        throw new Error();
      }
      const item = items[0];
      return Promise.resolve(item);
    });
  },
  persistItem({ commit, dispatch, state }, item) {
    commit('SET_LOADING', true);
    if (item.id) {
      return dispatch('update', item)
        .then(response => {
          commit('UPSERT_LOOSE_ITEM', item);
          commit('UPSERT_ITEM', item);
          commit('SET_LOADING', false);
          const dto = response.data;
          return dispatch('transformDto', dto).then(item => {
            return item;
          });
        })
        .catch(error => {
          commit('SET_LOADING', false);
          return Promise.reject(error);
        });
    } else {
      return dispatch('create', item)
        .then(response => {
          const dto = response.data;
          return dispatch('transformDto', dto).then(item => {
            commit('UPSERT_LOOSE_ITEM', item);
            commit('UPSERT_ITEM', item);
            commit('SET_TOTAL_ITEM_COUNT', state.totalItemsCount + 1);
            commit('SET_LOADING', false);
            return item;
          });
        })
        .catch(error => {
          commit('SET_LOADING', false);
          return Promise.reject(error);
        });
    }
  },
  replaceItems({ commit, dispatch }, params) {
    commit('CLEAR_ITEMS');
    return dispatch('getItems', params);
  },
  replacePaginatedItems({ commit, dispatch }, params) {
    commit('CLEAR_PAGINATED_ITEMS');
    return dispatch('getPaginatedItems', params);
  }
};

export default baseActions;
