import { PermissionLevel } from '@/utils/permission';
import { getConfigurationFromLocalStorage } from '@/utils';

/**
 * Pages for robex
 */
export const robexRoutes = [
  {
    component: () => import('@/pages/robexwebshop/index.vue'),
    meta: {
      permissions: [{ OrderFromRobexWooCommerce: PermissionLevel.Write }],
      title: 'orderFromRobex'
    },
    name: 'OrderFromRobex',
    path: 'orderfromrobex'
  }
];
