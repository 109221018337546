import request from '@/utils/request';

function apiGetFirstPostcodeByTownName(params) {
  return request({
    method: 'get',
    url: 'location/GetFirstPostcodeByTownName',
    params: {
      ...params
    }
  });
}
function apiGetAddressByPostcode(params) {
  return request({
    method: 'get',
    url: 'location/GetAddressByPostcode',
    params: {
      ...params
    }
  });
}
function apiGetRestaurantByPostcode(params) {
  return request({
    method: 'get',
    url: 'location/GetRestaurantForDeliveryByPostcode',
    params: {
      ...params
    }
  });
}
function apiGetRestaurantForTown(params) {
  return request({
    method: 'get',
    url: 'location/GetRestaurantForDeliveryByTown',
    params: {
      ...params
    }
  });
}

export const storeExtensions = {
  actions: {
    getFirstPostcodeByTownName({ dispatch }, params) {
      return new Promise((resolve, reject) =>
        apiGetFirstPostcodeByTownName(params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    },
    getAddressByPostcode({ dispatch }, params) {
      return new Promise((resolve, reject) =>
        apiGetAddressByPostcode(params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    },
    getRestaurantByPostcode({ dispatch }, params) {
      return new Promise((resolve, reject) =>
        apiGetRestaurantByPostcode(params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    },
    getRestaurantForTown({ dispatch }, params) {
      return new Promise((resolve, reject) =>
        apiGetRestaurantForTown(params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    }
  }
};
