import request from '@/utils/request';

const transformItem2Dto = ({ backendEntityIDField }) => item => {
  const dto = {
    ...item
  };
  if (backendEntityIDField !== 'id') {
    dto[backendEntityIDField] = dto.id;
    delete dto.id;
  }
  return dto;
};

const transformDto2Item = ({ backendEntityIDField }) => dto => {
  const item = {
    ...dto
  };
  if (backendEntityIDField !== 'id') {
    item['id'] = dto[backendEntityIDField];
    delete item[backendEntityIDField];
  }
  return item;
};

const apiFetchList = ({ requestUrl }) => params => {
  return request({
    method: 'get',
    params: params && params.query,
    url: requestUrl
  });
};

const apiFetchOne = ({ requestUrl }) => (id, params) => {
  return request({
    method: 'get',
    params: params && params.query,
    url: requestUrl + '/' + id.toString()
  });
};

const apiCreate = entity => transformItemToDto => item => {
  return request({
    data: transformItemToDto(item),
    method: 'post',
    url: entity.requestUrl
  });
};

const apiUpdate = ({ requestUrl }) => transformItemToDto => item => {
  return request({
    data: transformItemToDto(item),
    method: 'put',
    url: requestUrl
  });
};

const apiDelete = ({ requestUrl }) => item => {
  return request({
    method: 'delete',
    params: {},
    url: requestUrl + '/' + item.id.toString()
  });
};

export default { transformItem2Dto, transformDto2Item, apiFetchList, apiFetchOne, apiCreate, apiUpdate, apiDelete };
