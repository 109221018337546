import request from '@/utils/request';
// import { toISOString } from '@/utils/date';
import { toDateTimeString } from '../../../utils/date';

function transformItem2Dto(item) {
  const dto = {
    worklogID: item.id,
    ...item,
    workedAt: toDateTimeString(item.workedAt)
  };
  delete dto.id;
  return dto;
}

function getEmployeeWorklog(params) {
  return request({
    method: 'get',
    url: 'employee-worklog/getEmployeeWorklog',
    params: {
      ...params
    }
  });
}

function getWorkedHoursReport(params) {
  return request({
    method: 'get',
    url: 'employee-worklog-report',
    params: {
      ...params
    }
  });
}

function downloadWorkedHoursReport(params) {
  return request({
    method: 'get',
    url: 'employee-worklog-report-download',
    params: {
      ...params
    },
    responseType: 'blob',
    timeout: 120000
  });
}

function saveWorklog(command) {
  return request({
    method: 'post',
    url: 'employee-worklog/saveWorklog',
    data: command
  });
}

function changePaymentMethod(command) {
  return request({
    method: 'post',
    url: 'employee-worklog/changePaymentMethod',
    data: command
  });
}

export const storeExtensions = {
  actions: {
    getEmployeeWorklog({ dispatch }, params) {
      return new Promise((resolve, reject) =>
        getEmployeeWorklog(params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    },
    getWorkedHoursReport({ dispatch }, params) {
      return new Promise((resolve, reject) =>
        getWorkedHoursReport(params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    },
    downloadWorkedHoursReport({ dispatch }, params) {
      return new Promise((resolve, reject) =>
        downloadWorkedHoursReport(params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    },
    changePaymentMethod({ commit, dispatch }, changePaymentMethodCommand) {
      return new Promise((resolve, reject) =>
        changePaymentMethod(changePaymentMethodCommand)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    },
    saveWorklog({ commit, dispatch }, saveWorklogCommand) {
      return new Promise((resolve, reject) =>
        saveWorklog(saveWorklogCommand)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    }
  }
};

export const apiOverrides = { transformItem2Dto };
