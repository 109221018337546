import Cookies from 'js-cookie';

export default {
  actions: {
    closeSideBar({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation);
    },
    setDateFormat({ commit }, format) {
      commit('SET_DATE_FORMAT', format);
    },
    setDateTimeFormat({ commit }, format) {
      commit('SET_DATE_TIME_FORMAT', format);
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language);
    },
    setSize({ commit }, size) {
      commit('SET_SIZE', size);
    },
    setTimeFormat({ commit }, format) {
      commit('SET_TIME_FORMAT', format);
    },
    toggleDevice({ commit }, device) {
      commit('TOGGLE_DEVICE', device);
    },
    toggleSideBar({ commit }) {
      commit('TOGGLE_SIDEBAR');
    },
    increasePageVersion({ commit }) {
      commit('INCREASE_PAGE_VERSION');
    },
    newVersionAvailable({ commit }) {
      commit('SET_NEW_VERSION_AVAILABLE', true);
    }
  },
  mutations: {
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 0);
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    SET_DATE_FORMAT: (state, format) => {
      state.dateFormat = format;
    },
    SET_DATE_TIME_FORMAT: (state, format) => {
      state.dateTimeFormat = format;
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      Cookies.set('language', language);
    },
    SET_SIZE: (state, size) => {
      state.size = size;
      Cookies.set('size', size);
    },
    SET_TIME_FORMAT: (state, format) => {
      state.timeFormat = format;
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device;
    },
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1);
      } else {
        Cookies.set('sidebarStatus', 0);
      }
    },
    INCREASE_PAGE_VERSION: state => {
      state.pageVersion++;
    },
    SET_NEW_VERSION_AVAILABLE: (state, newVersionAvailable) => {
      state.newVersionAvailable = newVersionAvailable;
    }
  },
  state: {
    dateFormat: 'dd-MM-yyyy',
    dateTimeFormat: 'dd-MM-yyyy HH:mm',
    device: 'desktop',
    editorOptions: { btnSaveAndNext: true, btnDelete: true },
    language: Cookies.get('language') || 'nl',
    sidebar: {
      opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
      withoutAnimation: false
    },
    size: Cookies.get('size') || 'medium',
    timeFormat: 'HH:mm',
    multilingual: true,
    pageVersion: 1,
    validationErrorInputPrefix: 'error.input.',
    newVersionAvailable: false,
    minUpdateCheckInterval: 5 * 1000,
    maxUpdateCheckInterval: 24 * 60 * 60 * 1000
  }
};
