import { toDateTimeString } from '@/utils/date';
import request from '@/utils/request';

function transformItem2Dto(item) {
  return {
    ...item,
    scratchAndWinActionID: item.id,
    startDate: toDateTimeString(item.startDate),
    endDate: toDateTimeString(item.endDate),
    exportedAt: toDateTimeString(item.exportedAt)
  };
}

function getWinCodesCsv(id) {
  return request({
    method: 'get',
    url: 'scratch-and-win-action/generate-csv',
    params: {
      id: id
    },
    responseType: 'blob',
    timeout: 120000
  });
}

export const storeExtensions = {
  actions: {
    getWinCodesCsv({ dispatch }, id) {
      return new Promise((resolve, reject) =>
        getWinCodesCsv(id)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    }
  }
};

export const apiOverrides = { transformItem2Dto };
