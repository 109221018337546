import request from '@/utils/request';

export function printTestPage(printCommand) {
  const url = `printer/printtestpage`;
  return request({
    data: printCommand,
    method: 'post',
    url: url
  });
}

export const storeExtensions = {
  actions: {
    printTestPage({ commit, dispatch }, printCommand) {
      return new Promise((resolve, reject) =>
        printTestPage(printCommand)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    }
  }
};
