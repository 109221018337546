import Cookies from 'js-cookie';

const TokenKey = 'Admin-Token';
const UserTypeKey = 'UserType';
const UserLanguageKey = 'UserLanguage';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getUserType() {
  return Cookies.get(UserTypeKey);
}

export function setUserType(userType) {
  return Cookies.set(UserTypeKey, userType);
}

export function removeUserType() {
  return Cookies.remove(UserTypeKey);
}

export function getUserLanguage() {
  return Cookies.get(UserLanguageKey);
}

export function setUserLanguage(language) {
  return Cookies.set(UserLanguageKey, language);
}

export function removeUserLanguage() {
  return Cookies.remove(UserLanguageKey);
}
