import { PermissionLevel } from '@/utils/permission';

export const dishesRoutes = [
  {
    component: () => import('@/pages/masterdata/productcategories/index.vue'),
    meta: {
      permissions: [{ ProductCategory: PermissionLevel.Read }],
      title: 'menu_product_categories'
    },
    name: 'ProductCategories',
    path: 'productcategories'
  },
  {
    component: () => import('@/pages/masterdata/productcategories/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ ProductCategory: PermissionLevel.Write }],
      title: 'createProductCategory'
    },
    name: 'CreateProductCategory',
    path: 'productcategories/create'
  },
  {
    component: () => import('@/pages/masterdata/productcategories/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ ProductCategory: PermissionLevel.Read }],
      title: 'editProductCategory'
    },
    name: 'EditProductCategory',
    path: 'productcategories/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/products/index.vue'),
    meta: {
      permissions: [{ Product: PermissionLevel.Read }],
      title: 'menu_products'
    },
    name: 'Products',
    path: 'products'
  },
  {
    component: () => import('@/pages/masterdata/products/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ Product: PermissionLevel.Write }],
      title: 'createProduct'
    },
    name: 'CreateProduct',
    path: 'products/create'
  },
  {
    component: () => import('@/pages/masterdata/products/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ Product: PermissionLevel.Read }],
      title: 'editProduct'
    },
    name: 'EditProduct',
    path: 'products/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/localproductcategories/index.vue'),
    meta: {
      permissions: [{ LocalProductCategory: PermissionLevel.Read }],
      title: 'menu_local_product_categories'
    },
    name: 'LocalProductCategories',
    path: 'local_product_categories'
  },
  {
    component: () => import('@/pages/masterdata/localproductcategories/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ LocalProductCategory: PermissionLevel.Write }],
      title: 'createLocalProductCategory'
    },
    name: 'CreateLocalProductCategory',
    path: 'local_product_categories/create'
  },
  {
    component: () => import('@/pages/masterdata/localproductcategories/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ LocalProductCategory: PermissionLevel.Write }],
      title: 'editLocalProductCategory'
    },
    name: 'EditLocalProductCategory',
    path: 'local_product_categories/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/localproducts/index.vue'),
    meta: {
      permissions: [{ LocalProduct: PermissionLevel.Read }],
      title: 'menu_local_products'
    },
    name: 'LocalProducts',
    path: 'local_products'
  },
  {
    component: () => import('@/pages/masterdata/localproducts/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ LocalProduct: PermissionLevel.Write }],
      title: 'createLocalProduct'
    },
    name: 'CreateLocalProduct',
    path: 'local_product_categories/create'
  },
  {
    component: () => import('@/pages/masterdata/localproducts/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ LocalProduct: PermissionLevel.Write }],
      title: 'editLocalProduct'
    },
    name: 'EditLocalProduct',
    path: 'local_product/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/menucategories/index.vue'),
    meta: {
      permissions: [{ MenuCategory: PermissionLevel.Read }],
      title: 'menu_menu_categories'
    },
    name: 'MenuCategories',
    path: 'menucategories'
  },
  {
    component: () => import('@/pages/masterdata/menucategories/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ MenuCategory: PermissionLevel.Write }],
      title: 'createMenuCategory'
    },
    name: 'CreateMenuCategory',
    path: 'menucategories/create'
  },
  {
    component: () => import('@/pages/masterdata/menucategories/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ MenuCategory: PermissionLevel.Read }],
      title: 'editMenuCategory'
    },
    name: 'EditMenuCategory',
    path: 'menucategories/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/menus/index.vue'),
    meta: {
      permissions: [{ Menu: PermissionLevel.Read }],
      title: 'menu_menu'
    },
    name: 'Menus',
    path: 'menus'
  },
  {
    component: () => import('@/pages/masterdata/menus/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ Menu: PermissionLevel.Write }],
      title: 'createMenu'
    },
    name: 'CreateMenu',
    path: 'menu/create'
  },
  {
    component: () => import('@/pages/masterdata/menus/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ Menu: PermissionLevel.Read }],
      title: 'editMenu'
    },
    name: 'EditMenu',
    path: 'menus/edit/:id(\\d+)'
  }
];
