import request from '@/utils/request';

function getRevenueReport(params) {
  return request({
    method: 'get',
    url: 'revenue-report',
    params: {
      ...params
    },
    timeout: 120000
  });
}

function getRevenueReportRealtime() {
  return request({
    method: 'get',
    url: 'revenue-report/realtime',
    timeout: 120000
  });
}

function getDeliveryReport(params) {
  return request({
    method: 'get',
    url: 'delivery-report',
    params: {
      ...params
    },
    timeout: 120000
  });
}

function downloadRevenueReport(params, data) {
  return request({
    method: 'post',
    url: 'revenue-report/download',
    params: {
      ...params
    },
    data: data,
    responseType: 'blob',
    timeout: 120000
  });
}

export const storeExtensions = {
  actions: {
    getRevenueReport({ dispatch }, params) {
      return new Promise((resolve, reject) =>
        getRevenueReport(params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    },
    getRevenueReportRealtime({ dispatch }, params) {
      return new Promise((resolve, reject) =>
        getRevenueReportRealtime()
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    },
    getDeliveryReport({ dispatch }, params) {
      return new Promise((resolve, reject) =>
        getDeliveryReport(params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          })
      );
    },
    downloadRevenueReport({ dispatch }, command) {
      return new Promise((resolve, reject) =>
        downloadRevenueReport(command.params, command.data)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          })
      );
    }
  }
};
