<template>
  <action-dialog
    :type="type"
    :callback="callback"
    :title="title"
    :message="message"
    :cancel-button-text="cancelButtonText"
    :confirm-button-text="confirmButtonText"
    :dangerously-use-h-t-m-l-string="dangerouslyUseHTMLString"
    :width="width"
    :close-on-click-modal="closeOnClickModal"
    :show-cancel="showCancel"
    :show-close="showClose"
    :close-on-press-escape="closeOnPressEscape"
  />
</template>
<script>
import i18n from '@/i18n';
import ActionDialog from '@/components/ActionDialog';
export default {
  components: {
    ActionDialog
  },
  data() {
    return {
      callback: null,
      type: undefined,
      title: undefined,
      message: undefined,
      cancelButtonText: undefined,
      confirmButtonText: undefined,
      dangerouslyUseHTMLString: false,
      width: undefined,
      closeOnClickModal: undefined,
      closeOnPressEscape: undefined,
      showCancel: undefined,
      showClose: undefined,
      i18n
    };
  }
};
</script>
