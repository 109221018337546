import { toDateTimeString } from '@/utils/date';

function transformItem2Dto(item) {
  const dto = {
    ...item,
    configurationID: item.id,
    fromDate: toDateTimeString(item.fromDate)
  };
  delete dto.id;
  return dto;
}

export const apiOverrides = { transformItem2Dto };

export const storeExtensions = {
  getters: {
    value: state => code => {
      const config = state.items.find(config => config.code === code);
      return config && config.value;
    }
  }
};
