function transformItem2Dto(item) {
  const dto = {
    ...item,
    userID: item.id
  };
  delete dto.id;
  delete dto.tokenIssuedAt;
  delete dto.tokenValidUntil;
  delete dto.jwtToken;
  return dto;
}

export const apiOverrides = { transformItem2Dto };
