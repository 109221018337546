import { PermissionLevel } from '@/utils/permission';

/**
 * Pages for statistics
 */
export const statisticsRoutes = [
  {
    component: () => import('@/pages/statistics/revenue_comparison.vue'),
    meta: {
      permissions: [{ StatisticsRevenueComparison: PermissionLevel.Read }],
      title: 'menu_revenue_comparison'
    },
    name: 'StatisticsRevenueComparison',
    path: 'revenue-comparison',
    alwaysShow: true
  },
  {
    component: () => import('@/pages/statistics/revenue_development.vue'),
    meta: {
      permissions: [{ StatisticsRevenueDevelopment: PermissionLevel.Read }],
      title: 'menu_revenue_development'
    },
    name: 'StatisticsRevenueDevelopment',
    path: 'revenue-development',
    alwaysShow: true
  },
  {
    component: () => import('@/pages/statistics/customer_satisfaction.vue'),
    meta: {
      permissions: [{ StatisticsCustomerSatisfaction: PermissionLevel.Read }],
      title: 'menu_statistics_customer_satisfaction'
    },
    name: 'StatisticsCustomerSatisfaction',
    path: 'customer-satisfaction',
    alwaysShow: true
  },
  {
    component: () => import('@/pages/statistics/postcode_analytics.vue'),
    meta: {
      permissions: [{ StatisticsPostcodeAnalytics: PermissionLevel.Read }],
      title: 'menu_statistics_postcode_analytics'
    },
    name: 'StatisticsPostcodeAnalytics',
    path: 'postcode-analytics',
    alwaysShow: true
  },
  {
    component: () => import('@/pages/statistics/bureau_de_wit.vue'),
    meta: {
      permissions: [{ StatisticsBureauDeWit: PermissionLevel.Read }],
      title: 'menu_statistics_bureau_de_wit'
    },
    name: 'StatisticsBureauDeWit',
    path: 'bureau-de-wit',
    alwaysShow: true
  },
  {
    component: () => import('@/pages/statistics/scorecard.vue'),
    meta: {
      permissions: [{ StatisticsScorecard: PermissionLevel.Read }],
      title: 'menu_scorecard'
    },
    name: 'StatisticsScorecard',
    path: 'scorecard',
    alwaysShow: true
  },
  {
    component: () => import('@/pages/statistics/media_dashboard.vue'),
    meta: {
      permissions: [{ StatisticsMediaDashboard: PermissionLevel.Read }],
      title: 'menu_statistics_media_dashboard'
    },
    name: 'StatisticsMediaDashboard',
    path: 'media-dashboard',
    alwaysShow: true
  }
];
