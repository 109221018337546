import { RouteConfig } from 'vue-router';
import { dishesRoutes } from '@/router/dishesRoutes';
import { applicationRoutes } from '@/router/applicationRoutes';
import { websiteRoutes } from '@/router/websiteRoutes';
import { restaurantRoutes } from '@/router/restaurantRoutes';
import { myRestaurantRoutes } from '@/router/myRestaurantRoutes';
import { reportRoutes } from '@/router/reportRoutes';
import { usersRoutes } from '@/router/usersRoutes';
import { statisticsRoutes } from '@/router/statisticsRoutes';
import { robexRoutes } from './robexRoutes';
import { requestFormRoutes } from './requestFormRoutes';

const LazyLayout = () => import('@/layout/index.vue');

export default [
  {
    children: [
      {
        component: () => import('@/pages/user/settings.vue'),
        meta: { noCache: true, title: 'userSettings' },
        name: 'Settings',
        path: 'settings'
      }
    ],
    component: LazyLayout,
    hidden: true,
    path: '/user',
    redirect: 'settings'
  } as RouteConfig,
  {
    path: '/application',
    component: LazyLayout,
    name: 'Application',
    meta: { title: 'menu_admin_application', icon: 'star' },
    children: [...applicationRoutes]
  } as RouteConfig,
  {
    path: '/users',
    component: LazyLayout,
    name: 'AdminUserManagement',
    meta: { title: 'menu_admin_users', icon: 'user' },
    children: [...usersRoutes]
  } as RouteConfig,
  {
    path: '/restaurants',
    component: LazyLayout,
    name: 'RestaurantsMainMenu',
    meta: { title: 'menu_restaurants', icon: 'shopping' },
    children: [...restaurantRoutes]
  } as RouteConfig,
  {
    path: '/my-restaurant',
    component: LazyLayout,
    name: 'MyRestaurantMainMenu',
    meta: { title: 'menu_my_restaurant', icon: 'shopping' },
    children: [...myRestaurantRoutes]
  } as RouteConfig,
  {
    path: '/reports',
    component: LazyLayout,
    name: 'ReportsMainMenu',
    meta: { title: 'menu_reports', icon: 'table' },
    children: [...reportRoutes]
  } as RouteConfig,
  {
    path: '/statistics',
    component: LazyLayout,
    name: 'StatisticsMainMenu',
    meta: { title: 'menu_statistics', icon: 'chart' },
    alwaysShow: true,
    children: [...statisticsRoutes]
  } as RouteConfig,
  {
    path: '/robex',
    component: LazyLayout,
    name: 'orderFromRobex',
    meta: { title: 'orderFromRobex', icon: 'link' },
    children: [...robexRoutes]
  } as RouteConfig,
  {
    path: '/requests',
    component: LazyLayout,
    name: 'requestForm',
    meta: { title: 'menu_requestForm', icon: 'link' },
    children: [...requestFormRoutes]
  } as RouteConfig,
  {
    path: '/dishes',
    component: LazyLayout,
    name: 'Dishes',
    meta: { title: 'menu_dishes', icon: 'food' },
    children: [...dishesRoutes]
  } as RouteConfig,
  {
    path: '/website',
    component: LazyLayout,
    name: 'Website',
    meta: { title: 'menu_website', icon: 'international' },
    children: [...websiteRoutes]
  } as RouteConfig
];
