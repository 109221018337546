'use strict';
const dayjs = require('dayjs');

export const WORKING_DAYS = [1, 2, 3, 4, 5]; // [0 - 6] starting at 0=Sunday, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay

export function getDurationInDays(startDate, endDate) {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  if (start.isAfter(end)) {
    return 0;
  }
  const diff = end.diff(start, 'days') + 1;
  return diff;
}

function parseDateToUTC(date) {
  // Transform date to UTC and strip time
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}
export const getWorkingDays = (d0, d1) => {
  const holidays = [];
  const startDate = parseDateToUTC(d0);
  const endDate = parseDateToUTC(d1);

  // Validate input
  if (endDate < startDate) {
    return 0;
  }

  // Calculate days between dates
  const millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1); // Start just after midnight
  endDate.setHours(23, 59, 59, 999); // End just before midnight
  const diff = endDate - startDate; // Milliseconds between datetime objects
  let days = Math.round(diff / millisecondsPerDay);

  // Subtract two weekend days for every week in between
  const weeks = Math.floor(days / 7);
  days -= weeks * 2;

  // Handle special cases
  const startDay = startDate.getDay();
  const endDay = endDate.getDay();

  // Remove weekend not previously removed.
  if (startDay - endDay > 1) {
    days -= 2;
  }
  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay === 0 && endDay !== 6) {
    days--;
  }
  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay === 6 && startDay !== 0) {
    days--;
  }
  // Account for holidays
  holidays.forEach(day => {
    if (day >= d0 && day <= d1) {
      /* If it is not saturday (6) or sunday (0), substract it */
      if (parseDateToUTC(day).getDay() % 6 !== 0) {
        days--;
      }
    }
  });
  return days;
};
export function addDays(date, days) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
}

export function nextWeekOf(date) {
  return addDays(date, 7);
}

export function isWorkingDay(date) {
  const weekDay = date.getDay();
  return WORKING_DAYS.includes(weekDay);
}

export function toISOString(date) {
  return date && new Date(date).toISOString();
}

export function toString(date, format) {
  return date && dayjs(date).format(format);
}

export function toDateTimeString(date) {
  return date && dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
}

export function toDateTimeStringVisual(date) {
  return date && dayjs(date).format('DD-MM-YYYY HH:mm:ss');
}
export function toDateStringVisual(date) {
  return date && dayjs(date).format('DD-MM-YYYY');
}
export function toDateString(date) {
  return date && new Date(date).toDateString();
}

export function getDayJSFormatFromElementFormat(format) {
  return format.replace(/(dd)/g, 'DD').replace(/(yyyy)/g, 'YYYY');
}
export function formatDateByElementFormat(date, format) {
  const dayJsFormat = getDayJSFormatFromElementFormat(format);
  return dayjs(date).format(dayJsFormat);
}
export function isDateOlderThan(dateA, dateB) {
  return Date.parse(dateA) < Date.parse(dateB);
}
export function compareDates(dateA, dateB) {
  const valueA = typeof dateA === 'string' ? Date.parse(dateA).valueOf() : dateA;
  const valueB = typeof dateB === 'string' ? Date.parse(dateB).valueOf() : dateB;

  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  return 0;
}
