import {
  apiChangePassword,
  apiChangePasswordWithToken,
  apiForgotPassword,
  apiGetPasswordPolicy,
  apiImpersonateUser,
  apiLoginByUsername,
  apiLogout,
  apiValidateNewPasswordToken
} from '@/api/authentication';
import {
  getToken,
  getUserLanguage,
  getUserType,
  removeToken,
  removeUserType,
  setToken,
  setUserLanguage,
  setUserType
} from '@/utils/auth';
import jwt_decode from 'jwt-decode';
import { removeUserLanguage } from '../../utils/auth';

const token = getToken();
const userType = getUserType();
const preferredLanguage = getUserLanguage();
let userId = null;
let restaurantId = null;

const UserTypes = {
  StandardUser: 'SRE.Data.Models.StandardUser',
  RestaurantUser: 'SRE.Data.Models.RestaurantUser',
  KassaUser: 'SRE.Data.Models.KassaUser'
};
const isRestaurantUser = userType === UserTypes.RestaurantUser;
const isKassaUser = userType === UserTypes.KassaUser;
let isStandardUser = userType === UserTypes.StandardUser;
if (!isRestaurantUser && !isKassaUser && !isStandardUser) {
  isStandardUser = true;
}
if (token) {
  const decodedJwtToken = jwt_decode(token);
  userId = parseInt(decodedJwtToken.sub);
  restaurantId = parseInt(decodedJwtToken.restaurantID);
}
export default {
  actions: {
    changePasswordOfLoggedInUser({ commit, state }, passwordChangeInfo) {
      return new Promise((resolve, reject) => {
        apiChangePassword(state.userId, passwordChangeInfo.oldPassword, passwordChangeInfo.newPassword)
          .then(() => {
            commit('SET_PASSWORD_CHANGED', true);
            resolve();
          })
          .catch(error => {
            commit('SET_PASSWORD_CHANGED', false);
            reject(error);
          });
      });
    },

    frontEndLogout({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        removeToken();
        removeUserType();
        resolve();
      });
    },

    getPasswordPolicy({ commit, state }) {
      return new Promise((resolve, reject) => {
        apiGetPasswordPolicy()
          .then(response => {
            //
            // Since mockjs does not support custom status codes, it can only be hacked like this
            if (!response.data) {
              reject(new Error('Failed to load password policy.'));
            }
            const data = {
              ...response.data,
              isFromDatabase: true
            };
            commit('SET_PASSWORD_POLICY', data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    getUserInfo({ commit, state, dispatch }, params) {
      return new Promise((resolve, reject) => {
        if (!params) {
          params = [];
        }

        if (state.isStandardUser) {
          const withPermissions = params.withPermissions || true;
          dispatch('standardUser/fetchUser', { id: state.userId, withPermissions: withPermissions }, { root: true })
            .then(response => {
              if (!response.data) {
                reject(new Error('Verification failed, please login again.'));
              }
              const data = response.data;
              if (withPermissions && data.roles && data.roles.length > 0) {
                // Verify that the returned roles are a non-null array
                commit('SET_ROLES', data.roles);
              } else {
                reject(new Error('getInfo: roles must be a non-null array!'));
              }

              commit('SET_USER_PERMISSIONS', data.permissions);
              commit('SET_NAME', data.name);
              commit('SET_CODE', data.code);
              commit('SET_EMAIL', data.email);
              commit('SET_DESCRIPTION', data.description);
              commit('SET_AVATAR', data.avatar);
              commit('SET_DOMAIN_ID', data.domainID);
              commit('SET_ROBEX_WEBSHOP_URL', '');
              commit('SET_CASH_DESK_DOMAIN', '');
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          if (state.isRestaurantUser) {
            dispatch('restaurantUser/fetchRestaurantUser', state.userId, { root: true })
              .then(response => {
                if (!response.data) {
                  reject(new Error('Verification failed, please login again.'));
                }
                const data = response.data;
                commit('SET_USER_PERMISSIONS', data.permissions);
                commit('SET_NAME', data.name);
                commit('SET_CODE', data.code);
                commit('SET_EMAIL', data.email);
                commit('SET_DESCRIPTION', data.description);
                commit('SET_AVATAR', data.avatar);
                commit('SET_ROBEX_WEBSHOP_URL', data.robexWebshopURL);
                commit('SET_CASH_DESK_DOMAIN', data.cashDeskDomain);
                resolve(response);
              })
              .catch(error => {
                reject(error);
              });
          } else {
            if (state.isKassaUser) {
              dispatch('kassaUser/fetchKassaUser', state.userId, { root: true })
                .then(response => {
                  if (!response.data) {
                    reject(new Error('Verification failed, please login again.'));
                  }
                  const data = response.data;
                  commit('SET_USER_PERMISSIONS', data.permissions);
                  commit('SET_NAME', data.name);
                  commit('SET_CODE', data.code);
                  commit('SET_EMAIL', data.email);
                  commit('SET_DESCRIPTION', data.description);
                  commit('SET_AVATAR', data.avatar);
                  resolve(response);
                })
                .catch(error => {
                  reject(error);
                });
            }
          }
        }
      });
    },

    forgotPassword({ commit }, userInfo) {
      return apiForgotPassword(userInfo.username, userInfo.email);
    },

    validateTokenForNewPassword({ commit }, token) {
      return apiValidateNewPasswordToken(token);
    },

    changePasswordWithToken({ commit }, tokenInfo) {
      return apiChangePasswordWithToken(tokenInfo.password, tokenInfo.token);
    },

    logOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        apiLogout(state.token)
          .then(() => {
            commit('SET_TOKEN', '');
            commit('SET_ROLES', []);
            removeToken();
            removeUserType();
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    loginByUsername({ commit }, userInfo) {
      return new Promise((resolve, reject) =>
        apiLoginByUsername(userInfo.code, userInfo.password)
          .then(response => {
            console.log(response.data);
            const payload = response.data.token.split('.')[1];
            const jwtPayload = JSON.parse(atob(payload));
            const userId = parseInt(jwtPayload.sub);
            const restaurantID = parseInt(jwtPayload.restaurantID);
            if (response.data.standardUserYN) {
              const domainIDs = jwtPayload.domainIDs.split(',').map(c => parseInt(c));
              commit('SET_DOMAIN_ID', domainIDs[0]);
            }
            commit('SET_TOKEN', response.data.token);
            commit('SET_USER_TYPE', response.data.userType);
            commit('SET_USER_LANGUAGE', response.data.preferredLanguageCode);
            commit('SET_USER_ID', userId);
            commit('SET_RESTAURANT_ID', restaurantID);
            setToken(response.data.token);
            setUserType(response.data.userType);
            resolve();
          })
          .catch(error => {
            reject(error);
          })
      );
    },

    setPreferredLanguageOfCurrentUser({ commit }, language) {
      commit('SET_USER_LANGUAGE', language);
    },

    impersonateUser({ commit }, userInfo) {
      return new Promise((resolve, reject) =>
        apiImpersonateUser(userInfo.targetUserID)
          .then(response => {
            const payload = response.data.token.split('.')[1];
            const jwtPayload = JSON.parse(atob(payload));
            const userId = parseInt(jwtPayload.sub);
            const restaurantID = parseInt(jwtPayload.restaurantID);
            if (response.data.standardUserYN) {
              const domainIDs = jwtPayload.domainIDs.split(',').map(c => parseInt(c));
              commit('SET_DOMAIN_ID', domainIDs[0]);
            }
            commit('SET_TOKEN', response.data.token);
            commit('SET_USER_TYPE', response.data.userType);
            commit('SET_USER_LANGUAGE', response.data.preferredLanguageCode);
            commit('SET_USER_ID', userId);
            commit('SET_RESTAURANT_ID', restaurantID);
            setToken(response.data.token);
            setUserType(response.data.userType);
            resolve();
          })
          .catch(error => {
            reject(error);
          })
      );
    },

    persistUser({ dispatch }, userInfo) {
      return new Promise((resolve, reject) => {
        dispatch('standardUser/updateSelf', userInfo, { root: true })
          .then(() => {})
          .catch(error => {
            reject(error);
          });
      });
    },

    updateLoggedInUser({ commit, state, dispatch }, userInfo) {
      return new Promise((resolve, reject) => {
        const user = {
          ...userInfo,
          id: state.userId,
          domainID: state.domainID
        };
        dispatch('standardUser/updateSelf', user, { root: true })
          .then(() => {
            commit('SET_NAME', userInfo.name);
            commit('SET_CODE', userInfo.code);
            commit('SET_EMAIL', userInfo.email);
            commit('SET_DESCRIPTION', userInfo.description);
            commit('SET_AVATAR', userInfo.avatar);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_CODE: (state, code) => {
      state.code = code;
    },
    SET_RETAILERS: (state, retailers) => {
      state.retailers = retailers;
    },
    SET_DESCRIPTION: (state, description) => {
      state.description = description;
    },
    SET_DOMAIN_ID: (state, domainID) => {
      state.domainID = domainID;
    },
    SET_EMAIL: (state, email) => {
      state.email = email;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_PASSWORD_CHANGED: (state, passwordChanged) => {
      state.passwordChanged = passwordChanged;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER_TYPE: (state, userType) => {
      state.userType = userType;
      state.isStandardUser = userType === UserTypes.StandardUser;
      state.isRestaurantUser = userType === UserTypes.RestaurantUser;
      state.isKassaUser = userType === UserTypes.KassaUser;
    },
    SET_USER_LANGUAGE: (state, languageCode) => {
      state.preferredLanguage = languageCode;
      if (languageCode) {
        setUserLanguage(languageCode);
      } else {
        removeUserLanguage();
      }
    },
    SET_PASSWORD_POLICY: (state, passwordPolicy) => {
      state.passwordPolicy = passwordPolicy;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting;
    },
    SET_STATUS: (state, status) => {
      state.status = status;
    },
    SET_USER_ID: (state, id) => {
      state.userId = id;
    },
    SET_RESTAURANT_ID: (state, id) => {
      state.restaurantId = id;
    },
    SET_USER_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_ROBEX_WEBSHOP_URL: (state, robexWebshopURL) => {
      state.robexWebshopURL = robexWebshopURL;
      localStorage.robexWebshopURL = robexWebshopURL;
    },
    SET_CASH_DESK_DOMAIN: (state, cashDeskDomain) => {
      state.cashDeskDomain = cashDeskDomain;
      if (cashDeskDomain) {
        localStorage.setItem('cashDeskUrl', 'https://' + cashDeskDomain);
      } else {
        localStorage.removeItem('cashDeskUrl');
      }
    }
  },

  state: {
    avatar: '',
    robexWebshopURL: '',
    cashDeskDomain: '',
    code: '',
    description: '',
    domainID: '',
    email: '',
    name: '',
    passwordChanged: false,
    passwordPolicy: {
      minimumLength: 0,
      requireDigit: false,
      requireLowercase: false,
      isFromDatabase: false,
      requireUppercase: false,
      requireNonAlphanumeric: false
    },
    permissions: [],
    roles: [],
    setting: {
      articlePlatform: []
    },
    status: '',
    user: '',
    userId,
    token,
    userType,
    isStandardUser,
    isRestaurantUser,
    isKassaUser,
    preferredLanguage,
    restaurantId: restaurantId
  }
};
