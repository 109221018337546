<template>
  <input
    v-if="!readonly"
    ref="numeric"
    v-model="inputModel"
    :readonly="readonly"
    type="tel"
    :disabled="disabled"
    :autoComplete="autoComplete"
    :required="required"
    :placeholder="placeholder"
    :name="name"
    :class="classNames"
    @focus="onFocusHandler"
    @blur="onBlurHandler"
  />
  <span v-else>{{ value === null ? placeholder : inputModel }} </span>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100000
    },
    precision: {
      type: Number,
      default: 2
    },
    separator: {
      type: String,
      default: '.'
    },
    disabled: Boolean,
    placeholder: String,
    readonly: Boolean,
    required: {
      type: Boolean,
      default: false
    },
    autoComplete: {
      type: String,
      default: 'off'
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    name: String,
    value: [Number, String],
    decimalSeparator: {
      type: String,
      default: ',',
      validator: function(value) {
        return [',', '.'].indexOf(value) !== -1;
      }
    },
    strictDecimalSeparator: {
      type: Boolean,
      default: false
    },
    showThousandSeparator: {
      type: Boolean,
      default: true
    },
    invalid: Boolean // Highlight input with error color
  },
  data() {
    return {
      currentValue: this.value,
      inputModel: ''
    };
  },
  computed: {
    thousandSeparator() {
      if (this.decimalSeparator === ',') {
        return '.';
      }
      return ',';
    },
    classNames() {
      let computedClasses = '';
      if (this.invalid) {
        computedClasses += ' is-invalid';
      }
      return computedClasses;
    }
  },
  watch: {
    value(value) {
      this.currentValue = value;
      this.updateInputModelByValue(value);
    },
    inputModel(inputText, oldValue) {
      if (!this.hasFocus) {
        return;
      }
      let newValueStringCandidate = inputText;
      if (!this.strictDecimalSeparator) {
        newValueStringCandidate = inputText.replace(',', '.');
      }
      const regex = new RegExp('^-?[0-9]*([.][0-9]{0,' + this.precision + '})?$', 'i');
      const validatePrice = number => regex.test(number);
      const formattedValue = this.format(newValueStringCandidate);
      if (
        newValueStringCandidate !== '' &&
        (!validatePrice(newValueStringCandidate) || formattedValue < this.min || formattedValue > this.max)
      ) {
        this.$nextTick(_ => {
          this.inputModel = oldValue;
        });
      } else {
        this.$emit('input', formattedValue);
      }
    }
  },
  created() {
    this.updateInputModelByValue(this.currentValue);
  },
  methods: {
    updateInputModelByValue(value) {
      if (this.format(this.inputModel) !== value) {
        let newInputText = value === null || value === undefined ? '' : value.toString();
        if (!this.strictDecimalSeparator) {
          newInputText = newInputText.replace('.', this.decimalSeparator);
        }
        this.inputModel = newInputText;
      }
      if (!this.hasFocus) {
        this.formatInput();
      }
    },
    format(input) {
      if (input === '' && !this.required) {
        return null;
      }
      if (!input) {
        return 0;
      }
      if (input === '-') {
        return 0;
      }
      if (typeof input === 'string') {
        return +input.replace(this.decimalSeparator, '.');
      }
      return +input;
    },
    onBlurHandler(e) {
      this.hasFocus = false;
      this.$emit('blur', e);
      this.formatInput();
    },
    onFocusHandler(e) {
      this.hasFocus = true;
      this.$emit('focus', e);
      this.unformatInput();

      const allowLimitExceed = !this.required && this.currentValue === null;
      const value = this.currentValue || 0;
      if (!allowLimitExceed && value < this.min) {
        this.$emit('input', this.min);
      } else if (!allowLimitExceed && value > this.max) {
        this.$emit('input', this.max);
      }
    },
    formatInput() {
      const inputNumberValue = this.format(this.inputModel);
      if (!Number.isNaN(inputNumberValue) && inputNumberValue !== null) {
        this.inputModel = inputNumberValue.toFixed(this.precision);
        this.inputModel = this.inputModel.replace(/[\.]+/g, this.decimalSeparator);

        const decimalSplit = this.inputModel.split(this.decimalSeparator);

        this.inputModel = decimalSplit[0];
        if (this.showThousandSeparator) {
          this.inputModel = this.inputModel.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + this.thousandSeparator);
        }
        if (decimalSplit[1] !== undefined) {
          this.inputModel += this.decimalSeparator + decimalSplit[1];
        }
        this.inputModel = this.prefix + this.inputModel;
        this.inputModel += this.suffix;
      } else {
        this.inputModel = '';
      }
    },
    unformatInput() {
      if (this.showThousandSeparator) {
        const thousandSeparatorRegEx = new RegExp('\\' + this.thousandSeparator + '', 'g');
        this.inputModel = this.inputModel.replace(thousandSeparatorRegEx, '');
      }
      this.inputModel = this.inputModel.slice(this.prefix.length);
      this.inputModel = this.inputModel.slice(0, this.inputModel.length - this.suffix.length);
    }
  }
};
</script>

<style lang="scss" scoped>
input.is-invalid {
  background-color: #fccfd5;
}
</style>
