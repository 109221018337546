import request from '@/utils/request';

function addNewMenuMenuOptionGroup(params) {
  return request({
    method: 'post',
    url: 'menu-menu-option-group/create',
    data: params
  });
}

export const storeExtensions = {
  actions: {
    addNewMenuMenuOptionGroup({ commit, dispatch, state }, params) {
      return new Promise((resolve, reject) =>
        addNewMenuMenuOptionGroup(params)
          .then(response => {
            const dto = response.data;
            return dispatch('transformDto', dto).then(item => {
              commit('UPSERT_LOOSE_ITEM', item);
              commit('UPSERT_ITEM', item);
              commit('SET_TOTAL_ITEM_COUNT', state.totalItemsCount + 1);
              commit('SET_LOADING', false);
              return item;
            });
          })
          .catch(error => {
            reject(error);
          })
      );
    }
  }
};
