import { PermissionLevel } from '@/utils/permission';
export const usersRoutes = [
  {
    component: () => import('@/pages/admin/security/roles/index.vue'),
    meta: {
      permissions: [{ Role: PermissionLevel.Read }],
      title: 'menu_admin_roles'
    },
    name: 'Roles',
    path: 'roles'
  },
  {
    component: () => import('@/pages/admin/security/roles/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ Role: PermissionLevel.Write }],
      title: 'createRole'
    },
    name: 'CreateRole',
    path: 'roles/create'
  },
  {
    component: () => import('@/pages/admin/security/roles/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ Role: PermissionLevel.Read }],
      title: 'editRole'
    },
    name: 'EditRole',
    path: 'roles/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/admin/security/standardUsers/index.vue'),
    meta: {
      permissions: [{ StandardUser: PermissionLevel.Read }],
      title: 'menu_admin_users'
    },
    name: 'StandardUsers',
    path: 'users'
  },
  {
    component: () => import('@/pages/admin/security/standardUsers/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ StandardUser: PermissionLevel.Write }],
      title: 'createUser'
    },
    name: 'CreateStandardUser',
    path: 'users/create'
  },
  {
    component: () => import('@/pages/admin/security/standardUsers/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ StandardUser: PermissionLevel.Read }],
      title: 'editUser'
    },
    name: 'EditStandardUser',
    path: 'users/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/admin/security/restaurantUsers/index.vue'),
    meta: {
      permissions: [{ RestaurantUser: PermissionLevel.Read }],
      title: 'menu_admin_restaurant_users'
    },
    name: 'RestaurantUsers',
    path: 'restaurantUsers'
  },
  {
    component: () => import('@/pages/admin/security/restaurantUsers/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ RestaurantUser: PermissionLevel.Read }],
      title: 'createRestaurantUser'
    },
    name: 'CreateRestaurantUser',
    path: 'restaurantUsers/create'
  },
  {
    component: () => import('@/pages/admin/security/restaurantUsers/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ RestaurantUser: PermissionLevel.Read }],
      title: 'editRestaurantUser'
    },
    name: 'EditRestaurantUser',
    path: 'restaurantUsers/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/admin/security/deliveryAppUsers/index.vue'),
    meta: {
      permissions: [{ DeliveryAppUser: PermissionLevel.Read }],
      title: 'menu_admin_deliveryapp_users'
    },
    name: 'DeliveryAppUsers',
    path: 'deliveryAppUsers'
  },
  {
    component: () => import('@/pages/admin/security/deliveryAppUsers/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ DeliveryAppUser: PermissionLevel.Read }],
      title: 'createDeliveryAppUser'
    },
    name: 'CreateDeliveryAppUser',
    path: 'deliveryAppUsers/create'
  },
  {
    component: () => import('@/pages/admin/security/deliveryAppUsers/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ DeliveryAppUser: PermissionLevel.Read }],
      title: 'editDeliveryAppUser'
    },
    name: 'EditDeliveryAppUser',
    path: 'deliveryAppUsers/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/admin/security/externalApiUsers/index.vue'),
    meta: {
      permissions: [{ ExternalApiUser: PermissionLevel.Read }],
      title: 'menu_admin_external_api_users'
    },
    name: 'ExternalApiUsers',
    path: 'externalApiUsers'
  },
  {
    component: () => import('@/pages/admin/security/externalApiUsers/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ ExternalApiUser: PermissionLevel.Read }],
      title: 'createExternalApiUser'
    },
    name: 'CreateExternalApiUser',
    path: 'externalApiUsers/create'
  },
  {
    component: () => import('@/pages/admin/security/externalApiUsers/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ ExternalApiUser: PermissionLevel.Read }],
      title: 'editExternalApiUser'
    },
    name: 'EditExternalApiUser',
    path: 'externalApiUsers/edit/:id(\\d+)'
  }
];
