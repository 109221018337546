import { toDateTimeString } from '@/utils/date';

function transformItem2Dto(item) {
  const dto = {
    ...item,
    computerAccessID: item.id,
    createdAt: toDateTimeString(item.createdAt)
  };
  delete dto.id;
  return dto;
}

export const apiOverrides = { transformItem2Dto };
