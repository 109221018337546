import { PermissionLevel } from '@/utils/permission';
export const websiteRoutes = [
  {
    component: () => import('@/pages/masterdata/homepageblocks/index.vue'),
    meta: {
      permissions: [{ HomePageBlock: PermissionLevel.Read }],
      title: 'menu_home_page_blocks'
    },
    name: 'HomePageBlock',
    path: 'homePageBlock'
  },
  {
    component: () => import('@/pages/masterdata/homepageblocks/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ HomePageBlock: PermissionLevel.Write }],
      title: 'createHomePageBlock'
    },
    name: 'CreateHomePageBlock',
    path: 'homePageBlock/create'
  },
  {
    component: () => import('@/pages/masterdata/homepageblocks/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ HomePageBlock: PermissionLevel.Read }],
      title: 'editHomePageBlock'
    },
    name: 'EditHomePageBlock',
    path: 'homePageBlock/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/pages/index.vue'),
    meta: {
      permissions: [{ Page: PermissionLevel.Read }],
      title: 'menu_pages'
    },
    name: 'Page',
    path: 'page'
  },
  {
    component: () => import('@/pages/masterdata/pages/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ Page: PermissionLevel.Write }],
      title: 'createPage'
    },
    name: 'CreatePage',
    path: 'page/create'
  },
  {
    component: () => import('@/pages/masterdata/pages/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ Page: PermissionLevel.Read }],
      title: 'editPage'
    },
    name: 'EditPage',
    path: 'page/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/scratchandwinactions/index.vue'),
    meta: {
      permissions: [{ ScratchAndWinAction: PermissionLevel.Read }],
      title: 'menu_scratch_and_win_actions'
    },
    name: 'ScratchAndWinActions',
    path: 'scratch-and-win-action'
  },
  {
    component: () => import('@/pages/masterdata/scratchandwinactions/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ ScratchAndWinAction: PermissionLevel.Read }],
      title: 'editScratchAndWinAction'
    },
    name: 'EditScratchAndWinAction',
    path: 'scratch-and-win-action/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/scratchandwinactions/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ ScratchAndWinAction: PermissionLevel.Write }],
      title: 'createScratchAndWinAction'
    },
    name: 'CreateScratchAndWinAction',
    path: 'scratch-and-win-action/create'
  }
];
