import request from '@/utils/request';

export function apiLoginByUsername(code, password) {
  const dto = {
    password: password,
    username: code
  };
  return request({
    data: dto,
    method: 'post',
    url: '/authenticate'
  });
}

export function apiImpersonateUser(targetUserID) {
  const dto = {
    userID: targetUserID
  };
  return request({
    data: dto,
    method: 'post',
    url: '/impersonate'
  });
}

export function apiLogout() {
  return request({
    method: 'post',
    url: '/logout'
  });
}

export function apiChangePassword(userId, oldPassword, newPassword) {
  const dto = {
    newPassword: newPassword,
    oldPassword: oldPassword,
    userID: userId
  };
  return request({
    data: dto,
    method: 'put',
    url: '/change-password'
  });
}

export function apiGetPasswordPolicy() {
  return request({
    method: 'get',
    url: '/password-policy'
  });
}

export function apiValidateNewPasswordToken(token) {
  return request({
    data: { token },
    method: 'post',
    url: '/new-password/validate'
  });
}

export function apiChangePasswordWithToken(password, token) {
  const dto = {
    password,
    token
  };
  return request({
    data: dto,
    method: 'post',
    url: '/new-password'
  });
}
export function apiForgotPassword(username, email) {
  const dto = {
    username,
    email
  };
  return request({
    data: dto,
    method: 'post',
    url: '/forgot-password'
  });
}
