const baseGetters = {
  getBackendEntityIDField: state => state.backendEntityIDField,
  getLoadedItemByID: state => id => {
    const item = state.items.find(item => item.id === +id);
    return item;
  },
  getLooseItemByID: state => id => {
    const item = state.looseItems.find(item => item.id === +id);
    return item;
  },
  getLooseOrLoadedItemByID: state => id => {
    const item = state.items.find(item => item.id === +id) || state.looseItems.find(item => item.id === +id);
    return item;
  },
  isLoading(state) {
    return state.loading;
  },
  loadedItems(state) {
    return state.items;
  },
  loadedPaginatedItems(state) {
    return state.paginatedItems;
  },
  loadedItemsCount(state) {
    return state.items.length;
  },
  totalItemsCount(state) {
    return state.totalItemsCount;
  },
  getVersion: state => state.version
};

export default baseGetters;
