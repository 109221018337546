<template>
  <div :class="computedClasses" class="material-input__component">
    <div ref="inputs" v-loading="loading" :class="{ iconClass: icon }">
      <i v-if="icon" :class="['el-icon-' + icon]" class="el-input__icon material-input__icon" />
      <input
        v-if="type === 'email'"
        v-model="currentValue"
        :name="name"
        :placeholder="fillPlaceHolder"
        :readonly="readonly"
        :disabled="disabled"
        :autoComplete="autoComplete"
        :required="required"
        type="email"
        :class="computedInputClasses"
        @focus="handleMdFocus"
        @blur="handleMdBlur"
        @input="handleModelInput"
      />
      <input
        v-if="type === 'url'"
        v-model="currentValue"
        :name="name"
        :placeholder="fillPlaceHolder"
        :readonly="readonly"
        :disabled="disabled"
        :autoComplete="autoComplete"
        :required="required"
        type="url"
        :class="computedInputClasses"
        @focus="handleMdFocus"
        @blur="handleMdBlur"
        @input="handleModelInput"
      />
      <duration-input
        v-if="type === 'duration'"
        v-model="currentValue"
        :readonly="readonly"
        :disabled="disabled"
        :required="required"
        :placeholder="fillPlaceHolder"
        class="material-input"
        @focus="handleMdFocus"
        @blur="handleMdBlur"
        @input="handleNumericInput"
      />
      <numeric-input
        v-if="type === 'number'"
        v-model="currentValue"
        :name="name"
        :min="min"
        :max="max"
        :precision="precision"
        :readonly="readonly"
        :disabled="disabled"
        :required="required"
        :placeholder="fillPlaceHolder"
        :prefix="prefix"
        :suffix="suffix"
        :auto-complete="autoComplete"
        :show-thousand-separator="showThousandSeparator"
        :invalid="invalid"
        class="material-input"
        @focus="handleMdFocus"
        @blur="handleMdBlur"
        @input="handleNumericInput"
      />
      <numeric-input
        v-if="type === 'currency'"
        v-model="currentValue"
        :name="name"
        :min="min"
        :max="max"
        :precision="2"
        :readonly="readonly"
        :disabled="disabled"
        :required="required"
        :placeholder="fillPlaceHolder"
        :show-thousand-separator="showThousandSeparator"
        prefix="€ "
        :auto-complete="autoComplete"
        :invalid="invalid"
        class="material-input"
        @focus="handleMdFocus"
        @blur="handleMdBlur"
        @input="handleNumericInput"
      />
      <numeric-input
        v-if="type === 'percentage'"
        v-model="currentValue"
        :name="name"
        :min="min"
        :max="max"
        :precision="precision"
        :readonly="readonly"
        :disabled="disabled"
        :required="required"
        :placeholder="fillPlaceHolder"
        :show-thousand-separator="showThousandSeparator"
        suffix=" %"
        :auto-complete="autoComplete"
        :invalid="invalid"
        class="material-input"
        @focus="handleMdFocus"
        @blur="handleMdBlur"
        @input="handleNumericInput"
      />
      <input
        v-if="type === 'password'"
        v-model="currentValue"
        :name="name"
        :placeholder="fillPlaceHolder"
        :readonly="readonly"
        :disabled="disabled"
        :autoComplete="autoComplete"
        :max="max"
        :min="min"
        :required="required"
        type="password"
        class="material-input"
        @focus="handleMdFocus"
        @blur="handleMdBlur"
        @input="handleModelInput"
      />
      <input
        v-if="type === 'tel'"
        v-model="currentValue"
        :name="name"
        :placeholder="fillPlaceHolder"
        :readonly="readonly"
        :disabled="disabled"
        :autoComplete="autoComplete"
        :minlength="phoneNumberMinLength"
        :maxlength="phoneNumberMaxLength"
        :required="required"
        type="tel"
        class="material-input"
        @focus="handleMdFocus"
        @blur="handleMdBlur"
        @input="handleModelInput"
      />
      <input
        v-if="type === 'text'"
        v-model="currentValue"
        :name="name"
        :placeholder="fillPlaceHolder"
        :readonly="readonly"
        :disabled="disabled"
        :autoComplete="autoComplete"
        :minlength="minlength"
        :maxlength="maxlength"
        :required="required"
        type="text"
        :class="computedInputClasses"
        @focus="handleMdFocus"
        @blur="handleMdBlur"
        @input="handleModelInput"
      />
      <input
        v-if="type === 'time'"
        v-model="currentValue"
        :name="name"
        :placeholder="fillPlaceHolder"
        :readonly="readonly"
        :disabled="disabled"
        :minlength="minlength"
        :maxlength="maxlength"
        :required="required"
        type="text"
        :class="computedInputClasses"
        @focus="handleMdFocus"
        @blur="handleMdBlur"
        @input="handleModelInput"
      />
      <el-autocomplete
        v-if="type === 'autocomplete'"
        ref="autocomplete"
        v-model="currentValue"
        style="width: 100%"
        :name="name"
        :placeholder="fillPlaceHolder"
        :readonly="readonly"
        :disabled="disabled"
        :auto-complete="autoComplete"
        :minlength="minlength"
        :maxlength="maxlength"
        :required="required"
        v-bind="$attrs"
        type="text"
        @focus="handleMdFocus"
        @blur="handleMdBlur"
        @input="handleInputValue"
        @select="$emit('select', $event)"
      >
        <template v-slot:default="{ item }">
          <slot name="autocomplete" v-bind="item" />
        </template>
      </el-autocomplete>
      <span class="material-input-bar" />
      <label class="material-label">
        <slot v-if="!hideSlot" />
      </label>
    </div>
  </div>
</template>

<script>
import DurationInput from '@/components/DSE/DurationInput';
import NumericInput from '@/components/DSE/NumericInput';
// source:https://github.com/wemake-services/vue-material-input/blob/master/src/components/MaterialInput.vue

export default {
  name: 'MdInput',
  components: {
    NumericInput,
    DurationInput
  },
  props: {
    loading: Boolean,
    icon: String,
    name: String,
    type: {
      type: String,
      default: 'text'
    },
    value: [String, Number],
    placeholder: [String, Array],
    translatePlaceholder: {
      type: Boolean,
      default: true
    },
    readonly: Boolean,
    disabled: Boolean,
    invalid: Boolean,
    min: Number,
    max: Number,
    precision: Number,
    step: Number,
    minlength: Number,
    maxlength: Number,
    phoneNumberMaxLength: {
      type: Number,
      default: 16
    },
    phoneNumberMinLength: Number,
    prefix: String,
    suffix: String,
    showThousandSeparator: Boolean,
    required: {
      type: Boolean,
      default: false
    },
    autoComplete: {
      type: String,
      default: 'off'
    },
    validateEvent: {
      type: Boolean,
      default: true
    },
    textTransform: {
      type: String,
      default: 'none',
      validator: function(value) {
        return ['uppercase', 'lowercase', 'none'].indexOf(value) !== -1;
      }
    },
    hideSlot: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: this.value,
      hasFocus: false,
      fillPlaceHolder: this.placeholder
    };
  },
  computed: {
    computedInputClasses() {
      let classes = 'material-input ';
      classes += 'material-input-' + this.textTransform;
      return classes;
    },
    computedClasses() {
      return {
        'no-label': !this.$slots.default || this.hideSlot,
        'material--active': this.hasFocus,
        'material--disabled': this.disabled,
        'material--readonly': this.readonly,
        'material--raised': Boolean(this.hasFocus || this.currentValue) // has value
      };
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = this.applyTextTransform(newValue);
    }
  },
  mounted() {
    if (this.$refs.autocomplete) {
      this.computedInputClasses.split(' ').forEach(c => {
        this.$refs.autocomplete.$refs.input.$refs.input.classList.add(c);
      });
    }
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        const inputElements = this.$refs.inputs.getElementsByTagName('input');
        if (inputElements.length > 0) {
          inputElements[0].focus();
        }
      });
    },
    applyTextTransform(target) {
      let value = target;
      if (this.type === 'time') {
        value = value.replace('.', ':');
        value = value.replace(',', ':');
      }
      if (this.textTransform === 'uppercase') {
        value = value.toUpperCase();
      } else if (this.textTransform === 'lowercase') {
        value = value.toLowerCase();
      }
      return value;
    },
    handleModelInput(event) {
      const value = event.target.value;
      this.handleInputValue(value);
    },
    handleInputValue(value) {
      value = this.applyTextTransform(value);
      this.$emit('input', value);
      if (this.$parent.$options.componentName === 'ElFormItem') {
        if (this.validateEvent) {
          this.$parent.$emit('el.form.change', [value]);
        }
      }
      this.$emit('change', value);
    },
    handleMdFocus(event) {
      this.hasFocus = true;
      this.$emit('hasFocus', event);
      this.fillPlaceHolder = this.getPlaceholder();
    },
    getPlaceholder() {
      if (!this.placeholder) {
        return null;
      }
      if (Array.isArray(this.placeholder)) {
        return this.$i18n.t(`${this.placeholder[0]}`, this.placeholder[1]);
      }
      return (this.fillPlaceHolder = this.translatePlaceholder ? this.$i18n.t(this.placeholder) : this.placeholder);
    },
    handleMdBlur(event) {
      this.hasFocus = false;
      this.$emit('blur', event);
      if (this.$parent.$options.componentName === 'ElFormItem') {
        if (this.validateEvent) {
          this.$parent.$emit('el.form.blur', [this.currentValue]);
        }
      }
    },
    handleNumericInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
@use "sass:math";
// Fonts:
$font-size-base: 14px;
$font-size-small: 14px;
$font-size-smallest: 12px;
$font-weight-normal: normal;
$font-weight-bold: bold;
$apixel: 1px;
// Utils
$spacer: 12px;
$transition: 0.2s ease all;
$index: 0px;
$index-has-icon: 30px;
// Theme:
$color-white: white;
$color-grey: #9e9e9e;
$color-grey-light: #e0e0e0;
$color-blue: #2196f3;
$color-red: #f44336;
$color-black: black;
// Base clases:
%base-bar-pseudo {
  content: '';
  height: 1px;
  width: 0;
  bottom: 0;
  position: absolute;
  transition: $transition;
}

// Mixins:
@mixin slided-top() {
  top: -($font-size-base + $spacer);
  left: 0;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}

// Component:
.material-input__component {
  margin-top: 36px;
  position: relative;
  * {
    box-sizing: border-box;
  }
  .iconClass {
    .material-input__icon {
      position: absolute;
      left: 0;
      line-height: $font-size-base;
      color: $color-blue;
      top: $spacer;
      width: $index-has-icon;
      height: $font-size-base;
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      pointer-events: none;
    }
    .material-label {
      left: $index-has-icon;
    }
    .material-input {
      text-indent: $index-has-icon;
    }
  }
  .material-input {
    font-size: $font-size-small;
    padding: $spacer $spacer $spacer - $apixel * 10 math.div($spacer, 2);
    display: block;
    width: 100%;
    border: none;
    line-height: 1;
    border-radius: 0;
    &:focus {
      outline: none;
      border: none;
      border-bottom: 1px solid transparent; // fixes the height issue
    }
  }
  .material-label {
    font-weight: $font-weight-normal;
    position: absolute;
    pointer-events: none;
    left: $index;
    top: 0;
    transition: $transition;
    font-size: $font-size-small;
  }
  .material-input-bar {
    position: relative;
    display: block;
    width: 100%;
    &:before {
      @extend %base-bar-pseudo;
      left: 50%;
    }
    &:after {
      @extend %base-bar-pseudo;
      right: 50%;
    }
  }
  // Disabled state:
  &.material--disabled {
    .material-input {
      border-bottom-style: dashed;
    }
  }
  // Raised state:
  &.material--raised {
    .material-label {
      @include slided-top();
    }
  }
  // Active state:
  &.material--active {
    .material-input-bar {
      &:before,
      &:after {
        width: 50%;
      }
    }
  }
}

.material-input__component {
  background: $color-white;
  .material-input {
    background: none;
    color: $color-black;
    text-indent: $index;
    border-bottom: 1px solid $color-grey-light;
  }
  .material-input-uppercase {
    text-transform: uppercase;
  }
  .material-input-lowercase {
    text-transform: lowercase;
  }
  .material-label {
    color: $color-grey;
  }
  .material-input-bar {
    &:before,
    &:after {
      background: $color-blue;
    }
  }
  // Active state:
  &.material--active {
    .material-label {
      color: $color-blue;
    }
  }
  // Readonly state:
  &.material--readonly {
    background: none;
    .material-label,
    .material-input {
      border: none;
    }
  }
  // Errors:
  &.material--has-errors {
    &.material--active .material-label {
      color: $color-red;
    }
    .material-input-bar {
      &:before,
      &:after {
        background: transparent;
      }
    }
  }
}
</style>
