export function transformItem2Dto(item) {
  const dto = {
    userID: item.id,
    ...item
  };
  delete dto.id;
  if (!item.reference || !item.reference.trim()) {
    dto.reference = null;
  }
  const roles = [];
  if (item.roles) {
    item.roles.forEach(role => {
      const roleDto = {
        roleID: role.id,
        ...role
      };
      delete roleDto.id;
      roles.push(roleDto);
    });
  }
  dto.roles = roles;

  return dto;
}

function transformDto2Item(dto) {
  const item = {
    id: dto.userID,
    ...dto
  };
  delete item.userID;

  const roles = [];
  if (dto.roles) {
    dto.roles.forEach(roleDto => {
      const role = {
        id: roleDto.roleID,
        ...roleDto
      };
      delete role.roleID;
      roles.push(role);
    });
  }
  item.roles = roles;
  return item;
}

export const apiOverrides = { transformItem2Dto, transformDto2Item };
