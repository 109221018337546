import { PermissionLevel } from '@/utils/permission';

/**
 * Pages for restaurant managers
 * Employees, Vehicles, Local promotions, VIP point corrections , ....
 */
export const myRestaurantRoutes = [
  {
    component: () => import('@/pages/masterdata/couponcodes/mycouponcodes/index.vue'),
    meta: {
      permissions: [{ MyCouponCodes: PermissionLevel.Read }],
      title: 'menu_coupon_codes'
    },
    name: 'MyCouponCodes',
    path: 'couponcodes'
  },
  {
    component: () => import('@/pages/admin/application/computeraccesses/restaurantmanager/index.vue'),
    meta: {
      permissions: [{ MyComputerAccess: PermissionLevel.Read }],
      title: 'menu_computer_accesses'
    },
    name: 'MyComputerAccesses',
    path: 'computer-accesses'
  },
  {
    component: () => import('@/pages/admin/application/computeraccesses/restaurantmanager/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ MyComputerAccess: PermissionLevel.Write }],
      title: 'editComputerAccess'
    },
    name: 'EditMyComputerAccess',
    path: 'computer-access/edit/:id'
  },
  {
    component: () => import('@/pages/admin/security/kassaUsers/index.vue'),
    meta: {
      permissions: [{ KassaUser: PermissionLevel.Read }],
      title: 'menu_kassa_users'
    },
    name: 'KassaUsers',
    path: 'kassa_users'
  },
  {
    component: () => import('@/pages/admin/security/kassaUsers/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ KassaUser: PermissionLevel.Write }],
      title: 'createKassaUser'
    },
    name: 'CreateKassaUser',
    path: 'kassa_users/create'
  },
  {
    component: () => import('@/pages/admin/security/kassaUsers/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ KassaUser: PermissionLevel.Write }],
      title: 'editKassaUser'
    },
    name: 'EditKassaUser',
    path: 'kassa_user/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/localproductcategories/myLocalProductCategories/index.vue'),
    meta: {
      permissions: [{ MyLocalProductCategory: PermissionLevel.Read }],
      title: 'menu_local_product_categories'
    },
    name: 'MyLocalProductCategories',
    path: 'local_product_categories'
  },
  {
    component: () => import('@/pages/masterdata/localproducts/myLocalProducts/index.vue'),
    meta: {
      permissions: [{ MyLocalProducts: PermissionLevel.Read }],
      title: 'menu_local_products'
    },
    name: 'MyLocalProducts',
    path: 'local_products'
  },
  {
    component: () => import('@/pages/masterdata/vehicles/index.vue'),
    meta: {
      permissions: [{ MyVehicles: PermissionLevel.Read }],
      title: 'menu_vehicles'
    },
    name: 'Vehicles',
    path: 'vehicles'
  },
  {
    component: () => import('@/pages/masterdata/vehicles/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ MyVehicles: PermissionLevel.Write }],
      title: 'createVehicle'
    },
    name: 'CreateVehicle',
    path: 'vehicles/create'
  },
  {
    component: () => import('@/pages/masterdata/vehicles/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ MyVehicles: PermissionLevel.Write }],
      title: 'editVehicle'
    },
    name: 'EditVehicle',
    path: 'vehicles/edit/:id(\\d+)'
  },

  {
    component: () => import('@/pages/employeeworklog/index.vue'),
    meta: {
      permissions: [{ EmployeeWorklog: PermissionLevel.Write }],
      title: 'menu_employeeworklog'
    },
    name: 'EmployeeWorklog',
    path: 'employeeworklog'
  },
  {
    component: () => import('@/pages/masterdata/employees/index.vue'),
    meta: {
      permissions: [{ MyEmployees: PermissionLevel.Read }],
      title: 'menu_employees'
    },
    name: 'Employees',
    path: 'employees'
  },
  {
    component: () => import('@/pages/masterdata/employees/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ MyEmployees: PermissionLevel.Write }],
      title: 'createEmployee'
    },
    name: 'CreateEmployee',
    path: 'employees/create'
  },
  {
    component: () => import('@/pages/masterdata/employees/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ MyEmployees: PermissionLevel.Read }],
      title: 'editEmployee'
    },
    name: 'EditEmployee',
    path: 'employees/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/couponcodes/mycouponcodes/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ MyCouponCodes: PermissionLevel.Write }],
      title: 'createCouponCode'
    },
    name: 'CreateMyCouponCode',
    path: 'couponcodes/create'
  },
  {
    component: () => import('@/pages/masterdata/couponcodes/mycouponcodes/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ MyCouponCodes: PermissionLevel.Write }],
      title: 'editCouponCode'
    },
    name: 'EditMyCouponCode',
    path: 'couponcodes/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/masterdata/postcoderestaurants/index.vue'),
    meta: {
      permissions: [{ PostcodeRestaurant: PermissionLevel.Read }],
      title: 'menu_postcode_restaurants'
    },
    name: 'PostcodeRestaurants',
    path: 'postcoderestaurants'
  },
  {
    component: () => import('@/pages/masterdata/postcoderestaurants/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ PostcodeRestaurant: PermissionLevel.Write }],
      title: 'editPostcodeRestaurant'
    },
    name: 'EditPostcodeRestaurant',
    path: 'postcoderestaurants/edit/:id(\\d+)'
  }
];
