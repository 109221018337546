/*eslint no-unused-vars: "error"*/
import * as accesslogOptions from './options/accesslog';
import * as eventlogOptions from './options/eventlog';
import * as permissionOptions from './options/permission';
import * as optionOptions from './options/option';
import * as roleOptions from './options/role';
import * as standardUserOptions from './options/standardUser';
import * as externalApiOptions from './options/externalApiUser';
import * as restaurantUserOptions from './options/restaurantUser';
import * as kassaUserOptions from './options/kassaUser';
import * as printerOptions from './options/printer';
import * as configurationOptions from './options/configuration';
import * as couponCodeOptions from './options/couponcode';
import * as customerOptions from './options/customer';
import * as employeeWorklogOptions from './options/employeeWorklog';
import * as restaurantOptions from './options/restaurant.js';
import * as computerAccessOptions from './options/computerAccess';
import * as deliveryAppUserOptions from './options/deliveryAppUser.js';
import * as postcodeOptions from './options/postcode';
import * as menuMenuOptionGroupOptions from './options/menuMenuOptionGroup';
import * as scratchAndWinActionOptions from './options/scratchAndWinAction';

// PLOP: Import option files
import * as partnershipOptions from './options/partnership';
import * as employeeOptions from './options/employee';
import * as productOptions from './options/product';

// eslint-disable-next-line
import { RouteParent, CrudStore } from './interfaces';

const Security = {
  name: 'Security',
  srcPath: '@/pages/admin/security/',
  i18nTitlePrefix: 'menu_security_'
} as RouteParent;

const Application = {
  name: 'Application',
  srcPath: '@/pages/admin/application/',
  i18nTitlePrefix: 'menu_application_'
} as RouteParent;

const Company = {
  name: 'Company',
  srcPath: '@/pages/admin/company/',
  i18nTitlePrefix: 'menu_company_'
} as RouteParent;

const routeParentPages = {
  Security,
  Application,
  Company
};

export const routerPages = {};
Object.keys(routeParentPages).forEach(key => {
  routerPages[routeParentPages[key].name] = [];
});

const eventlog = {
  permissionName: 'Eventlog',
  requestUrl: '/eventlog',
  store: 'eventlog',
  backendEntityIDField: 'eventLogID',
  options: eventlogOptions
} as CrudStore;

const accesslog = {
  permissionName: 'Accesslog',
  requestUrl: '/accesslog',
  store: 'accesslog',
  backendEntityIDField: 'accessLogID',
  options: accesslogOptions
} as CrudStore;

const appSetting = {
  permissionName: 'AppSetting',
  requestUrl: '/app-settings',
  store: 'appSetting',
  backendEntityIDField: 'appSettingID'
} as CrudStore;

const configuration = {
  permissionName: 'Configuration',
  requestUrl: '/configuration',
  store: 'configuration',
  options: configurationOptions,
  backendEntityIDField: 'configurationID'
} as CrudStore;

const contactPerson = {
  permissionName: 'ContactPerson',
  requestUrl: '/contactperson',
  store: 'contactPerson',
  backendEntityIDField: 'contactPersonID'
} as CrudStore;

const permission = {
  permissionName: 'Permission',
  requestUrl: '/permission',
  store: 'permission',
  backendEntityIDField: 'permissionID',
  options: permissionOptions
} as CrudStore;

const option = {
  permissionName: 'Option',
  requestUrl: '/option',
  store: 'option',
  backendEntityIDField: 'optionID',
  options: optionOptions
} as CrudStore;

const role = {
  permissionName: 'Role',
  requestUrl: '/role',
  store: 'role',
  backendEntityIDField: 'roleID',
  options: roleOptions
} as CrudStore;

const standardUser = {
  permissionName: 'StandardUser',
  requestUrl: '/standardUser',
  store: 'standardUser',
  backendEntityIDField: 'userID',
  options: standardUserOptions,
  route: {
    parent: Security,
    listViewName: 'StandardUsers'
  }
} as CrudStore;

const restaurantUser = {
  permissionName: 'RestaurantUser',
  requestUrl: '/restaurantUser',
  store: 'restaurantUser',
  options: restaurantUserOptions,
  backendEntityIDField: 'userID'
} as CrudStore;

const kassaUser = {
  permissionName: 'KassaUser',
  requestUrl: '/kassaUser',
  store: 'kassaUser',
  options: kassaUserOptions,
  backendEntityIDField: 'userID'
} as CrudStore;

const deliveryAppUser = {
  permissionName: 'DeliveryAppUser',
  requestUrl: '/deliveryAppUser',
  store: 'deliveryAppUser',
  backendEntityIDField: 'userID',
  options: deliveryAppUserOptions
} as CrudStore;

const externalApiUser = {
  permissionName: 'ExternalApiUser',
  requestUrl: '/externalApiUser',
  store: 'externalApiUser',
  options: externalApiOptions,
  backendEntityIDField: 'userID'
} as CrudStore;

const language = {
  permissionName: 'Language',
  requestUrl: '/language',
  store: 'language',
  backendEntityIDField: 'languageID'
} as CrudStore;

const country = {
  permissionName: 'Country',
  requestUrl: '/country',
  store: 'country',
  backendEntityIDField: 'countryID'
} as CrudStore;

const translation = {
  permissionName: 'Translation',
  requestUrl: '/string-description',
  store: 'translation',
  backendEntityIDField: 'stringDescriptionID'
} as CrudStore;

// PLOP: Crud store modules
const partnership = {
  permissionName: 'Partnership',
  requestUrl: '/partnership',
  store: 'partnership',
  options: partnershipOptions,
  backendEntityIDField: 'partnershipID'
} as CrudStore;

const scratchandwinaction = {
  permissionName: 'ScratchAndWinAction',
  requestUrl: '/scratch-and-win-action',
  store: 'scratchAndWinAction',
  options: scratchAndWinActionOptions,
  backendEntityIDField: 'scratchAndWinActionID'
} as CrudStore;

const scratchAndWinBatch = {
  permissionName: 'ScratchAndWinBatch',
  requestUrl: '/scratch-and-win-batch',
  store: 'scratchAndWinBatch',
  backendEntityIDField: 'scratchAndWinBatchID'
} as CrudStore;

const monitoredprocess = {
  permissionName: 'MonitoredProcess',
  requestUrl: '/monitored-process',
  store: 'monitoredProcess',
  backendEntityIDField: 'monitoredProcessID'
} as CrudStore;

const pindevice = {
  permissionName: 'PinDevice',
  requestUrl: '/pin-device',
  store: 'pinDevice',
  backendEntityIDField: 'pinDeviceID'
} as CrudStore;

const computeraccess = {
  permissionName: 'ComputerAccess',
  requestUrl: '/computer-access',
  store: 'computerAccess',
  backendEntityIDField: 'computerAccessID',
  options: computerAccessOptions
} as CrudStore;

const myComputerAccess = {
  permissionName: 'MyComputerAccess',
  requestUrl: '/computer-access',
  store: 'myComputerAccess',
  backendEntityIDField: 'computerAccessID',
  options: computerAccessOptions
} as CrudStore;

const page = {
  permissionName: 'Page',
  requestUrl: '/page',
  store: 'page',
  backendEntityIDField: 'pageID'
} as CrudStore;

const homepageblock = {
  permissionName: 'HomePageBlock',
  requestUrl: '/home-page-block',
  store: 'homePageBlock',
  backendEntityIDField: 'homePageBlockID'
} as CrudStore;

const messagetemplate = {
  permissionName: 'MessageTemplate',
  requestUrl: '/message-template',
  store: 'messageTemplate',
  backendEntityIDField: 'messageTemplateID'
} as CrudStore;

const loyaltypointstransaction = {
  permissionName: 'LoyaltyPointsTransaction',
  requestUrl: '/loyalty-points-transaction',
  store: 'loyaltyPointsTransaction',
  backendEntityIDField: 'loyaltyPointsTransactionID'
} as CrudStore;

const customer = {
  permissionName: 'Customer',
  requestUrl: '/customer',
  store: 'customer',
  options: customerOptions,
  backendEntityIDField: 'customerID'
} as CrudStore;

const myCustomer = {
  permissionName: 'MyCustomer',
  requestUrl: '/customer',
  store: 'myCustomer',
  options: customerOptions,
  backendEntityIDField: 'customerID'
} as CrudStore;

const localproductcategory = {
  permissionName: 'MyLocalProductCategory',
  requestUrl: '/local-product-category',
  store: 'localProductCategory',
  backendEntityIDField: 'localProductCategoryID'
} as CrudStore;

const localproduct = {
  permissionName: 'LocalProduct',
  requestUrl: '/local-product',
  store: 'localProduct',
  backendEntityIDField: 'localProductID'
} as CrudStore;

const couponcode = {
  permissionName: 'CouponCode',
  requestUrl: '/coupon-code',
  store: 'couponCode',
  options: couponCodeOptions,
  backendEntityIDField: 'couponCodeID'
} as CrudStore;

const mycouponcode = {
  permissionName: 'MyCouponCodes',
  requestUrl: '/coupon-code',
  store: 'myCouponCode',
  options: couponCodeOptions,
  backendEntityIDField: 'couponCodeID'
} as CrudStore;

const vehicle = {
  permissionName: 'Vehicle',
  requestUrl: '/vehicle',
  store: 'vehicle',
  backendEntityIDField: 'vehicleID'
} as CrudStore;

const vehicletype = {
  permissionName: 'VehicleType',
  requestUrl: '/vehicle-type',
  store: 'vehicleType',
  backendEntityIDField: 'vehicleTypeID'
} as CrudStore;

const menucategory = {
  permissionName: 'MenuCategory',
  requestUrl: '/menu-category',
  store: 'menuCategory',
  backendEntityIDField: 'menuCategoryID'
} as CrudStore;

const productcategory = {
  permissionName: 'ProductCategory',
  requestUrl: '/product-category',
  store: 'productCategory',
  backendEntityIDField: 'productCategoryID'
} as CrudStore;

const employee = {
  permissionName: 'Employee',
  requestUrl: '/employee',
  store: 'employee',
  options: employeeOptions,
  backendEntityIDField: 'employeeID'
} as CrudStore;

const restaurant = {
  permissionName: 'Restaurant',
  requestUrl: '/restaurant',
  store: 'restaurant',
  backendEntityIDField: 'restaurantID',
  options: restaurantOptions
} as CrudStore;

const pricecategory = {
  permissionName: 'PriceCategory',
  requestUrl: '/price-category',
  store: 'priceCategory',
  backendEntityIDField: 'priceCategoryID'
} as CrudStore;

const product = {
  permissionName: 'Product',
  requestUrl: '/product',
  store: 'product',
  options: productOptions,
  backendEntityIDField: 'productID'
} as CrudStore;

const printer = {
  permissionName: 'Printer',
  requestUrl: '/printer',
  store: 'printer',
  options: printerOptions,
  backendEntityIDField: 'printerID'
} as CrudStore;

const employeeWorklog = {
  permissionName: 'EmployeeWorklog',
  requestUrl: '/employee-worklog',
  store: 'employeeWorklog',
  options: employeeWorklogOptions,
  backendEntityIDField: 'worklogID'
} as CrudStore;

const customerAddress = {
  permissionName: 'CustomerAddress',
  requestUrl: '/customer-address',
  store: 'customerAddress',
  backendEntityIDField: 'customerAddressID'
} as CrudStore;

const postcodeRestaurant = {
  permissionName: 'PostcodeRestaurant',
  requestUrl: '/postcodeRestaurant',
  store: 'postcodeRestaurant',
  backendEntityIDField: 'postcodeRestaurantID'
} as CrudStore;

const postcode = {
  permissionName: 'Postcode',
  requestUrl: '/postcode',
  store: 'postcode',
  options: postcodeOptions
} as CrudStore;

const menu = {
  permissionName: 'Menu',
  requestUrl: '/menu',
  store: 'menu',
  backendEntityIDField: 'menuID'
} as CrudStore;

const menuBlock = {
  permissionName: 'MenuBlock',
  requestUrl: '/menublock',
  store: 'menuBlock',
  backendEntityIDField: 'menuBlockID'
} as CrudStore;

const menuOptionGroup = {
  permissionName: 'MenuOptionGroup',
  requestUrl: '/menu-option-group',
  store: 'menuOptionGroup',
  backendEntityIDField: 'menuOptionGroupID'
} as CrudStore;

const menuMenuOptionGroup = {
  permissionName: 'MenuMenuOptionGroup',
  requestUrl: '/menu-menu-option-group',
  store: 'menuMenuOptionGroup',
  options: menuMenuOptionGroupOptions,
  backendEntityIDField: 'menuMenuOptionGroupID'
} as CrudStore;

const menuOptionGroupProduct = {
  permissionName: 'MenuOptionGroupProduct',
  requestUrl: '/menu-option-group-product',
  store: 'menuOptionGroupProduct',
  backendEntityIDField: 'menuOptionGroupProductID'
} as CrudStore;

const orderHistory = {
  permissionName: 'OrderHistory',
  requestUrl: '/order-history',
  store: 'orderHistory'
} as CrudStore;

const entities = [
  scratchandwinaction,
  partnership,
  scratchAndWinBatch,
  monitoredprocess,
  pindevice,
  computeraccess,
  myComputerAccess,
  page,
  homepageblock,
  messagetemplate,
  loyaltypointstransaction,
  customer,
  myCustomer,
  customerAddress,
  localproductcategory,
  localproduct,
  couponcode,
  mycouponcode,
  vehicle,
  vehicletype,
  menu,
  menucategory,
  menuOptionGroup,
  menuMenuOptionGroup,
  menuOptionGroupProduct,
  menuBlock,
  productcategory,
  employee,
  employeeWorklog,
  restaurant,
  contactPerson,
  postcode,
  postcodeRestaurant,
  pricecategory,
  product,
  eventlog,
  accesslog,
  appSetting,
  configuration,
  language,
  permission,
  option,
  role,
  standardUser,
  restaurantUser,
  kassaUser,
  deliveryAppUser,
  externalApiUser,
  country,
  translation,
  printer,
  orderHistory
];

export default entities;
