import request from '@/utils/request';

function apiFetchKassaUser(id) {
  return request({
    method: 'get',
    url: '/kassauser/' + id.toString()
  });
}

export const storeExtensions = {
  actions: {
    fetchKassaUser({ dispatch }, id) {
      return apiFetchKassaUser(id);
    }
  }
};
