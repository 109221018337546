import { toDateTimeString } from '@/utils/date';

function transformItem2Dto(item) {
  const dto = {
    ...item,
    couponCodeID: item.id,
    couponStartDate: toDateTimeString(item.couponStartDate),
    couponEndDate: toDateTimeString(item.couponEndDate)
  };
  delete dto.id;
  return dto;
}

export const apiOverrides = { transformItem2Dto };
