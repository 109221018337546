import request from '@/utils/request';

function apiFetchRestaurantUser(id) {
  return request({
    method: 'get',
    url: '/restaurantUser/' + id.toString()
  });
}

export const storeExtensions = {
  actions: {
    fetchRestaurantUser({ dispatch }, id) {
      return apiFetchRestaurantUser(id);
    }
  }
};
