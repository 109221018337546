// Keys ougt to be sorted alphabetically, case sensitive.
// To autosort: repeat CTRL+S until all keys are sorted
//  OR
// repeat command until sorted: npm run lint-fix

/* eslint sort-keys-fix/sort-keys-fix: error */
export default {
  accesslog: {
    accessedAt: 'Datum tijd',
    accessedBy: 'Geopend door',
    accessedResource: 'Gebruikte bron',
    ip: 'IP',
    parameters: 'Parameters'
  },
  autoUpdate: {
    alertConfirm: 'Herlaad pagina',
    alertMessage:
      'Er is een nieuwe versie van de applicatie beschikbaar. Herlaad de pagina om de applicatie bij te werken.',
    alertTitle: 'Update'
  },
  common: {
    active: 'Actief',
    add: 'Toevoegen',
    addRow: 'Rij toevoegen',
    address: 'Adres',
    addresses: 'Adressen',
    ageRestriction: 'Minimum leeftijd',
    areaName: 'Wijk',
    billingAddress: 'Factuuradres',
    block_notification_not_saved_warning: 'Notificatie instelling niet opgeslagen. Probeer het later opnieuw',
    browserNotSupported: 'Uw browser ondersteunt deze functionaliteit niet',
    cancel: 'Annuleren',
    category: 'Categorie',
    chamberOfCommerceNumber: 'KVK nummer',
    city: 'Plaats',
    clearFilters: 'Verwijder alle filters',
    clearSelection: 'Deselecteren',
    close: 'Sluiten',
    close_unsaved_warning: 'Uw wijzigingen zijn nog niet opgeslagen. Weet u zeker dat u dit scherm wilt sluiten?',
    close_warning: 'Wilt u zeker dat u dit scherm wilt afsluiten?',
    code: 'Code',
    company: 'Bedrijf',
    companyAddress: 'Bezoekadres',
    companyName: 'Bedrijf',
    confirm: 'Bevestigen',
    content: 'Content',
    copy: 'Kopieer',
    copyToClipboard: 'Kopiëer naar klembord',
    copyToClipboardFail: 'Kopiëren naar klembord mislukt',
    copyToClipboardSuccess: 'Kopiëren naar klembord gelukt',
    country: 'Land',
    couponCode: 'Actiecode',
    customer: 'Klant',
    data_created: 'De gegevens zijn opgeslagen',
    data_save_error: 'De gegevens kunnen niet worden opgeslagen',
    data_updated: 'De wijzigingen zijn opgeslagen',
    date: 'Datum',
    dateOfBirth: 'Geboortedatum',
    decimals: 'Decimalen',
    delete: 'Verwijderen',
    deleteSelectedItems: 'Geselecteerde items verwijderen',
    delete_cancelled: 'Verwijderen geannuleerd',
    delete_completed: 'De gegevens zijn verwijderd',
    delete_record_summary_warning: 'Weet u zeker dat u <b>{summary}</b> wilt verwijderen?',
    delete_record_warning: 'Weet u zeker dat u dit wilt verwijderen?',
    deliveryMethod: 'Bezorgmethode',
    deliveryPerson: 'Koerier',
    department: 'Afdeling',
    deposit: 'Statiegeld',
    description: 'Beschrijving',
    displayName: 'Weergave op site',
    dontSave: 'Niet opslaan',
    dontSend: 'Niet verzenden',
    dont_show_again_warning: 'Niet meer tonen',
    draft: 'Concept',
    ean: 'EAN',
    edit: 'Bewerk',
    email: 'E-mailadres',
    employee: 'Werknemer',
    employees: 'Werknemers',
    emptyString: ' ',
    error: 'Fout',
    expectedDeliveryTime: 'Gewenste levertijd',
    export: 'Export',
    file: 'Bestand',
    firstName: 'Voornaam',
    fixedPhone: 'Vaste telefoon',
    fromDate: '',
    gender: 'Geslacht',
    general: 'Algemeen',
    houseNumber: 'Huisnummer',
    ibanNumber: 'IBAN',
    id: 'ID',
    image: 'Afbeelding',
    impersonateUser: 'Inloggen als deze gebruiker',
    inactive: 'Inactief',
    initials: 'Initialen',
    isMainAddressYN: 'Hoofdadres',
    jobFunction: 'Functie',
    key: 'Sleutel',
    lastName: 'Achternaam',
    latitude: 'Breedtegraad',
    leave_mail_warning: 'U verlaat deze pagina. Wilt u eerst deze mail verzenden?',
    leave_unsaved_warning: 'U verlaat deze pagina. Wilt u de wijzigingen opslaan?',
    legacyOrderID: 'Order ID',
    loading: 'Laden ...',
    localProducts: 'Lokale producten',
    longitude: 'Lengtegraad',
    loyaltyPoints: 'VIP punten',
    mailingAddress: 'Postadres',
    menuCategory: 'Menucategorie',
    menuComposition: 'Menusamenstelling',
    mobilePhone: 'Mobiele telefoon',
    modified: 'Gewijzigd',
    mollieKey: 'Mollie key',
    name: 'Naam',
    no: 'Nee',
    notApplicable: 'Niet van toepassing',
    nutritionValues: 'Voedingswaarden',
    ok: 'OK',
    onlineYN: 'Actief',
    partnership: 'Samenwerkings-verband',
    password: 'Wachtwoord',
    phone: 'Telefoon',
    phoneIce: 'Telefoon (I.C.E.)',
    position: 'Positie op website',
    positionInCashDesk: 'Volgorde in kassa',
    positionOnTicket: 'Positie op bon',
    postalCode: 'Postcode',
    prefix: 'Tussenvoegsel',
    price: 'Prijs',
    priceCategory: 'Prijscategorie',
    printers: 'Printers',
    product: 'Product',
    productCategory: 'Productcategorie',
    products: 'Producten',
    publish: 'Publiceren',
    quantity: 'Aantal',
    question: 'Vraag',
    readMore: 'Lees meer ...',
    reference: 'Referentie',
    remark: 'Opmerking',
    remarks: 'Opmerkingen',
    restaurant: 'Restaurant',
    restaurantName: 'Restaurant',
    restaurants: 'Restaurants',
    salutation: 'Aanhef',
    save: 'Opslaan',
    saveClose: 'Opslaan & sluiten',
    saveNext: 'Opslaan & volgende',
    search: 'Zoek',
    send: 'Verzenden',
    shortcut: 'Website shortcut',
    skip: 'Overslaan',
    status: 'Status',
    stay: 'Blijf op pagina',
    streetName: 'Straat',
    streetNumber: 'Huisnummer',
    success: 'Succes',
    switchOff: 'Uit',
    switchOn: 'Aan',
    tabGeneral: 'Algemeen',
    takeOutAndDeliveryConcept: 'Takeout and Delivery',
    takeawayID: 'Takeaway ID',
    taskstatus: 'Taakstatus',
    ticketNumber: 'Bonnummer',
    timeCreated: 'Tijd binnenkomst',
    timeDelivered: 'Werkelijke levertijd',
    timeExpectedDelivery: 'Gewenste levertijd',
    timeHoursMinutes: 'u:min',
    timeInstore: 'Tijd in keuken',
    timeLeftStore: 'Tijd verlaten keuken',
    timeWaited: 'Wachttijd',
    title: 'Titel',
    toGo: 'To Go formule',
    total: 'Totaal',
    translations: 'Vertalingen',
    type: 'Type',
    unit: 'Eenheid',
    useShiftBaseYN: 'Gebruikt Shiftbase',
    username: 'Gebruikersnaam',
    value: 'Waarde',
    vatNumber: 'BTW-nummer',
    vatRateOption: 'BTW tarief',
    vehicle: 'Voertuig',
    vehicleType: 'Voertuig type',
    vehicleTypes: 'Voertuig types',
    vehicles: 'Voertuigen',
    warning: 'Waarschuwing',
    yes: 'Ja'
  },
  computerAccess: {
    accessYN: 'Toestemming',
    code: 'Code',
    createdAt: 'Datum/tijd aanvraag toegang',
    ip: 'IP-Adres',
    locale: 'Lokaal',
    userAgent: 'Browser'
  },
  configuration: {
    code: 'Code',
    fromDate: 'Geldig vanaf',
    value: 'Waarde'
  },
  coupon: {
    conditions: 'Voorwaarden',
    couponType: 'Type korting',
    createdByRestaurantName: 'Aangemaakt door',
    customer: 'Specifiek voor klant',
    discountAmount: 'Kortingsbedrag',
    discountPercentage: 'Kortingspercentage',
    endDateTime: 'Geldig tot en met',
    externalProductID: 'Extern product ID',
    minimumSpending: 'Minimum bedrag',
    oncePerOrder: 'Eénmaal per bestelling',
    onlyUseOnce: 'Eénmalig te gebruiken',
    restaurants: 'Restaurants',
    startDateTime: 'Geldig vanaf'
  },
  customer: {
    account: 'Heeft account',
    blackList: 'Zwarte lijst',
    excludeFromDirectMailYN: 'Geen direct mail versturen',
    hasAccount: 'VIP',
    newsletter: 'Nieuwsbrief'
  },
  dashboard: {
    revenueChart: 'Omzetontwikkeling',
    sparewareNews: 'Formule nieuws',
    sparewareNewsSubtitle: 'De laatste formule updates'
  },
  dayOfWeek: {
    friday: 'Vrijdag',
    monday: 'Maandag',
    saturday: 'Zaterdag',
    sunday: 'Zondag',
    thursday: 'Donderdag',
    tuesday: 'Dinsdag',
    wednesday: 'Woensdag'
  },
  deliveryAppUser: {
    jwtToken: 'Bearer token',
    tokenIssuedAt: 'Uitgegeven',
    tokenValidUntil: 'Geldig tot'
  },
  deliveryMethod: {
    delivery: 'Bezorgen',
    dining: 'Dineren',
    takeOut: 'Afhalen'
  },
  deliveryReport: {
    average: 'Gem',
    customerWaitingTime: 'Wachttijd klant (min)',
    deliveryDude: 'Koerier',
    deliveryDuration: 'Bezorgtijd (min)',
    groupByDate: 'Groepeer per datum',
    groupByDeliverer: 'Groepeer per koerier',
    max: 'Max',
    min: 'Min',
    numberOfTickets: 'Aantal bonnen',
    period: 'Periode',
    timeInKitchen: 'Tijd in keuken (min)'
  },
  editor: {
    entityFetchError: 'Dit item bestaat niet (meer) of u heeft geen toegang tot de gegevens van deze pagina.'
  },
  employee: {
    change: 'Wisselgeld',
    cost: 'Kosten',
    employment_date_error: 'De datum in dienst kan niet voor de datum uit dienst vallen.',
    employment_end_date: 'Datum uit dienst',
    employment_start_date: 'Datum in dienst',
    firebaseUID: 'Firebase UID',
    hourly_wage: 'Uurloon',
    is_delivery_dude: 'Is koerier',
    is_restaurant_manager: 'Is filiaalmanager',
    residence: 'Woonplaats',
    worklogs: 'Uren'
  },
  employeeWorklog: {
    amount: 'Bedrag',
    amountToBeSettled: 'Afrekenen',
    breakDuration: 'Pauze',
    changePaymentMethodError: 'De betaalwijze kon niet worden aangepast',
    changePaymentMethodSuccess: 'De betaalwijze is aangepast',
    createReport: 'Rapport downloaden',
    days: 'Dagen',
    employee: 'Werknemer',
    employeeName: 'Werknemer',
    endsAt: 'Eindtijd',
    fillInCompleteForm: 'Selecteer de medewerker en vul de datum en de gewerkte uren in',
    fromDate: 'Van',
    hours: 'Uren',
    isExcluded: 'Niet toegewezen',
    isIncluded: '{name}',
    minutes: 'Minuten',
    numberOfTickets: 'Aantal bonnen',
    orderNotFound: "Order met bonnummer '{ticketNumber}' niet gevonden in de lijst",
    paymentMethod: 'Betaalwijze',
    remarks: 'Notitie',
    save: 'Opslaan',
    scanTicket: 'Scan bonnen',
    selectEmployee: 'Alle werknemers',
    startedAt: 'Begintijd',
    ticketNumber: 'Bonnummer',
    totalAmount: 'Totaal',
    totalCosts: 'Kosten',
    totalElectronicAmount: 'Electronisch',
    totalGiftcardAmount: 'Giftcard',
    totalHours: 'Uren (u:mm)',
    totalInvoiceAmount: 'Op rekening/overig',
    totalTicketAmount: 'Totaal bonnen',
    totalWage: 'Loon',
    untilDate: 'Tot en met',
    workedAt: 'Datum',
    worklogsCreated: 'De uren zijn opgeslagen'
  },
  error: {
    general_request: 'Er is iets fout gegaan. Probeer het later opnieuw.',
    oneOrMoreInputs: 'De gegevens kunnen niet worden opgeslagen want het formulier bevat fouten.',
    request_401: 'U bent niet geautoriseerd',
    request_403: 'U heeft geen permissie tot deze actie',
    save: 'Er is iets fout gegaan. Probeer het later opnieuw.'
  },
  errorLog: {
    description:
      'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction',
    tips: 'Please click the bug icon in the upper right corner'
  },
  errorPages: {
    backToHome: 'Terug naar thuispagina',
    headline401: 'U heeft geen toegang tot deze pagina',
    headline403: 'U heeft geen toegang tot deze pagina',
    headline404: 'Pagina niet gevonden',
    message401: 'Indien u het hier niet mee eens bent, neem dan contact op met uw beheerder.',
    message403: 'Indien u het hier niet mee eens bent, neem dan contact op met uw beheerder.',
    message404: 'Controleer of de ingevoerde URL correct is.',
    return: 'Terug',
    title401: 'Oops',
    title403: 'Toegang geweigerd',
    title404: 'Sorry'
  },
  eventlog: {
    exception: 'Exception',
    level: 'Level',
    logger: 'Logger',
    message: 'Message',
    occurredAt: 'Datum tijd',
    thread: 'Thread'
  },
  forgotPassword: {
    description:
      'U kunt uw wachtwoord opnieuw instellen. Vul het onderstaande formulier in om de instructies per email te ontvangen.',
    done: 'Er is een email verstuurd naar {email}. Volg de instructies die hierin zijn vermeld.',
    submit: 'Verstuur email',
    title: 'Wachtwoord vergeten'
  },
  home: {
    welcomeVisitor: 'Welkom bezoeker'
  },
  homepageBlock: {
    blockType: 'Type blok',
    contentPlaceholder: 'Voer hier de HTML met de website-content in',
    country: 'Land',
    ribbonText: 'Ribbon tekst',
    ribbonTextPlaceholder: 'Deze tekst verschijnt op de ribbon',
    ribbonType: 'Type ribbon',
    url: 'URL',
    website: 'Website'
  },
  language: {
    code: 'ISO 639-1 Code'
  },
  localProduct: {
    localProductCategory: 'Categorie',
    online: 'Online'
  },
  login: {
    code: 'Gebruikersnaam',
    forbidden: 'Gebruiker/IP combinatie is geblokkeerd.',
    impersonationSuccess: 'U bent nu ingelogd als een andere gebruiker',
    logIn: 'Inloggen',
    password: 'Wachtwoord',
    title: 'Inloggen',
    unauthorised: 'Deze inloggegevens zijn onjuist.'
  },
  loyaltyPoints: {
    points: 'Punten',
    type: 'Actie'
  },
  mail: {
    bcc: 'Bcc',
    body: 'Bericht',
    cc: 'Cc',
    error: 'Er is een probleem opgetreden. Uw email is niet verstuurd.',
    from: 'Van',
    subject: 'Onderwerp',
    success: 'Uw email is succesvol verzonden',
    to: 'Aan'
  },
  menu: {
    availableForThuisbezorgd: 'Thuisbezorgd',
    cowsMilkProtein: 'Koemelkeiwit',
    digestibleCarbohydrates: 'Percentage verteerbare koolhydraten',
    displayName: 'Naam op site',
    extraProductLine: 'Extra productregel op bon',
    extraProductLineTooltip:
      'Deze regel wordt altijd op de bon afgedrukt bij het menu. Bijvoorbeeld: "Met kroepoek en uitjes"',
    fat: 'Vetpercentage',
    gluten: 'Gluten',
    imageTooltip: 'Een menu wordt alleen getoond op de website als er een afbeelding is geupload',
    kiloCalorie: 'KiloCalorieën',
    kiloJoule: 'KiloJoule',
    loyaltyPointsTooltip:
      'Het aantal punten dat dit menu kost in de VIP-shop. Laat 0 als dit menu NIET zichtbaar moet zijn in de VIP-shop ',
    menuBlockOptions: 'Opties',
    menuBlockType: 'Soort',
    menuBlockTypes: {
      moreMainProduct: 'Extra aantal van het hoofdproduct',
      sauce: 'Saus',
      sideDish: 'Bijgerecht',
      taste: 'Smaak',
      topping: 'Topping'
    },
    nameOnTicket: 'Naam op bon',
    nameTooltip: 'De taalspecifieke naam kan je invullen bij Vertalingen (Naam op site)',
    nutritionValuesUnit: 'Voedingswaarden per',
    onlineTooltip: 'Een menu wordt alleen getoond op de website als het actief is',
    popupInfo: 'Pop-up tekst',
    positionInMenu: 'Volgorde in menu',
    pricesTooltip:
      'Een menu wordt alleen getoond op de website als de prijs voor de categorie van het gekozen restaurant hier ingevuld is',
    restaurantsTooltip: 'Een menu wordt alleen getoond op de website als het gekozen restaurant hier aangevinkt is',
    showPricesYN: 'Toon prijzen',
    subtitle: 'Onderschrift',
    takeAwayID: 'Thuisbezorgd ID'
  },
  menuBlockOption: {
    default: 'Standaard',
    displayName: 'Naam op site',
    displayNameDe: 'Duits',
    displayNameNl: 'Nederlands',
    menuCategoryName: 'Menu categorienaam',
    numberOfProduct: '#',
    price: 'Meerprijs voor producten',
    priceAlmere: 'Almere',
    priceDuitsland: 'Duitsland',
    priceGroningen: 'Groningen',
    priceHoog: 'Hoog',
    priceLaag: 'Laag',
    priceMiddel: 'Middel',
    product: 'Product',
    productCategoryName: 'Product categorienaam',
    sortOrder: 'Volgorde'
  },
  messageTemplate: {
    body: 'Bericht',
    messageType: 'Type bericht',
    subject: 'Onderwerp'
  },
  monitoredProcess: {
    createdAt: 'Aangemaakt',
    expectedIntervalInSeconds: 'Interval (s)',
    lastError: 'Error',
    lastRunAt: 'Laatste run',
    nextRunAt: 'Volgend run'
  },
  navbar: {
    dashboard: 'Dashboard',
    language: 'Taal',
    logOut: 'Uitloggen',
    reportBug: 'Storing melden',
    screenfull: 'Screenfull',
    size: 'Global Size',
    theme: 'Theme'
  },
  newPassword: {
    description: 'U kunt nu uw wachtwoord wijzigen.',
    done: 'Uw wachtwoord is gewijzigd.',
    invalidToken: 'Deze link is verlopen.',
    submit: 'Wijzig wachtwoord',
    title: 'Nieuw wachtwoord'
  },
  orderFromRobex: {
    loginToRobexWebShop: 'Klik op deze link om de Robex webshop te openen in een nieuw tabblad',
    ordering: 'Bestellen',
    requestAccessToRobexWebShop:
      'U heeft nog geen toegang tot de Robex webshop. Neem contact op met het hoofdkantoor om toegang aan te vragen'
  },
  page: {
    inFooterMenu: 'In footer',
    keywords: 'Keywords',
    shortDescription: 'Korte omschrijving',
    template: 'Template'
  },
  permission: {
    AccessLog: 'Access log',
    AppSetting: 'Appsettings',
    ComputerAccess: 'Geautoriseerde computers',
    Configuration: 'Instellingen',
    Country: 'Landen',
    CouponCode: 'Actie codes',
    Customer: 'Klanten',
    CustomerAddress: 'Klantadressen',
    DeliveryApp: 'Bezorg app',
    DeliveryAppUser: 'Bezorgapp gebruiker',
    DeliveryReport: 'Bezorgrapportage',
    DiscountCondition: 'Kortingsvoorwaarde',
    DiscountRule: 'Kortingsregel',
    Domain: 'Domeinen',
    EmailMarketing: 'Email Marketing',
    Employee: 'Werknemers',
    EmployeeWorklog: 'Personeelsuren',
    EventLog: 'Event log',
    ExternalApiUser: 'Externe API gebruikers',
    HomePageBlock: 'Homepage blokken',
    ImpersonateUser: 'Inloggen als andere gebruiker',
    KassaUser: 'Kassa logins',
    Language: 'Talen',
    LegacySpareware: 'Oude Spareware',
    LocalProduct: 'Lokale producten',
    LocalProductCategory: 'Lokale productcategorieën',
    LogUpdateDeliveryTime: 'Bezorgtijden loggen',
    LoyaltyPointsTransaction: 'Vip punten transacties',
    MediaLibrary: 'Mediabibliotheek openen',
    Menu: 'Menus',
    MenuCategory: 'Menucategorieën',
    MessageTemplate: 'Berichtentemplates',
    MonitoredProcess: 'Bewaakte processen',
    MyComputerAccess: 'Filiaalmanager - Geautoriseerde computers ',
    MyCouponCodes: 'Filiaalmanager - Actiecodes ',
    MyDeliveryReport: 'Filiaalmanager - Bezorgrapportage',
    MyEmployees: 'Filiaalmanager - Werknemers',
    MyLegacySpareware: 'Filiaalmanager - Oude spareware',
    MyLocalProduct: 'Filiaalmanager - Lokale producten',
    MyLocalProductCategory: 'Filiaalmanager - Lokale productcategorieën',
    MyLocalProducts: 'Filiaalmanager - Lokale producten',
    MyOrderHistory: 'Filiaalmanager - Orderhistorie',
    MyRevenueReport: 'Filiaalmanager - Omzetrapportage',
    MyReviewsBureauDeWit: 'Filiaalmanager - Bureau de Wit',
    MyStatistics: 'Filiaalmanager - Statistieken',
    MyVehicles: 'Filiaalmanager - Voertuigen',
    OpeningHours: 'Filiaalmanager - Openingstijden',
    Option: 'Optielijsten',
    OrderFromRobex: 'Bestellen bij Robex',
    OrderFromRobexWooCommerce: 'Bestellen bij Robex (nieuw)',
    OrderHistory: 'Orderhistorie',
    OrderServiceBus: 'Order Service Bus',
    Page: 'Paginas',
    Partnership: 'Samenwerkingsverbanden',
    Permission: 'Permissies',
    PinDevice: 'Pinapparaten',
    PostcodeRestaurant: 'Postcodes',
    PriceCategory: 'Prijscategorieën',
    Printer: 'Printers',
    Product: 'Producten',
    ProductCategory: 'Productcategorieën',
    ReportEmployeeWorklog: 'Personeelsuren rapport',
    RequestForm: 'Verzoekformulier',
    Restaurant: 'Restaurants',
    RestaurantUser: 'Filiaalmanagers',
    RevenueChart: 'Omzetgrafiek',
    RevenueReport: 'Omzetrapportage',
    RevenueReportRealtime: 'Omzetrapportage realtime',
    ReviewsBureauDeWit: 'Bureau de Wit',
    Role: 'Rollen',
    ScratchAndWinAction: 'Kras en win acties',
    SpareWareNews: 'Spareware nieuws',
    StandardUser: 'Standaard gebruikers',
    Statistics: 'Statistieken',
    StatisticsBureauDeWit: 'Statistieken Bureau de Wit',
    StatisticsCustomerSatisfaction: 'Statistieken gasttevredenheid',
    StatisticsMediaDashboard: 'Statistieken media dashboard',
    StatisticsPostcodeAnalytics: 'Statistieken postcode analytics',
    StatisticsRevenueComparison: 'Statistieken omzetvergelijking',
    StatisticsRevenueDevelopment: 'Statistieken omzetontwikkeling',
    StatisticsScorecard: 'Statistieken scorecard',
    TimeRegistrationTerminal: 'In- en uitklokken',
    Translation: 'Vertalingen',
    User: 'Gebruikers',
    Vehicle: 'Voertuigen',
    VehicleType: 'Voertuig types',
    execute: 'Uitvoeren',
    none: 'Geen',
    pageIsReadonly: 'U heeft geen rechten om de gegevens op deze pagina te wijzigen.',
    read: 'Lezen',
    roles: 'Uw rollen',
    selectPlaceholder: 'Kies het toegangsniveau',
    switchRoles: 'Rol wisselen',
    tabPermissions: 'Permissies',
    tabTaskTypes: 'Taaksoorten',
    write: 'Schrijven'
  },
  pickrec: {
    chooseEntryFor: 'Selecteer "{caption}"',
    itemNotFound: "'{caption}' niet gevonden",
    suggestionsMayBeMore: 'Er zijn mogelijk meer zoekresulten',
    suggestionsRestCount: 'Er zijn nog {count} andere zoekresultaten'
  },
  pinDevice: {
    deviceType: 'Apparaat type',
    ecrID: 'ECR ID',
    status: 'Transactie status',
    transactionID: 'Transactie ID',
    wakeupIpAddress: 'Wake up IP-adres',
    wakeupIpPort: 'Wake up IP-port'
  },
  printer: {
    expectStatus: 'Stuurt status',
    printProvider: 'Provider',
    printTestPage: 'Testpagina afdrukken',
    statusOption: 'Status',
    statusReceivedAt: 'Status ontvangen op',
    statusText: 'Ontvangen status',
    testPagePrinted: 'De testpagina is verstuurd naar de printer',
    typeOption: 'Type'
  },
  product: {
    closingTime: 'Verkoop tot',
    cowsMilkProteinYN: 'Koemelkeiwit',
    deposit: 'Statiegeld',
    description: 'Beschrijving op site',
    digestibleCarbohydrates: 'Verteerbare koolhydraten',
    fat: 'Vet',
    glutenYN: 'Gluten',
    image: 'Afbeelding',
    kiloCalorie: 'Kilocalorie',
    kiloJoule: 'Kilojoule',
    largeImage: 'Grote afbeelding 359x170 pixels',
    menuBlockType: 'Beschikbaar als menu-optie',
    nameOnTicket: 'Naam op bon',
    nutritionValuesUnit: 'Eenheid',
    onlyTasteYN: 'Alleen smaak',
    prices: 'Prijzen',
    restaurants: 'Verkrijgbaar',
    sauceYN: 'Saus',
    showInNewBlockInCashDeskYN: 'Toon nieuw blok in kassa',
    showLargeInSiteTooltip:
      'Kies deze optie als je het product net zo groot als een menu wilt tonen. (Zoals bijvoorbeeld een Flaguettes)',
    showLargeInSiteYN: 'Groot weergeven',
    smallImage: 'Kleine afbeelding 97x97 pixels',
    subtitle: 'Ondertekst op site',
    supChargeYN: 'Is SUP heffing',
    tabNutritionalValues: 'Voedingswaarden',
    tabRestaurants: 'Restaurants',
    tasteYN: 'Smaak',
    toppingYN: 'Topping',
    vipOnlyYN: 'VIP only'
  },
  report: {
    fromDate: 'Van',
    untilDate: 'Tot en met'
  },
  request: {
    networkError: 'Het netwerk is op dit moment niet bereikbaar.'
  },
  requestForm: {
    formTitle: 'Verzoekformulier',
    openRequestForm: 'Open het verzoekformulier in een nieuw tabblad'
  },
  restaurant: {
    addressCheckCompletedYN: 'NAW check compleet',
    cashPaymentYN: 'Contant betalen',
    cashdeskDirectPrint: 'Kassa direct printen',
    cashdeskDomain: 'Kassa domein',
    closed: 'dicht',
    closingTime: 'Tijd gesloten',
    defaultDeliveryMethod: 'Standaard levering',
    deliveryCharge: 'Bezorgkosten',
    deliveryCostsLimit: 'Bezorgkosten gratis vanaf',
    deliveryTime: 'Levertijd (m)',
    detailPageBlock1: 'Website info blok 1',
    detailPageBlock2: 'Website info blok 2',
    freeDeliveryThreshold: 'Bezorging gratis vanaf',
    giftcardYN: 'Giftcard',
    idealYN: 'iDeal betaling',
    insocialCustomerID: 'Insocial klantnummer',
    isTSGCustomerYN: 'TSG klant',
    minimumOrderValue: 'Minimum orderwaarde',
    mobilePin: 'Heeft mobiel pinapparaat',
    nameOnTicket: 'Handelsnaam op bon',
    newPos: 'Nieuwe PoS',
    numberOfTickets: 'Aantal bonnen',
    open: 'open',
    openingHours: 'Openingstijden',
    openingHoursValidationError: 'Controleer de openingstijden',
    openingSoonYN: 'Binnenkort geopend',
    openingTime: 'Openingstijd',
    openingTimeForDelivery: 'Bezorgen mogelijk tussen',
    openingTimeForPickup: 'Afhalen mogelijk tussen',
    openingTimesStatement: 'Statement openingstijden',
    partnershipShiftbase: 'Dit samenwerkingsverband ondersteunt geen ShiftBase',
    pinDevices: 'Pinapparaten',
    postcodeOpeningHoursValidationError: 'Controleer de postcodes en de openingstijden',
    postcodeUniquenessViolation: '{alreadyUsedBy}',
    postcodes: 'Postcodes',
    queuePrinterClientCode: 'Printer client code',
    redirectOrderUrl: 'Redirect vanuit bestellenknop',
    redirectSearchUrl: 'Redirect na wijzigen vestiging',
    robexCustomerNumber: 'Robex klantnummer',
    showDayRevenueYN: 'Dagomzet weergeven',
    showDeliveryScreen: 'Toon bezorgscherm',
    tabCashDesk: 'Kassa',
    tabContactPersons: 'Contactpersonen',
    takeOutDiscountPercentage: 'Kortingspercentage voor afhalen',
    takeOutPossible: 'Afhalen mogelijk',
    testRestaurantYN: 'Test restaurant',
    ticketFooter: 'Bon voettekst',
    ticketHeader: 'Bon koptekst',
    useNewPosYN: 'Gebruik nieuwe PoS',
    website: 'Filiaal detailpagina'
  },
  revenueReport: {
    amount: 'Bedrag',
    amountVATHigh: 'BTW Hoog',
    amountVATLow: 'BTW Laag',
    bringYourOwnCount: 'Aantal Bring your own',
    customerAverageRevenue: 'Gemiddeld bedrag per klant',
    daysCount: 'Aantal dagen',
    employeeHours: 'Gewerkte uren',
    employeeLaborCosts: 'Netto loonkosten',
    employeeLaborCostsPercentage: 'Bruto loonkosten percentage van omzet',
    employeeNumberOfDays: 'Aantal dagen',
    employeeOtherCosts: 'Onkosten',
    header: 'Restaurant {restaurant}: Omzet van {fromDate} tot en met {untilDate}',
    lastUpdated: 'Bijgewerkt om',
    ordersAppCount: 'Aantal bonnen apps',
    ordersByPhoneCount: 'Aantal bonnen telefonisch',
    ordersOthersCount: 'Aantal bonnen overig',
    ordersPayterCount: 'Aantal bonnen Payter',
    ordersRestaurantCount: 'Aantal bonnen winkel',
    ordersSneletenCount: 'Aantal bonnen Sneleten',
    ordersThuisbezorgdCount: 'Aantal bonnen Thuisbezorgd',
    ordersWebsiteCount: 'Aantal bonnen website',
    position: 'Positie',
    revenue: 'Omzet',
    revenueAppsTotal: 'Omzet apps',
    revenueDiningCash: 'Omzet dineren contant',
    revenueDiningPIN: 'Omzet dineren PIN',
    revenueGiftCard: 'Omzet giftcard',
    revenueOnCredit: 'Omzet op rekening',
    revenueOthers: 'Omzet overig',
    revenuePayter: 'Omzet Payter',
    revenuePhoneCash: 'Omzet telefonisch contant',
    revenuePhoneElectronic: 'Omzet telefonisch electronisch',
    revenuePhonePIN: 'Omzet telefonisch PIN',
    revenueSneletenCash: 'Omzet Sneleten contant',
    revenueSneletenElectronic: 'Omzet Sneleten electronisch',
    revenueSneletenPIN: 'Omzet Sneleten PIN',
    revenueTakeAwayCash: 'Omzet afhaal contant (besteld in restaurant)',
    revenueTakeAwayPIN: 'Omzet afhaal PIN (besteld in restaurant)',
    revenueThuisbezorgdCash: 'Omzet Thuisbezorgd contant',
    revenueThuisbezorgdElectronic: 'Omzet Thuisbezorgd electronisch',
    revenueThuisbezorgdPIN: 'Omzet Thuisbezorgd PIN',
    revenueWebsiteCash: 'Omzet website contant',
    revenueWebsiteElectronic: 'Omzet website electronisch',
    revenueWebsitePIN: 'Omzet website PIN',
    totalCredited: 'Gecrediteerd',
    totalCustomersCount: 'Aantal klanten',
    totalDeliveryCosts: 'Totaal bezorgkosten',
    totalDeposit: 'Totaal statiegeld',
    totalOrdersCount: 'Aantal bonnen totaal',
    totalPayed: 'Afgerekende bonnen',
    totalRevenueCash: 'Totaal contant',
    totalRevenueElectronic: 'Totaal electronisch',
    totalRevenueExcludingVAT: 'Omzet excl. BTW',
    totalRevenueGiftCard: 'Totaal Giftcard',
    totalRevenueIncludingVAT: 'Omzet incl. BTW',
    totalRevenueNotSettled: 'Niet afgerekende bonnen',
    totalRevenueOnCredit: 'Totaal op rekening',
    totalRevenuePIN: 'Totaal PIN',
    totalRevenueVATHigh: 'Totaal omzet BTW Hoog',
    totalRevenueVATLow: 'Totaal omzet BTW Laag',
    totalSupCharges: 'Totaal SUP heffing',
    totalUniqueCustomersCount: 'Aantal unieke klanten',
    vat: 'BTW'
  },
  reviewsBureauDeWit: {
    header: 'Bureau de Wit',
    loginToReviewsBureauDeWit: 'Klik op deze link om de reviews openen in een nieuw tabblad'
  },
  route: {
    Table: 'Table',
    avatarUpload: 'Avatar Upload',
    clipboardDemo: 'Clipboard',
    complexTable: 'Complex Table',
    componentIndex: 'Introduction',
    componentMixin: 'Mixin',
    components: 'Components',
    countTo: 'CountTo',
    createContactPerson: 'Contactpersoon aanmaken',
    createCountry: 'Land aanmaken',
    createCouponCode: 'Actiecode aanmaken',
    createCustomer: 'Klant aanmaken',
    createCustomerAddress: 'Adres aanmaken',
    createDeliveryAppUser: 'Bezorgapp gebruiker aanmaken',
    createEmployee: 'Werknemer aanmaken',
    createEmployeeWorklog: 'Uren aanmaken',
    createExternalApiUser: 'Externe API gebruiker aanmaken',
    createHomePageBlock: 'Homepage blokken aanmaken',
    createKassaUser: 'Kassa login aanmaken',
    createLanguage: 'Taal aanmaken',
    createLocalProduct: 'Lokaal product aanmaken',
    createLocalProductCategory: 'Lokale productcategorie aanmaken',
    createLocalProducts: 'Lokaal product aanmaken',
    createLoyaltyPointsTransaction: 'Vip punten transactie aanmaken',
    createMenu: 'Menu aanmaken',
    createMenuBlock: 'Menuopties aanmaken',
    createMenuCategory: 'Menucategorie aanmaken',
    createMessageTemplate: 'Berichtentemplate aanmaken',
    createMonitoredProcess: 'Bewaakt proces aanmaken',
    createMyCouponCode: 'Actiecode aanmaken',
    createPage: 'Pagina aanmaken',
    createPartnership: 'Samenwerkingsverband aanmaken',
    createPinDevice: 'Pinapparaat aanmaken',
    createPriceCategory: 'Prijscategorie aanmaken',
    createPrinter: 'Printer aanmaken',
    createProduct: 'Product aanmaken',
    createProductCategory: 'Productcategorie aanmaken',
    createRestaurant: 'Restaurant aanmaken',
    createRestaurantUser: 'Filiaalmanager aanmaken',
    createRole: 'Rol aanmaken',
    createScratchAndWinAction: 'Kras en win actie aanmaken',
    createScratchAndWinBatch: 'Kras en win batch aanmaken',
    createUser: 'Gebruiker aanmaken',
    createVehicle: 'Voertuig aanmaken',
    createVehicleType: 'Voertuig type aanmaken',
    customTreeTable: 'Custom TreeTable',
    dashboard: 'Dashboard',
    directivePermission: 'Directive Permission',
    documentation: 'Documentation',
    dragDialog: 'Drag Dialog',
    dragTable: 'Drag Table',
    dropzone: 'Dropzone',
    dynamicTable: 'Dynamic Table',
    editComputerAccess: 'Geautoriseerde computer bewerken',
    editConfiguration: 'Instelling aanpassen',
    editContactPerson: 'Bewerk contactpersoon',
    editCountry: 'Land bewerken',
    editCouponCode: 'Actiecode bewerken',
    editCustomer: 'Klant bewerken',
    editCustomerAddress: 'Adres bewerken',
    editDeliveryAppUser: 'Bezorgapp gebruiker bewerken',
    editEmployee: 'Werknemer bewerken',
    editEmployeeWorklog: 'Uren bewerken',
    editExternalApiUser: 'Externe API gebruiker bewerken',
    editHomePageBlock: 'Homepage blokken bewerken',
    editKassaUser: 'Kassa login bewerken',
    editLanguage: 'Taal bewerken',
    editLocalProduct: 'Lokaal product bewerken',
    editLocalProductCategory: 'Lokale productcategorie bewerken',
    editLoyaltyPointsTransaction: 'Vip punten transactie bewerken',
    editMenu: 'Menu bewerken',
    editMenuBlock: 'Menuopties bewerken',
    editMenuCategory: 'Menucategorie bewerken',
    editMessageTemplate: 'Berichtentemplate bewerken',
    editMonitoredProcess: 'Bewaakt proces bewerken',
    editMyComputerAccess: 'Geautoriseerde computer bewerken',
    editMyCouponCode: 'Actiecode bewerken',
    editMyLocalProduct: 'Lokaal product bewerken',
    editPage: 'Pagina bewerken',
    editPartnership: 'Samenwerkingsverband bewerken',
    editPinDevice: 'Pinapparaat bewerken',
    editPostcodeRestaurant: 'Wijk bewerken',
    editPriceCategory: 'Prijscategorie bewerken',
    editPrinter: 'Printer bewerken',
    editProduct: 'Product bewerken',
    editProductCategory: 'Productcategorie bewerken',
    editRestaurant: 'Restaurant bewerken',
    editRestaurantUser: 'Filiaalmanager bewerken',
    editRole: 'Rol bewerken',
    editScratchAndWinAction: 'Kras en win actie bewerken',
    editScratchAndWinBatch: 'Kras en win batch bewerken',
    editStandardUser: 'Gebruiker bewerken',
    editTranslation: 'Vertaling bewerken',
    editVehicle: 'Voertuig bewerken',
    editVehicleType: 'Voertuig type bewerken',
    errorLog: 'Error Log',
    errorPages: 'Error Pages',
    example: 'Example',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    exportPDF: 'Export PDF',
    exportZip: 'Export Zip',
    externalLink: 'External Link',
    form: 'Form',
    guide: 'Guide',
    home: 'Home',
    i18n: 'I18n',
    icons: 'Icons',
    inlineEditTable: 'Inline Edit',
    introduction: 'Introduction',
    jsonEditor: 'JSON Editor',
    markdown: 'Markdown',
    menu_admin: 'Beheer',
    menu_admin_accesslog: 'Access Log',
    menu_admin_application: 'Applicatie',
    menu_admin_appsettings: 'Appsettings',
    menu_admin_company_records: 'Bedrijfsgegevens',
    menu_admin_configuration: 'Instellingen',
    menu_admin_countries: 'Landen',
    menu_admin_deliveryapp_users: 'Bezorgapp gebruikers',
    menu_admin_eventlog: 'Event Log',
    menu_admin_external_api_users: 'Externe API logins',
    menu_admin_languages: 'Talen',
    menu_admin_messagetemplates: 'Berichtentemplates',
    menu_admin_options: 'Keuzelijsten',
    menu_admin_restaurant_users: 'Filiaalmanagers',
    menu_admin_roles: 'Rollen',
    menu_admin_security: 'Rollen en gebruikers',
    menu_admin_translations: 'Vertalingen',
    menu_admin_users: 'Gebruikers',
    menu_admin_vehicle_types: 'Voertuigtypes',
    menu_computer_accesses: 'Geautoriseerde computers',
    menu_coupon_codes: 'Actiecodes',
    menu_customers: 'Klanten',
    menu_delivery_report: 'Bezorging',
    menu_dishes: 'Gerechten',
    menu_employees: 'Werknemers',
    menu_employeeworklog: 'Uren afrekenen',
    menu_employeeworklog_report: 'Personeelsuren',
    menu_home_page_blocks: 'Homepage blokken',
    menu_kassa_users: 'Kassa logins',
    menu_legacy_spareware: 'Oude Spareware',
    menu_local_product_categories: 'Lokale productcategorieën',
    menu_local_products: 'Lokale producten',
    menu_loyalty_points_transactions: 'Vip punten pransacties',
    menu_main_customers: 'Klantbeheer',
    menu_medialibrary: 'Mediabibliotheek',
    menu_menu: 'Menus',
    menu_menu_categories: 'Menucategorieën',
    menu_monitored_processes: 'Bewaakte processen',
    menu_my_restaurant: 'Mijn restaurant',
    menu_opening_hours: 'Openingstijden',
    menu_pages: 'Paginas',
    menu_partnerships: 'Samenwerkingsverbanden',
    menu_pin_devices: 'Pinapparaten',
    menu_postcode_restaurants: 'Wijken',
    menu_price_categories: 'Prijscategorieën',
    menu_product_categories: 'Productcategorieën',
    menu_products: 'Producten',
    menu_reports: 'Rapportages',
    menu_requestForm: 'Verzoekformulier',
    menu_restaurants: 'Restaurants',
    menu_revenue_comparison: 'Omzetvergelijking',
    menu_revenue_development: 'Omzetontwikkeling',
    menu_revenue_report: 'Kassa omzet',
    menu_revenue_report_realtime: 'Actuele omzetvergelijking',
    menu_reviews_bureau_de_wit: 'Bureau de Wit',
    menu_scorecard: 'Scorecard',
    menu_scratch_and_win_actions: 'Kras en Win Acties',
    menu_statistics: 'Statistieken',
    menu_statistics_bureau_de_wit: 'Bureau de Wit',
    menu_statistics_customer_satisfaction: 'Gasttevredenheid',
    menu_statistics_media_dashboard: 'Media dashboard',
    menu_statistics_postcode_analytics: 'Postcode analytics',
    menu_vehicles: 'Voertuigen',
    menu_website: 'Website',
    news: 'Nieuws',
    orderFromRobex: 'Bestellen bij Robex',
    orderHistory: 'Orderhistorie',
    page401: '401',
    page403: '403',
    page404: '404',
    pagePermission: 'Page Permission',
    permission: 'Permission',
    requestForm: 'Verzoekformulier',
    selectExcel: 'Export Selected',
    splitPane: 'SplitPane',
    sticky: 'Sticky',
    tab: 'Tab',
    theme: 'Theme',
    tinymce: 'Tinymce',
    treeTable: 'Tree Table',
    uploadExcel: 'Upload Excel',
    userSettings: 'Instellingen gebruiker',
    zip: 'Zip'
  },
  scratchAndWin: {
    batchState: 'Status van de batch',
    conditionsUrl: 'URL voor actie voorwaarden',
    confirmAlreadyExported:
      'Dit bestand is al eerder gedownload. Weet u zeker dat u de wincodes opnieuw wilt downloaden?',
    confirmDownloadCsv:
      'Verstrek dit bestand slechts eenmaal aan de drukker om dubbele wincodes te voorkomen. Weet u zeker dat u de wincodes nu wilt downloaden?',
    couponCodePrefix: 'Voorvoegsel bij de actiecode',
    couponType: 'Type korting',
    couponValidDays: 'Geldigheid gewonnen couponcode (dagen)',
    discountPercentage: 'Kortingspercentage',
    downloadCsv: 'Wincode bestand downloaden',
    endDate: 'Geldig tot en met',
    numberOfCards: 'Aantal kaarten',
    numberOfCardsGenerated: 'Aantal gegenereerde kaarten',
    product: 'Product',
    scratchAndWinPriceType: 'Type batch',
    sendPriceNotificationTo: 'Notificatie bij winst naar',
    startDate: 'Geldig vanaf',
    tabBatches: 'Batches',
    winText: 'Win tekst {Price} = gewonnen prijs, {CouponCode} = actiecode), {ValidUntil} = geldig tot',
    winTextGrandPrice: 'Win tekst {FirstName} {LastName} {EmailAddress}'
  },
  size: {
    medium: 'Normaal',
    mini: 'Kleinst',
    small: 'Klein'
  },
  stringInsert: {
    btnText: 'Invoegen...',
    closeOnInsert: 'Sluit dialoog na invoegen',
    textIntro: 'Klik op een tekst om deze in te voegen.',
    textIntroUnavailable: 'Er zijn geen teksten om in te voegen.',
    textTitle: 'Tekst invoegen',
    toastInsert: 'Ingevoegd: {name}'
  },
  table: {
    actions: 'Acties',
    searchDatePlaceholder: 'Typ of selecteer',
    searchPlaceholder: 'Typ om te zoeken',
    searchSelectPlaceholder: 'Selecteer om te zoeken'
  },
  tagsView: {
    close: 'Sluiten',
    closeAll: 'Alle sluiten',
    closeOthers: 'Andere sluiten',
    refresh: 'Verversen'
  },
  textArea: {
    placeholder: 'Uw tekst'
  },
  translation: {
    code: 'Vertalingcode'
  },
  user: {
    avatar: 'Profielafbeelding',
    avatarInfo: 'URL naar profielafbeelding',
    kassaEditAllowedYN: 'Deze gebruiker mag crediteren',
    kassaLoginCode: 'Logincode voor kassa',
    newPassword: 'Nieuw wachtwoord',
    newPasswordInfo: 'Laat leeg als u uw wachtwoord niet wilt wijzigen',
    oldPassword: 'Huidig wachtwoord',
    oldPasswordInfo: 'Voer ter controle uw huidige wachtwoord in',
    oldPasswordInvalid: 'U heeft niet het correcte huidige wachtwoord opgegeven',
    passwordChanged: 'Uw wachtwoord is aangepast',
    repeatPassword: 'Herhaal wachtwoord',
    repeatPasswordInfo: 'Herhaal ter controle uw nieuwe wachtwoord',
    robexWebshopURL: 'Robex autologin link',
    roles: 'Rollen'
  },
  validation: {
    complexity: 'Kies complexiteit',
    ean: 'Deze barcode is incorrect',
    email: 'Dit is een ongeldig e-mailadres',
    error: {
      alphanumeric: 'Minstens 1 symbool is vereist',
      digit: 'Minstens 1 getal is vereist',
      isFromDatabase: 'Het wachtwoord kan niet worden gevalideerd, omdat de server niet bereikbaar is.',
      itemNotFound: 'Bestand is reeds verwijderd.',
      length: 'Minstens {count} karakters zijn vereist',
      lowercase: 'Minstens 1 kleine letter is vereist',
      passwordComplexity: 'Het wachtwoord voldoet niet aan de vereiste complexiteit',
      uppercase: 'Minstens 1 hoofdletter is vereist'
    },
    exceedMax: 'Deze waarde is te groot',
    formValidationFailed: 'Dit formulier bevat fouten. Controleer uw invoer en probeer het opnieuw',
    notApplicable: 'Deze waarde is niet van toepassing',
    numeric: 'Alleen cijfers toegestaan',
    oldPasswordRequired: 'Vul het oude wachtwoord in',
    postcode: 'Ongeldige postcode',
    repeatPasswordMismatch: 'Twee wachtwoorden komen niet overeen',
    required: 'Dit veld is vereist',
    time: 'Ongeldige tijd',
    unique: 'Deze waarde is niet uniek',
    url: 'Dit is een ongeldige URL'
  },
  vehicle: {
    code: 'Code/kenteken'
  },
  vehicleType: {
    travelMode: 'Reismethode'
  },
  wizard: {
    next: 'Volgende',
    prev: 'Terug',
    skip: 'Overslaan'
  }
};
