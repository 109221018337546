import { PermissionLevel } from '@/utils/permission';
import { getConfigurationFromLocalStorage } from '@/utils';
import { requestFormRoutes } from '@/router/requestFormRoutes';
import { RouteConfig } from 'vue-router';
export const applicationRoutes = [
  {
    component: () => import('@/pages/admin/application/countries/index.vue'),
    meta: {
      permissions: [{ Country: PermissionLevel.Read }],
      title: 'menu_admin_countries'
    },
    name: 'Countries',
    path: 'countries'
  },
  {
    component: () => import('@/pages/admin/application/countries/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ Country: PermissionLevel.Write }],
      title: 'createCountry'
    },
    name: 'CreateCountry',
    path: 'countries/create'
  },
  {
    component: () => import('@/pages/admin/application/countries/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ Country: PermissionLevel.Read }],
      title: 'editCountry'
    },
    name: 'EditCountry',
    path: 'countries/edit/:id'
  },
  {
    component: () => import('@/pages/admin/application/vehicletypes/index.vue'),
    meta: {
      permissions: [{ VehicleType: PermissionLevel.Read }],
      title: 'menu_admin_vehicle_types'
    },
    name: 'VehicleTypes',
    path: 'vehicletypes'
  },
  {
    component: () => import('@/pages/admin/application/vehicletypes/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ VehicleType: PermissionLevel.Write }],
      title: 'createVehicleType'
    },
    name: 'CreateVehicleType',
    path: 'vehicleTypes/create'
  },
  {
    component: () => import('@/pages/admin/application/vehicletypes/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ VehicleType: PermissionLevel.Read }],
      title: 'editVehicle'
    },
    name: 'EditVehicleType',
    path: 'vehicleTypes/edit/:id'
  },
  {
    component: () => import('@/pages/admin/application/computeraccesses/headoffice/index.vue'),
    meta: {
      permissions: [{ ComputerAccess: PermissionLevel.Read }],
      title: 'menu_computer_accesses'
    },
    name: 'ComputerAccesses',
    path: 'computer-accesses'
  },
  {
    component: () => import('@/pages/admin/application/computeraccesses/headoffice/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ ComputerAccess: PermissionLevel.Write }],
      title: 'editComputerAccess'
    },
    name: 'EditComputerAccess',
    path: 'computer-access/edit/:id'
  },
  {
    component: () => import('@/pages/admin/application/languages/index.vue'),
    meta: {
      permissions: [{ Language: PermissionLevel.Read }],
      title: 'menu_admin_languages'
    },
    name: 'Languages',
    path: 'languages'
  },
  {
    component: () => import('@/pages/admin/application/languages/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ Language: PermissionLevel.Write }],
      title: 'createLanguage'
    },
    name: 'CreateLanguage',
    path: 'languages/create'
  },
  {
    component: () => import('@/pages/admin/application/languages/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ Language: PermissionLevel.Read }],
      title: 'editLanguage'
    },
    name: 'EditLanguage',
    path: 'languages/edit/:id'
  },
  {
    component: () => import('@/pages/admin/application/translations/index.vue'),
    meta: {
      permissions: [{ Translation: PermissionLevel.Read }],
      title: 'menu_admin_translations'
    },
    name: 'Translations',
    path: 'translations'
  },
  {
    component: () => import('@/pages/admin/application/translations/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ Translation: PermissionLevel.Read }],
      title: 'editTranslation'
    },
    name: 'EditTranslation',
    path: 'translations/edit/:id'
  },
  {
    component: () => import('@/pages/admin/application/configuration/index.vue'),
    meta: {
      permissions: [{ Configuration: PermissionLevel.Read }],
      title: 'menu_admin_configuration'
    },
    name: 'Configs',
    path: 'configuration'
  },
  {
    component: () => import('@/pages/admin/application/configuration/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ Configuration: PermissionLevel.Read }],
      title: 'editConfiguration'
    },
    name: 'EditConfiguration',
    path: 'configuration/edit/:id'
  },
  {
    component: () => import('@/pages/admin/application/appSettings/index.vue'),
    meta: {
      permissions: [{ AppSetting: PermissionLevel.Read }],
      title: 'menu_admin_appsettings'
    },
    name: 'AppSettings',
    path: 'appsettings'
  },
  {
    component: () => import('@/pages/admin/application/messagetemplates/index.vue'),
    meta: {
      permissions: [{ MessageTemplate: PermissionLevel.Read }],
      title: 'menu_admin_messagetemplates'
    },
    name: 'MessageTemplates',
    path: 'messagetemplates'
  },
  {
    component: () => import('@/pages/admin/application/messagetemplates/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ MessageTemplate: PermissionLevel.Write }],
      title: 'createMessageTemplate'
    },
    name: 'CreateMessageTemplate',
    path: 'messagetemplates/create'
  },
  {
    component: () => import('@/pages/admin/application/messagetemplates/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ MessageTemplate: PermissionLevel.Read }],
      title: 'editMessageTemplate'
    },
    name: 'EditMessageTemplate',
    path: 'messagetemplates/edit/:id'
  },
  {
    component: () => import('@/pages/admin/maintenance/monitoredprocesses/index.vue'),
    meta: {
      permissions: [{ MonitoredProcess: PermissionLevel.Read }],
      title: 'menu_monitored_processes'
    },
    name: 'MonitoredProcesses',
    path: 'monitoredprocesses'
  },
  {
    component: () => import('@/pages/admin/maintenance/monitoredprocesses/create.vue'),
    hidden: true,
    meta: {
      icon: 'edit',
      permissions: [{ MonitoredProcess: PermissionLevel.Write }],
      title: 'createMonitoredProcess'
    },
    name: 'CreateMonitoredProcess',
    path: 'monitoredprocesses/create'
  },
  {
    component: () => import('@/pages/admin/maintenance/monitoredprocesses/edit.vue'),
    hidden: true,
    meta: {
      noCache: true,
      permissions: [{ MonitoredProcess: PermissionLevel.Read }],
      title: 'editMonitoredProcess'
    },
    name: 'EditMonitoredProcess',
    path: 'monitoredprocesses/edit/:id'
  },
  {
    component: () => import('@/pages/admin/maintenance/accesslog/index.vue'),
    meta: {
      permissions: [{ AccessLog: PermissionLevel.Read }],
      title: 'menu_admin_accesslog'
    },
    name: 'Access log',
    path: 'accesslog'
  },
  {
    component: () => import('@/pages/admin/maintenance/eventlog/index.vue'),
    meta: {
      permissions: [{ EventLog: PermissionLevel.Read }],
      title: 'menu_admin_eventlog'
    },
    name: 'Event Log',
    path: 'eventlog'
  },
  {
    meta: {
      permissions: [{ MediaLibrary: PermissionLevel.Read }],
      title: 'menu_medialibrary'
    },
    name: 'Media Library',
    path: 'medialibrary',
    beforeEnter() {
      window.open(getConfigurationFromLocalStorage('mediaLibrary.URL'), '_blank');
    }
  },
  {
    component: () => import('@/pages/requestform/index.vue'),
    meta: {
      permissions: [{ RequestForm: PermissionLevel.Write }],
      title: 'menu_requestForm'
    },
    name: 'RequestForm',
    path: 'requestForm'
  }
];
